import { SelectOptionType } from '@octano/global-ui';
import { ReactElement, useCallback } from 'react';

import { createFetchContext } from '../../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../../components/info/DisplayError';
import Loading from '../../../../../components/info/Loading';
import { modifyEnrollmentFormData } from '../api';
import { ModifyEnrollmentFormDataResponse } from '../types';

export interface ModifyEnrollmentFormLoaderProps {
  studyPlanEnrollmentId: string | number;
  children: (props: {
    data: ModifyEnrollmentFormDataResponse;
    defaultValues: {
      'offer.current': SelectOptionType;
    };
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  ModifyEnrollmentFormDataResponse
>();

export const BaseLoaderConsumer = FetchConsumer;

export default function ModifyEnrollmentFormLoader({
  studyPlanEnrollmentId,
  children,
}: ModifyEnrollmentFormLoaderProps) {
  const request = useCallback(() => {
    return modifyEnrollmentFormData(studyPlanEnrollmentId);
  }, [studyPlanEnrollmentId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return (
              <div className="mx-3">
                <Loading insideCard />
              </div>
            );
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({
            data,
            refresh,
            defaultValues: {
              'offer.current': {
                label: `${data.detail.studyPlanVersionName} -
                 ${data.detail.campusName} - ${data.detail.scheduleName}`,
                value: data.detail.studyPlanVersionOfferId,
              },
            },
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
