import { useParams } from 'react-router-dom';
import { Card } from 'reactstrap';
import { TextsProvider } from './hooks/textsContext';
import HeaderInfo from './parts/Header/HeaderInfo';
import GradingStructureFormLoader from './parts/Loaders/GradingStructureFormLoader';
import GradingStructureInfoLoader from './parts/Loaders/GradingStructureInfoLoader';
import InitUpdateStructureTypeForm from './parts/UpdateStructureTypeForm';
import { UpdateStructureTypeForm } from './parts/UpdateStructureTypeForm/UpdateStructureTypeForm';

function GradingStructureEdit() {
  const prefix = 'configurations.gradingStructure';
  const { gradesAcademicId } = useParams<{ gradesAcademicId: string }>();

  return (
    <Card className="p-4">
      <TextsProvider prefix={prefix}>
        <GradingStructureFormLoader>
          <GradingStructureInfoLoader gradesAcademicId={+gradesAcademicId}>
            <InitUpdateStructureTypeForm>
              <header className="mb-3">
                <HeaderInfo />
              </header>
              <UpdateStructureTypeForm />
            </InitUpdateStructureTypeForm>
          </GradingStructureInfoLoader>
        </GradingStructureFormLoader>
      </TextsProvider>
    </Card>
  );
}
export default GradingStructureEdit;
