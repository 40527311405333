import { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { CheckInput } from '@octano/global-ui';

import { CourseFormContext } from '../providers/CourseProvider';
import { SectionTitle } from '../../../../components/text';
import { CourseFormFields, CourseFormMode } from '../types';

const prefix = 'courses.update';

const CourseTypeSection = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<CourseFormFields>();

  const { mode, formData } = useContext(CourseFormContext);
  const { courseTypes = [] } = formData;

  if (!courseTypes?.length) {
    return <></>;
  }

  return (
    <Row>
      <Col xs={12} className="pt-4">
        <SectionTitle text={t(`${prefix}.courseTypes`)} />
      </Col>
      <Col xs={12}>
        <p className="fs-18 fw-300">{t(`${prefix}.courseTypeDescription`)}</p>
      </Col>
      <Col xs={12}>
        <Row className="px-md-3 py-2">
          <Controller
            control={control}
            name="courseTypes"
            render={({ field }) => (
              <>
                {courseTypes.map((type) => (
                  <Col xs={6} md={4} lg={3} xl={2} key={`course_${type.value}`}>
                    <CheckInput
                      name={`course_type_${type.value}`}
                      label={type.label}
                      onChange={() => {
                        if (!field?.value?.includes(+type.value)) {
                          field?.onChange(
                            (field?.value ?? [])?.concat(+type.value),
                          );
                        } else {
                          field?.onChange(
                            field?.value?.filter((e) => e !== +type.value),
                          );
                        }
                      }}
                      value={!!field?.value?.includes(+type.value)}
                      disabled={mode === CourseFormMode.WATCH}
                    />
                  </Col>
                ))}
              </>
            )}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default CourseTypeSection;
