import { FormData } from './parts/Modals/CreateOrUpdateForm';
import { TeacherAssignmentHourType, TeacherAssignmentRecord } from './type';

const optionFormat = (option?: { id: number; name: string }) =>
  option
    ? {
        label: option.name,
        value: option.id,
      }
    : null;

export const mapTeacherAssignmentRecordToFormData = (
  row: TeacherAssignmentRecord,
  hourTypes: TeacherAssignmentHourType[],
): FormData => {
  const hours: any = {};

  if (hourTypes?.length) {
    hourTypes?.forEach((e) => (hours[e.id] = '0'));
  }
  Object.keys(hours ?? {}).forEach((hourType) => {
    const finded = row?.hours?.find((h) => h?.typeId === hourType);
    if (finded?.value) {
      hours[hourType] = `${finded?.value}`;
    }
  });
  return {
    hours,
    startAt: row.startAt,
    campus: optionFormat(row.campus),
    studyPlan: optionFormat(row.studyPlan),
    teacherAcademicRank: optionFormat(row.teacherAcademicRank),
    teacherContractType: optionFormat(row.teacherContractType),
    school: optionFormat(row.school),
    deanship: optionFormat(row.deanship),
    academicLevel: optionFormat(row.academicLevel),
    modality: optionFormat(row.modality),
    startPeriod: optionFormat(row.startPeriod),
  };
};
