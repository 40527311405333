import { Select, TextInput } from '@octano/global-ui';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SectionTitle } from '../../../../../../../components/text';
import { useValidations } from '../../../../../../../hooks/useValidations';
import { TranslationsKeys } from '../../../../../../../locales/translations';
import { usePersonalBackgroundFormDataLoader } from '../Loaders/PersonalBackgroundFormDataLoader';
const prefix = `contact`;

const CHILE_LOWER_CASE = 'chile';

const mapFn = ({ id, name }: { id: number; name: string }) => {
  return { label: name, value: id };
};

const PersonalBackgroundContact = () => {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_PERSONAL_BACKGROUND,
  );
  const { control, watch, formState, setValue } = useFormContext();
  const { validatePhone } = useValidations();

  const { data } = usePersonalBackgroundFormDataLoader();
  const { countries, regions, communes } = data!;
  const countryOptions = useMemo(() => countries.map(mapFn), [countries]);
  const regionsOptions = useMemo(() => regions.map(mapFn), [regions]);
  const selectedRegion = watch('region');

  const communesOptions = useMemo(
    () =>
      communes.filter((c) => c.regionId === selectedRegion?.value).map(mapFn),
    [communes, selectedRegion],
  );

  const rut = watch('rut');

  const isRegionTouched = formState.touchedFields['region'];

  useEffect(() => {
    if (rut) {
      setValue(
        'country',
        countryOptions.find(
          (c) => c.label.toLocaleLowerCase() === CHILE_LOWER_CASE,
        ),
      );
    }
  }, [countryOptions, rut, setValue]);

  useEffect(() => {
    if (isRegionTouched) {
      setValue('commune', null);
    }
  }, [selectedRegion, setValue, isRegionTouched]);

  return (
    <>
      <Row className="pt-5">
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={8}>
          <TextInput
            name={'addressStreet'}
            label={t(`${prefix}.addressStreet`)}
            control={control}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={'addressNumber'}
            label={t(`${prefix}.addressNumber`)}
            control={control}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={'addressExtra'}
            label={t(`${prefix}.addressExtra`)}
            control={control}
          />
        </Col>

        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={'region'}
            label={t(`${prefix}.region`)}
            options={regionsOptions}
            control={control}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={'commune'}
            label={t(`${prefix}.commune`)}
            options={communesOptions}
            control={control}
            shouldUnregister
          />
        </Col>

        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={'phone'}
            label={t(`inputs:phone.label`)}
            control={control}
            formatter="phone"
            rules={{ validate: validatePhone }}
            placeholder={t(`inputs:phone.placeholder`)}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={'cellPhone'}
            label={t(`inputs:cellPhone.label`)}
            control={control}
            formatter="phone"
            rules={{
              validate: {
                phone: validatePhone,
              },
            }}
            placeholder={t(`inputs:cellPhone.placeholder`)}
          />
        </Col>
      </Row>
    </>
  );
};

export default PersonalBackgroundContact;
