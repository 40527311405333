import { ColumnTable } from '@octano/global-ui';

import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../../../locales/translations';

const dictPrefix = 'sectionsTable';

export default function useSectionsColumns(): ColumnTable[] {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_HIRING_INFORMATION,
  );

  return [
    {
      columnName: 'period',
      headerText: t(`${dictPrefix}.period`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'code',
      headerText: t(`${dictPrefix}.code`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'course',
      headerText: t(`${dictPrefix}.course`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'campus',
      headerText: t(`${dictPrefix}.campus`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'school',
      headerText: t(`${dictPrefix}.school`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
  ];
}
