import { GradesConfig } from '../parts/EvaluationForm/types';

export interface AcademicLevels {
  id: number;
  name: string;
  structureTypeId: number;
  structureType: StructureType;
}

export interface StructureType {
  id: number;
  name: string;
  code: string;
  description: string;
  color: string;
}

// Table
export interface AcademicLevelsResponse {
  data: AcademicLevels[];
}

// Form selects
export interface AcademicLevelsFormDataResponse {
  academicLevels: AcademicLevels[];
  structureType: StructureType[];
}

// Form Data
export interface FormDataAction {
  structureTypeId: {
    value: number;
    label: string;
  };
  finalGrade?: null | GradesConfig['finalGrade'];
  testGrades?: null | GradesConfig['testGrades'];
}

export type FormDataContext = Pick<FormDataAction, 'finalGrade' | 'testGrades'>;

export interface StructureTypeSchema {
  finalEvaluationsConfig: null | GradesConfig['finalGrade'];
  partialEvaluationsConfig: null | GradesConfig['testGrades'];
}
// Details
export interface AcademicLevelInfoResponse {
  id: number;
  name: string;
  structureTypeId: number;
  structureType: StructureType;
  currentStructureTypeSchema: StructureTypeSchema;
}

export enum StructureTypeCode {
  FREE = 1,
  FIXED,
  SEMIFIXED,
}
