import { useTranslation } from 'react-i18next';
const prefix = 'tuitionContinuityDiscountAllocation';
export default function useTexts() {
  const { t } = useTranslation();
  return {
    table: {
      bulkDiscountTemplate: t(`${prefix}.table.bulkDiscountTemplate`),
      action: t(`${prefix}.table.action`),
      load: t(`${prefix}.table.load`),
    },
    modal: {
      title: t(`${prefix}.modal.title`),
      explanation: t(`${prefix}.modal.explanation`),
      form: {
        cancel: t(`${prefix}.modal.form.cancel`),
        submit: t(`${prefix}.modal.form.submit`),
        btnText: t(`${prefix}.modal.form.btnText`),
      },
      success: t(`${prefix}.modal.success`),
      error: t(`${prefix}.modal.error`),
    },
    uploadFailureModal: {
      title: t(`${prefix}.uploadFailureModal.title`),
      description: t(`${prefix}.uploadFailureModal.description`),
      download: t(`${prefix}.uploadFailureModal.download`),
      error: t(`${prefix}.uploadFailureModal.error`),
    },
    periodsSelector: {
      baseInfo: {
        error: t(`${prefix}.errorDownloadingFile`),
      },
      form: {
        period: t(`${prefix}.form.period`),
        school: t(`${prefix}.form.school`),
        studyPlan: t(`${prefix}.form.studyPlan`),
        entryPeriod: t(`${prefix}.form.entryPeriod`),
        campus: t(`${prefix}.form.campus`),
        schedule: t(`${prefix}.form.schedule`),
        modality: t(`${prefix}.form.modality`),
        dateFrom: t(`${prefix}.form.dateFrom`),
        dateTo: t(`${prefix}.form.dateTo`),
        gender: t(`${prefix}.form.gender`),
        notFound: t(`${prefix}.form.notFound`),
      },
    },
  };
}
