import { Select, TextInput } from '@octano/global-ui';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { SectionTitle } from '../../../../../../../components/text';
import { useValidations } from '../../../../../../../hooks/useValidations';
import { TranslationsKeys } from '../../../../../../../locales/translations';
import {
  HAS_INSURANCE,
  healthInsuranceOptions,
} from '../../../../../../../utils/selectOptions';
import { healthSystemsCodes } from '../../type';
import { usePersonalBackgroundFormDataLoader } from '../Loaders/PersonalBackgroundFormDataLoader';

const prefix = `healthSystem`;

const PersonalBackgroundHealthSystem = () => {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_PERSONAL_BACKGROUND,
  );
  const { control, watch } = useFormContext();
  const { validateRut, validatePhone } = useValidations();

  const { data } = usePersonalBackgroundFormDataLoader();
  const { healthInstitutions, healthSystems } = data!;
  const selectedHealthSystem = watch('healthSystem');
  const selectedHealthInsurance = watch('healthInsurance');

  const healthInstitutionsOptions = useMemo(
    () =>
      healthInstitutions.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [healthInstitutions],
  );

  const healthSystemOptions = useMemo(() => {
    return healthSystems.map(({ id, name }) => ({
      value: id,
      label: t(`${prefix}.${id}`),
    }));
  }, [healthSystems, t]);

  return (
    <>
      <Row className="pt-5">
        <Col className="pb-3" xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={'healthSystem'}
            label={t(`${prefix}.healthSystem`)}
            options={healthSystemOptions}
            isSearchable={false}
            control={control}
          />
        </Col>
        {selectedHealthSystem?.value === healthSystemsCodes.Private && (
          <Col className="pb-3" xs={12} lg={4}>
            <Select
              name={'healthInstitution'}
              label={t(`${prefix}.healthInstitution`)}
              options={healthInstitutionsOptions}
              isSearchable={false}
              control={control}
              shouldUnregister
            />
          </Col>
        )}
        {selectedHealthSystem?.value === healthSystemsCodes.Mixed && (
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name={'otherHealthSystem'}
              label={t(`${prefix}.otherHealthSystem`)}
              control={control}
              shouldUnregister
            />
          </Col>
        )}
        {selectedHealthSystem?.value && (
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name={'healthCareContributorId'}
              label={t(`${prefix}.healthCareContributorId`)}
              control={control}
              formatter="rut"
              shouldUnregister
              rules={{
                validate: {
                  rut: validateRut,
                },
              }}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={'healthInsurance'}
            label={t(`${prefix}.healthInsurance`)}
            options={healthInsuranceOptions}
            control={control}
            isSearchable={false}
          />
        </Col>
        {selectedHealthInsurance?.value === HAS_INSURANCE && (
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name={'healthInsuranceContributorId'}
                label={t(`${prefix}.healthInsuranceContributorId`)}
                control={control}
                formatter="rut"
                shouldUnregister
                rules={{
                  validate: { rut: validateRut },
                }}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name={'healthInsurancePhone'}
                label={t(`${prefix}.healthInsurancePhone`)}
                tooltip={t(`${prefix}.healthInsurancePhoneTooltip`)}
                control={control}
                formatter="phone"
                shouldUnregister
                rules={{ validate: validatePhone }}
                maxLength={15}
              />
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default PersonalBackgroundHealthSystem;
