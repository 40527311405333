import { useEffect, useState } from 'react';
import { checkNamespaces } from '../config/language';

export const useI18nFullFilled = () => {
  const [conditionMet, setConditionMet] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const poll = async () => {
      try {
        const result = checkNamespaces();
        if (result) {
          setConditionMet(true);
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Error durante la verificación:', error);
      }
    };

    if (conditionMet) {
      return () => clearInterval(intervalId);
    }

    intervalId = setInterval(poll, 200);
    poll();

    return () => clearInterval(intervalId);
  }, [conditionMet]);

  return conditionMet;
};
