// Components
import { Select, SelectOptionType } from '@octano/global-ui';
// Hooks
import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useValidations } from '../../../../hooks/useValidations';
// Utils
import { FinantialSettingsContext } from '../contexts/finantial-settings.context';
// Types
import { FinantialConfig } from '../interfaces/finantial-config.interface';

// Render
export default function PaydaySection() {
  const { t } = useTranslation();
  const prefix = 'configurations.finantial.payday';

  const finantialSettingsContext = useContext(FinantialSettingsContext);

  const { msgValidations } = useValidations();

  const { watch, setValue } = useFormContext<FinantialConfig>();

  const [payday] = watch(['payday']);

  const paydayOptions = useMemo<SelectOptionType[]>(
    () =>
      finantialSettingsContext?.formData?.paydays?.map((e) => ({
        label: e.name,
        value: +e.id,
      })) ?? [],
    [finantialSettingsContext?.formData?.paydays],
  );

  const paydayValue = useMemo<SelectOptionType | null>(
    () => paydayOptions?.find((e) => e?.value === payday) ?? null,
    [payday, paydayOptions],
  );

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <h2 className="text-primary fs-16 fw-600 text-uppercase">
          {t(`${prefix}.title`)}
        </h2>
      </Col>
      <Col sm={12} md={6} lg={3}>
        <Select
          name="payday"
          label={t(`${prefix}.inputs.payday`)}
          options={paydayOptions}
          value={paydayValue ?? undefined}
          disabled={finantialSettingsContext.disabled}
          onChange={(e) => {
            setValue('payday', +e?.value);
          }}
          rules={{ required: msgValidations.required }}
        />
      </Col>
    </Row>
  );
}
