import { Button, Icon, Modal } from '@octano/global-ui';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import { TranslationsKeys } from '../../../../locales/translations';

interface Props {
  onConfirm: (tuitionContinuityDiscountId: number) => void;
  onDecline: () => void;
}

export interface TuitionContinuityDiscountDeleteModalRef {
  openModal: (tuitionContinuityDiscountId: number) => void;
  closeModal: () => void;
}

const prefix = (key: string) => `actions.${key}`;

const TuitionContinuityDiscountDeleteModal = forwardRef<
  TuitionContinuityDiscountDeleteModalRef,
  Props
>(({ onConfirm, onDecline }, ref) => {
  const { t } = useTranslation(
    TranslationsKeys.TC_DISCOUNT_ALLOCATION_MANAGEMENT,
  );
  const [data, setData] = useState<number>();

  const [modal, setModal] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal(tuitionContinuityDiscountId: number) {
      setData(tuitionContinuityDiscountId);
      setModal(true);
    },
    closeModal() {
      setModal(false);
    },
  }));

  return (
    <Modal isOpen={modal} toggle={() => setModal(!modal)}>
      <Container fluid className="text-center">
        <Icon name="warning" size={45} />
        <p className="text-dark fs-20 mt-3 mb-4 text-center">
          {t(prefix('delete.title'))}
        </p>
        <p className="fs-16 mt-3 mb-4 mx-5  text-center">
          {t(prefix('delete.description'))}
        </p>

        <div className="d-flex justify-content-around" style={{ gap: 10 }}>
          <div className="flex-fill">
            <Button
              className="w-100"
              outlined
              text={t(prefix('delete.cancel'))}
              onClick={() => {
                onDecline();
                setModal(false);
              }}
            />
          </div>
          <div className="flex-fill">
            <Button
              className="w-100"
              text={t(prefix('delete.delete'))}
              onClick={() => {
                onConfirm(data!);
                setModal(false);
              }}
            />
          </div>
        </div>
      </Container>
    </Modal>
  );
});

export default TuitionContinuityDiscountDeleteModal;
