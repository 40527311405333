import { BackendModule } from 'i18next';

export const formatI18n = (value: any, format?: string) => {
  if (format === 'lowercase') {
    return value.toLowerCase();
  }
  if (format === 'uppercase') {
    return value.toUpperCase();
  }
  return value;
};

export const LazyImportPlugin: BackendModule = {
  type: 'backend',
  init: function (services, backendOptions, i18nextOptions) {},
  read: function (language, namespace, callback) {
    import(`../locales/${language}/${namespace}.json`).then((obj) => {
      callback(null, obj);
    });
  },

  save: function (language, namespace, data) {},

  create: function (languages, namespace, key, fallbackValue) {
    /* save the missing translation */
  },
};
