import { useTranslation } from 'react-i18next';
import { Card } from 'reactstrap';
import BaseLoader from './parts/BaseLoader';
import DiscountAllocationTable from './parts/DiscountAllocationTable';

import { FilterList } from './parts/PeriodsSelector';

export default function TuitionContinuityDiscountAllocation() {
  const { t } = useTranslation();
  return (
    <div className="student-grades-closure mx-3">
      <Card className="p-4">
        <div className="mb-3 fs-16">
          {t('tuitionContinuityDiscountAllocation.explanation')}
        </div>
        <BaseLoader>
          {({ data }) => <FilterList {...data}></FilterList>}
        </BaseLoader>
        <DiscountAllocationTable />
      </Card>
    </div>
  );
}
