import { Select, TextInput } from '@octano/global-ui';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import {
  useFilteredCities,
  useFilteredCommunes,
} from '../../../../hooks/useFilteredCommunes';
import { useParameters } from '../../../../hooks/useParameters';
import { useValidations } from '../../../../hooks/useValidations';
import { SectionTitle } from '../../../text';

const CHILE_LOWER_CASE = 'chile';

type DynamicContactType = 'studentFile' | 'sustainerFile';

type ContactSectionProps = {
  prefix: string;
  type: DynamicContactType;
  optionalFields?: string[];
  fieldNames?: {
    businessAddressStreet: string;
    businessAddressNumber: string;
    businessAddressExtra: string;
    businessCountry: string;
    businessRegion: string;
    businessCommune: string;
    businessCity: string;
    businessPhone: string;
    businessCellPhone: string;
    businessEmail: string;
    businessCustomCity: string;
    businessLocation: string;
  };
  disabled?: boolean;
  hasEmail?: boolean;
};

/**
 * Para usar este componente tu formulario debe estar envuelto en su FormProvider
 * para poder acceder a la información del formulario a través de useFormContext
 * Los nombres agregados en fieldNames se relacionan a los campos que utilizaste
 * al definir tu formulario.
 */
const DynamicContactBusiness = ({
  prefix,
  type,
  optionalFields = [],
  fieldNames = {
    businessAddressStreet: 'businessAddressStreet',
    businessAddressNumber: 'businessAddressNumber',
    businessAddressExtra: 'businessAddressExtra',
    businessCountry: 'businessCountry',
    businessRegion: 'businessRegion',
    businessCommune: 'businessCommune',
    businessPhone: 'businessPhone',
    businessCellPhone: 'businessCellPhone',
    businessEmail: 'businessEmail',
    businessLocation: 'businessLocation',
    businessCity: 'businessCity',
    businessCustomCity: 'businessCustomCity',
  },
  disabled = false,
  hasEmail = false,
}: ContactSectionProps) => {
  const { control, watch, formState, setValue } = useFormContext();
  const { t } = useTranslation();
  const {
    validateTextNotEmpty,
    validatePhone,
    validateEmail,
  } = useValidations();
  const { regionOptions, countryOptions } = useParameters();

  const ownSustainer = watch('ownSustainer');
  const selectedRegion = watch(fieldNames.businessRegion);
  const selectedCountry = watch(fieldNames.businessCountry);
  const rut = watch('rut');
  const filteredCommunes = useFilteredCommunes(selectedRegion);
  const filteredCities = useFilteredCities(selectedRegion);

  const isRegionTouched = formState.touchedFields[fieldNames.businessRegion];

  const isChile = useMemo(() => {
    return String(selectedCountry?.label)
      .toLowerCase()
      .includes(CHILE_LOWER_CASE);
  }, [selectedCountry?.label]);

  const optional = (value: string, fieldName: string) => {
    if (optionalFields.includes(fieldName)) {
      return undefined;
    }
    return validateTextNotEmpty(value);
  };

  useEffect(() => {
    if (rut) {
      setValue(
        fieldNames.businessCountry,
        countryOptions.find(
          (c) => c.label.toLocaleLowerCase() === CHILE_LOWER_CASE,
        ),
      );
    }
  }, [countryOptions, fieldNames.businessCountry, rut, setValue]);

  useEffect(() => {
    if (isRegionTouched) {
      setValue(fieldNames.businessCommune, null);
      setValue(fieldNames.businessCity, null);
    }
  }, [
    selectedRegion,
    setValue,
    isRegionTouched,
    fieldNames.businessCommune,
    fieldNames.businessCity,
  ]);

  return (
    <>
      <Row className="pt-5">
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-3" xs={12} lg={8}>
          <TextInput
            name={fieldNames.businessAddressStreet}
            label={t(`${prefix}.${fieldNames.businessAddressStreet}`)}
            control={control}
            rules={{
              validate: {
                require: (value) =>
                  optional(value, fieldNames.businessAddressStreet),
              },
            }}
            readOnly={disabled || ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames.businessAddressNumber}
            label={t(`${prefix}.${fieldNames.businessAddressNumber}`)}
            control={control}
            rules={{
              validate: {
                require: (value) =>
                  optional(value, fieldNames.businessAddressNumber),
              },
            }}
            readOnly={disabled || ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames.businessAddressExtra}
            label={t(`${prefix}.${fieldNames.businessAddressExtra}`)}
            control={control}
            readOnly={disabled || ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={fieldNames.businessCountry}
            label={t(`${prefix}.${fieldNames.businessCountry}`)}
            options={countryOptions}
            control={control}
            rules={{
              validate: {
                require: (value) => {
                  return optional(value, fieldNames.businessCountry);
                },
              },
            }}
            disabled={disabled || Boolean(rut) || ownSustainer === true}
          />
        </Col>
        {isChile ? (
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <Select
                name={fieldNames.businessRegion}
                label={t(`${prefix}.${fieldNames.businessRegion}`)}
                options={regionOptions}
                control={control}
                rules={{
                  validate: {
                    require: (value) =>
                      optional(value, fieldNames.businessRegion),
                  },
                }}
                disabled={disabled || ownSustainer === true}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <Select
                name={fieldNames.businessCity}
                label={t(`${prefix}.${fieldNames.businessCity}`)}
                options={filteredCities}
                control={control}
                rules={{
                  validate: {
                    require: (value) => {
                      return optional(value, fieldNames.businessCity);
                    },
                  },
                }}
                shouldUnregister
                disabled={disabled || ownSustainer === true}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <Select
                name={fieldNames.businessCommune}
                label={t(`${prefix}.${fieldNames.businessCommune}`)}
                options={filteredCommunes}
                control={control}
                rules={{
                  validate: {
                    require: (value) =>
                      optional(value, fieldNames.businessCommune),
                  },
                }}
                shouldUnregister
                disabled={disabled || ownSustainer === true}
              />
            </Col>
          </>
        ) : (
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name={fieldNames.businessLocation}
                label={t(`${prefix}.${fieldNames.businessLocation}`)}
                control={control}
                rules={{
                  validate: {
                    require: (value) =>
                      optional(value, fieldNames.businessLocation),
                  },
                }}
                shouldUnregister
                readOnly={disabled || ownSustainer === true}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name={fieldNames.businessCustomCity}
                label={t(`${prefix}.${fieldNames.businessCity}`)}
                control={control}
                rules={{
                  validate: {
                    require: (value) =>
                      optional(value, fieldNames.businessCustomCity),
                  },
                }}
                shouldUnregister
                readOnly={disabled || ownSustainer === true}
              />
            </Col>
          </>
        )}
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames.businessPhone}
            label={t(`${prefix}.${fieldNames.businessPhone}`)}
            control={control}
            formatter="phone"
            rules={{ validate: validatePhone }}
            placeholder={t(`common.placeholder.phone`)}
            readOnly={disabled || ownSustainer === true}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name={fieldNames.businessCellPhone}
            label={t(`${prefix}.${fieldNames.businessCellPhone}`)}
            control={control}
            formatter="phone"
            rules={{
              validate: {
                phone: validatePhone,
                require: (value) =>
                  optional(value, fieldNames.businessCellPhone),
              },
            }}
            placeholder={t(`common.placeholder.cellphone`)}
            readOnly={disabled || ownSustainer === true}
          />
        </Col>
        {hasEmail ? (
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name={fieldNames.businessEmail}
              label={t(`${prefix}.${fieldNames.businessEmail}`)}
              control={control}
              disabled={disabled}
              rules={{
                validate: {
                  required: validateTextNotEmpty,
                  email: validateEmail,
                },
              }}
              readOnly={disabled || ownSustainer === true}
            />
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default DynamicContactBusiness;
