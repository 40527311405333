import request from '../../../api/request';
import {
  AcademicLevelInfoResponse,
  AcademicLevelsFormDataResponse,
  AcademicLevelsResponse,
  FormDataAction,
} from './interfaces/type';

const SETTINGS_ACADEMIC_STRUCTURE = {
  FORM_DATA: () =>
    `maintainers-portal/general-configuration/academic-structure/form`,
  GET_ALL: () => `maintainers-portal/general-configuration/academic-structure/`,
  GET_INFO: (academicLevelId: string | number) =>
    `maintainers-portal/general-configuration/academic-structure/${academicLevelId}`,
  UPDATE: (academicLevelId: string | number) =>
    `maintainers-portal/general-configuration/academic-structure/${academicLevelId}`,
};

export async function getAllGradingStructure() {
  return request<AcademicLevelsResponse>(
    SETTINGS_ACADEMIC_STRUCTURE.GET_ALL(),
    {
      method: 'GET',
    },
  );
}

export async function getFormData() {
  return request<AcademicLevelsFormDataResponse>(
    SETTINGS_ACADEMIC_STRUCTURE.FORM_DATA(),
    {
      method: 'GET',
    },
  );
}

export async function getGradingStructureInfo(academicLevelId: number) {
  return request<AcademicLevelInfoResponse>(
    SETTINGS_ACADEMIC_STRUCTURE.GET_INFO(academicLevelId),
    {
      method: 'GET',
    },
  );
}

export async function updateGradingStructure(id: number, data: FormDataAction) {
  return request(SETTINGS_ACADEMIC_STRUCTURE.UPDATE(id), {
    method: 'PUT',
    data,
  });
}

// Services to strcuture

const SETTINGS_ACADEMIC_STRUCTURE_TYPE = {
  GET_STRUCTURE_TYPE_INFO: (structureTypeId: string | number) =>
    `/maintainers-portal/academic-offer/curriculum-services/${structureTypeId}`,
};

export async function getServicesToStructure(id: number) {
  return request(SETTINGS_ACADEMIC_STRUCTURE_TYPE.GET_STRUCTURE_TYPE_INFO(id), {
    method: 'GET',
  });
}
