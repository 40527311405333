import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//Textos para el modal de descuentos de matrícula de continuidad
export default function useTexts() {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      title: t('tuitionContinuityProcessManagementDetail.discount.title'),
      label: {
        name: t('common.forms.name'),
        code: t('common.forms.code'),
        percentage: t('common.forms.percentage'),
        type: t('common.forms.type'),
      },
      notFound: t('tuitionContinuityProcessManagementDetail.discount.notFound'),
      success: t(`tuitionContinuityProcessManagementDetail.discount.success`),
      error: t('common.errors.save'),
      actions: {
        cancel: t('common.actions.cancel'),
        confirm: t('common.actions.confirm'),
      },
    }),
    [t],
  );
}
