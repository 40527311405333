import { Select, TextInput } from '@octano/global-ui';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { useFilteredCommunes } from '../../../../hooks/useFilteredCommunes';
import { useParameters } from '../../../../hooks/useParameters';
import { useValidations } from '../../../../hooks/useValidations';
import { SectionTitle } from '../../../text';
import { DynamicContactType } from '../RenderSections';

const CHILE_LOWER_CASE = 'chile';

type ContactSectionProps = {
  prefix: string;
  type: DynamicContactType;
  optionalFields?: string[];
  fieldNames?: {
    businessAddressStreet: string;
    businessAddressNumber: string;
    businessAddressExtra: string;
    businessCountry: string;
    businessRegion: string;
    businessCommune: string;
    businessPhone: string;
    businessCellPhone: string;
    businessEmail: string;
    businessLocation: string;
  };
  disabled?: boolean;
  hasEmail?: boolean;
};

/**
 * Para usar este componente tu formulario debe estar envuelto en su FormProvider
 * para poder acceder a la información del formulario a través de useFormContext
 * Los nombres agregados en fieldNames se relacionan a los campos que utilizaste
 * al definir tu formulario.
 */
const DynamicContactCampusBusiness = ({
  prefix,
  type,
  optionalFields = [],
  fieldNames = {
    businessAddressStreet: 'businessAddressStreet',
    businessAddressNumber: 'businessAddressNumber',
    businessAddressExtra: 'businessAddressExtra',
    businessCountry: 'businessCountry',
    businessRegion: 'businessRegion',
    businessCommune: 'businessCommune',
    businessPhone: 'businessPhone',
    businessCellPhone: 'businessCellPhone',
    businessEmail: 'businessEmail',
    businessLocation: 'businessLocation',
  },
  disabled = false,
  hasEmail = false,
}: ContactSectionProps) => {
  const { t } = useTranslation();
  const { control, watch, formState, setValue } = useFormContext();
  const {
    validateTextNotEmpty,
    validatePhone,
    validateEmail,
  } = useValidations();
  const { regionOptions, countryOptions } = useParameters();

  const ownSustainer = watch('ownSustainer');
  const selectedRegion = watch(fieldNames.businessRegion);
  const rut = watch('rut');
  const filteredCommunes = useFilteredCommunes(selectedRegion);

  const isRegionTouched = formState.touchedFields[fieldNames.businessRegion];

  const optional = useCallback(
    (value: string, fieldName: string) => {
      if (optionalFields.includes(fieldName)) {
        return undefined;
      }
      return validateTextNotEmpty(value);
    },
    [optionalFields, validateTextNotEmpty],
  );

  useEffect(() => {
    if (rut) {
      setValue(
        fieldNames.businessCountry,
        countryOptions.find(
          (c) => c.label.toLocaleLowerCase() === CHILE_LOWER_CASE,
        ),
      );
    }
  }, [countryOptions, fieldNames.businessCountry, rut, setValue]);

  useEffect(() => {
    if (isRegionTouched) {
      setValue(fieldNames.businessCommune, null);
    }
  }, [selectedRegion, setValue, isRegionTouched, fieldNames.businessCommune]);

  const fields = useMemo(() => {
    return (
      <Fragment>
        <Row className="pt-5">
          <Col xs={12}>
            <SectionTitle text={t(`${prefix}.title`)} />
          </Col>
        </Row>
        <Row>
          <Col className="pb-3" xs={12} lg={8}>
            <TextInput
              name={fieldNames.businessAddressStreet}
              label={t(`${prefix}.${fieldNames.businessAddressStreet}`)}
              control={control}
              rules={{
                validate: {
                  require: (value) =>
                    optional(value, fieldNames.businessAddressStreet),
                },
              }}
              readOnly={disabled || ownSustainer === true}
            />
          </Col>
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name={fieldNames.businessAddressNumber}
              label={t(`${prefix}.${fieldNames.businessAddressNumber}`)}
              control={control}
              rules={{
                validate: {
                  require: (value) =>
                    optional(value, fieldNames.businessAddressNumber),
                },
              }}
            />
          </Col>
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name={fieldNames.businessAddressExtra}
              label={t(`${prefix}.${fieldNames.businessAddressExtra}`)}
              control={control}
            />
          </Col>

          <Col className="pb-3" xs={12} lg={4}>
            <Select
              name={fieldNames.businessRegion}
              label={t(`${prefix}.${fieldNames.businessRegion}`)}
              options={regionOptions}
              control={control}
              rules={{
                validate: {
                  require: (value) =>
                    optional(value, fieldNames.businessRegion),
                },
              }}
            />
          </Col>
          <Col className="pb-3" xs={12} lg={4}>
            <Select
              name={fieldNames.businessCommune}
              label={t(`${prefix}.${fieldNames.businessCommune}`)}
              options={filteredCommunes}
              control={control}
              rules={{
                validate: {
                  require: (value) =>
                    optional(value, fieldNames.businessCommune),
                },
              }}
              shouldUnregister
            />
          </Col>

          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name={fieldNames.businessPhone}
              label={t(`${prefix}.${fieldNames.businessPhone}`)}
              control={control}
              formatter="phone"
              rules={{ validate: validatePhone }}
              placeholder={t(`common.placeholder.phone`)}
            />
          </Col>
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name={fieldNames.businessCellPhone}
              label={t(`${prefix}.${fieldNames.businessCellPhone}`)}
              control={control}
              formatter="phone"
              rules={{
                validate: {
                  phone: validatePhone,
                  require: (value) =>
                    optional(value, fieldNames.businessCellPhone),
                },
              }}
              placeholder={t(`common.placeholder.cellphone`)}
            />
          </Col>
          {hasEmail ? (
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name={fieldNames.businessEmail}
                label={t(`${prefix}.${fieldNames.businessEmail}`)}
                control={control}
                disabled={disabled}
                rules={{
                  validate: {
                    required: validateTextNotEmpty,
                    email: validateEmail,
                  },
                }}
              />
            </Col>
          ) : null}
        </Row>
      </Fragment>
    );
  }, [
    disabled,
    fieldNames.businessAddressStreet,
    fieldNames.businessAddressNumber,
    fieldNames.businessAddressExtra,
    fieldNames.businessRegion,
    fieldNames.businessCommune,
    fieldNames.businessPhone,
    fieldNames.businessCellPhone,
    fieldNames.businessEmail,
    hasEmail,
    optional,
    ownSustainer,
    regionOptions,
    t,
    validateEmail,
    validatePhone,
    control,
    filteredCommunes,
    prefix,
    validateTextNotEmpty,
  ]);

  return <>{fields}</>;
};

export default DynamicContactCampusBusiness;
