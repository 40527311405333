import { Fragment, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SustainerType } from '../../../../utils/selectOptions';
import { DynamicContactType } from '../RenderSections';
import DynamicContactCampusBusiness from './DynamicContactCampusBusiness';
import DynamicContactCampusNormal from './DynamicContactCampusNormal';

type ContactSectionProps = {
  prefix: string;
  type: DynamicContactType;
  optionalFields?: string[];
  fieldNames?: {
    // Others
    addressStreet: string;
    addressNumber: string;
    addressExtra: string;
    country: string;
    region: string;
    commune: string;
    phone: string;
    cellPhone: string;
    email: string;
    location: string;
    // Business
    businessAddressStreet: string;
    businessAddressNumber: string;
    businessAddressExtra: string;
    businessCountry: string;
    businessRegion: string;
    businessCommune: string;
    businessPhone: string;
    businessCellPhone: string;
    businessEmail: string;
    businessLocation: string;
  };
  disabled?: boolean;
  hasEmail?: boolean;
};

/**
 * Para usar este componente tu formulario debe estar envuelto en su FormProvider
 * para poder acceder a la información del formulario a través de useFormContext
 * Los nombres agregados en fieldNames se relacionan a los campos que utilizaste
 * al definir tu formulario.
 */
const DynamicContactCampus = ({
  prefix,
  type,
  optionalFields = [],
  disabled = false,
  hasEmail = false,
}: ContactSectionProps) => {
  const { watch } = useFormContext();

  const sustainer = watch('sustainerType');

  const fields = useMemo(() => {
    if (sustainer?.value === SustainerType.LegalPerson) {
      return (
        <Fragment>
          <DynamicContactCampusBusiness
            prefix={prefix}
            type={type}
            optionalFields={optionalFields}
            disabled={disabled}
            hasEmail={hasEmail}
          />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <DynamicContactCampusNormal
          prefix={prefix}
          type={type}
          optionalFields={optionalFields}
          disabled={disabled}
          hasEmail={hasEmail}
        />
      </Fragment>
    );
  }, [sustainer, hasEmail, disabled, optionalFields, prefix, type]);

  return <>{fields}</>;
};

export default DynamicContactCampus;
