export enum TuitionContinuityDocumentStatusEnum {
  NOT_REVIEW = 'sin_revision',
  WAITING = 'en_espera',
  APPROVED = 'aprobado',
}

export interface StudentDocumentReviewInfDocumentLoaded {
  id: string;
  name: string;
  extension: string;
  type: string;
  status: TuitionContinuityDocumentStatusEnum;
  required: boolean;
  metadata: unknown;
  documentType: {
    id: number;
    code: string;
    name: string;
    icon: string;
  };
}

export interface StudentDocumentReviewInfDocumentNotLoaded {
  id?: undefined;
  type: string;
  required: boolean;
  documentType: {
    id: number;
    code: string;
    name: string;
    icon: string;
  };
}

export type StudentDocumentReviewInfDocument =
  | StudentDocumentReviewInfDocumentLoaded
  | StudentDocumentReviewInfDocumentNotLoaded;

export type StudentDocumentReviewInfDocumentOther = {
  id: string;
  name: string;
  extension: string;
  metadata?: { description?: string | null };
};

export interface StudentDocumentReviewInfResponseDto {
  student: {
    studentId: number;
    name: string;
    lastNames: string;
    paternalLastName: string;
    maternalLastName: string;
    paternalPreference: boolean;
    email: string;
    rut: string | null;
    passport: string | null;
    cellPhone?: string;
  };
  studyPlan: {
    studyPlanVersionName: string;
    studyPlanVersionOfferId: number;
    campusName: string;
    schedule: string;
  };
  periodId: number;
  documents: StudentDocumentReviewInfDocument[];
  documentsOthers: StudentDocumentReviewInfDocumentOther[];
}

export interface DocumentReviewStudentDocumentResponseDto {
  name: string;
  extension: string;
  url: string;
}

export type DocumentReviewStudentDocumentUploadResponseDto = StudentDocumentReviewInfDocument;

export type DocumentReviewStudentDocumentOtherUploadResponseDto = StudentDocumentReviewInfDocumentOther;
