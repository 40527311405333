import { Button } from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface GoBackToListButtonProps {
  text?: string;
  path?: string;
}
const GoBackToListButton = ({
  text: textProp,
  path,
}: GoBackToListButtonProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const text = useMemo(() => {
    if (textProp) {
      return textProp;
    }
    return t(`common:actions.backToList`);
  }, [t, textProp]);

  const onClick = useCallback(() => {
    if (path) {
      history.push(path);
    } else {
      history.goBack();
    }
  }, [history, path]);

  return (
    <Button
      type="button"
      text={text}
      icon="back"
      size="sm"
      outlined
      rounded
      color="secondary"
      onClick={onClick}
    />
  );
};

export default GoBackToListButton;
