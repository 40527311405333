import { useContext } from 'react';
import { Alert } from '@octano/global-ui';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { CourseFormContext } from '../providers/CourseProvider';
import { CourseFormMode } from '../types';

import GoBackButton from '../../../../components/buttons/GoBackButton';
import CourseDetailsBox from './CourseDetailsBox';

type CourseHeaderSectionProps = {
  onBack?: () => void;
};

const CourseHeaderSection = ({ onBack }: CourseHeaderSectionProps) => {
  const { t } = useTranslation();

  const { mode, course, isDisabledToUpdate } = useContext(CourseFormContext);

  return (
    <Row>
      <Col xs={12} lg={8}>
        <GoBackButton onClick={onBack} text={t(`common.actions.goBackStep`)} />
      </Col>
      {!!(mode !== CourseFormMode.CREATE && course) && (
        <Col xs={12} lg={4}>
          <CourseDetailsBox course={course} />
        </Col>
      )}
      {!!isDisabledToUpdate && (
        <Col xs={12}>
          <Alert
            className="mt-3 mb-2"
            color="info"
            icon="information"
            size="lg"
            text={t(`courses.update.infoEditMessage`)}
          />
        </Col>
      )}
    </Row>
  );
};

export default CourseHeaderSection;
