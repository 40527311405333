import { useContext } from 'react';
import { Button } from '@octano/global-ui';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { CourseFormContext } from '../providers/CourseProvider';
import { CourseFormMode } from '../types';

const prefix = 'courses.update';

type CourseFooterSectionProps = {
  processing?: boolean;
};

const CourseFooterSection = ({ processing }: CourseFooterSectionProps) => {
  const { t } = useTranslation();

  const { mode, courseId } = useContext(CourseFormContext);

  if (mode === CourseFormMode.WATCH) {
    return <></>;
  }

  return (
    <Row className="py-5 justify-content-end pt-5">
      <Col xs={12} lg={3} className="pb-2 order-2 order-lg-1">
        <Button
          type="button"
          outlined
          onClick={() => {}}
          text={t(`common.actions.cancel`)}
          disabled={!!processing}
          fullwidth
        />
      </Col>
      <Col xs={12} lg={3} className="pb-2 order-1 order-lg-2">
        <Button
          type="submit"
          text={
            courseId ? t(`${prefix}.updateCourse`) : t(`${prefix}.createCourse`)
          }
          loading={!!processing}
          fullwidth
        />
      </Col>
    </Row>
  );
};

export default CourseFooterSection;
