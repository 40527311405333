import {
  TextInput,
  TextAreaInput,
  FileInputControlled,
} from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { Col } from 'reactstrap';
import { useValidations } from '../../../../../../../hooks/useValidations';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../../../../locales/translations';

export interface FormData {
  name: string;
  description: string;
  file: File | null;
  fileName?: string;
}

const dictPrefix = 'createModal';

const CreateOrUpdateForm = () => {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_ADDITIONAL_DOCUMENTATION,
  );
  const forms = useFormContext<FormData>();
  const { control, watch } = forms;
  const { msgValidations } = useValidations();

  const fileName = watch('fileName');

  return (
    <>
      <Col xs={12}>
        <TextInput
          name="name"
          label={t(`${dictPrefix}.inputs.name.label`)}
          placeholder={t(`${dictPrefix}.inputs.name.placeholder`)}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12} className="mt-4">
        <TextAreaInput
          name="description"
          label={t(`${dictPrefix}.inputs.description.label`)}
          placeholder={t(`${dictPrefix}.inputs.description.placeholder`)}
          control={control}
        />
      </Col>
      <Col xs={12} className="mt-4">
        <FileInputControlled
          name="file"
          label={t(`${dictPrefix}.inputs.file.label`)}
          btnText={t(
            `${dictPrefix}.inputs.file.${fileName ? 'btnReplace' : 'btn'}`,
          )}
          accept="image/png, image/jpeg, application/pdf"
          control={control}
          rules={!fileName ? { required: msgValidations.required } : undefined}
        />
      </Col>
    </>
  );
};

export default CreateOrUpdateForm;
