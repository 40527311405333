import { ReactElement, useCallback } from 'react';

import {
  GetPeriodOFMantainerPortalResponse as Base,
  getPeriodOFMantainerPortal as getBase,
} from '../../../api/requests/MantainerPortal';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';

interface BaseLoaderProps<T> {
  children: (props: { data: T; refresh: () => Promise<void> }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<undefined, Base>();

export const BaseLoaderConsumer = FetchConsumer;

export function BaseLoader({ children }: BaseLoaderProps<Base>) {
  const request = useCallback(() => {
    return getBase();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return (
              <div className="mx-3">
                <Loading insideCard />
              </div>
            );
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({ data, refresh });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
