import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResultDefaultError } from '../../../api/request';
import { AuthenticationError } from '../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import DisplayInfo from '../../../components/info/DisplayInfo';
import Loading from '../../../components/info/Loading';
import searchStudent from '../api';

interface BaseLoaderProps {
  searchComponent: ReactElement;
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  { identifier: string | number; country: string | null },
  any,
  AuthenticationError | AxiosResultDefaultError
>();

export function useStudentCurrentAccountLoader() {
  return useFetch();
}

export const studentCurrentAccountLoaderConsumer = FetchConsumer;

const keyPrefix = 'studentCurrentAccount.search';

export default function StudentCurrentAccountLoader({
  searchComponent,
  children,
}: BaseLoaderProps) {
  const { t } = useTranslation();
  const request = useCallback(
    async (query: { identifier: string | number; country: string | null }) => {
      return searchStudent(query);
    },
    [],
  );

  return (
    <FetchProvider
      request={request}
      defaultQuery={{ identifier: '', country: null }}
      fetchImmediately={false}
    >
      {searchComponent}
      <FetchConsumer>
        {({ loading, data, error, refresh, query }) => {
          if (loading && query.identifier !== '') {
            return <Loading insideCard />;
          } else if (error) {
            return (
              <DisplayError
                insideCard
                title={
                  error.status === 404
                    ? t(`${keyPrefix}.error.title`)
                    : undefined
                }
                textBody={
                  error.status === 404
                    ? t(`${keyPrefix}.error.body`)
                    : error.code
                }
                retryAction={refresh}
              />
            );
          } else if (data) {
            return children;
          } else {
            return (
              <DisplayInfo
                insideCard
                title="Para continuar, realiza una búsqueda."
                textBody="Para consultar las deudas del alumno, realiza una búsqueda por nombre o ID."
              />
            );
          }
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
