import { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { TextInput, Select } from '@octano/global-ui';
import { useValidations } from '../../../../hooks/useValidations';

import { CourseFormContext } from '../providers/CourseProvider';
import { SectionTitle } from '../../../../components/text';
import { CourseFormFields } from '../types';

const prefix = 'courses.update';

const CourseDetailsSection = () => {
  const { t } = useTranslation();
  const { msgValidations, rulesPatternNumeric } = useValidations();

  const { control } = useFormContext<CourseFormFields>();

  const { isDisabledToUpdate, formData, course } =
    useContext(CourseFormContext);
  const {
    schools = [],
    academicLevels = [],
    modalities = [],
    retakeExamOptions = [],
    coursesFeatures,
  } = formData;

  return (
    <Row>
      <Col xs={12} className="pt-4 pb-3">
        <SectionTitle text={t(`${prefix}.dataCourse`)} />
      </Col>
      <Col xs={6} lg={4}>
        <TextInput
          name="shortening"
          label={t(`${prefix}.shortening`)}
          control={control}
          rules={{ required: msgValidations.required }}
          disabled={isDisabledToUpdate}
        />
      </Col>
      <Col xs={6} lg={4}>
        <TextInput
          name="code"
          label={t(`${prefix}.code`)}
          control={control}
          rules={{ required: msgValidations.required }}
          disabled={isDisabledToUpdate}
        />
      </Col>
      <Col xs={12} lg={4}>
        <TextInput
          name="name"
          label={t(`${prefix}.name`)}
          control={control}
          rules={{ required: msgValidations.required }}
          disabled={isDisabledToUpdate}
        />
      </Col>
      <Col xs={12} lg={4}>
        <Select
          name="school"
          label={t(`${prefix}.school`)}
          options={schools}
          control={control}
          rules={{ required: msgValidations.required }}
          disabled={isDisabledToUpdate}
        />
      </Col>
      <Col xs={12} lg={4}>
        <Select
          name="modality"
          label={t(`${prefix}.modality`)}
          options={modalities}
          control={control}
          isClearable={true}
          disabled={course?.modalityId ? isDisabledToUpdate : false}
        />
      </Col>
      <Col xs={12} lg={4}>
        <Select
          name="academicLevel"
          label={t(`${prefix}.academicLevel`)}
          options={academicLevels}
          control={control}
          rules={{ required: msgValidations.required }}
          disabled={isDisabledToUpdate}
        />
      </Col>
      <Col xs={12} lg={4}>
        <TextInput
          name="credits"
          label={t(`${prefix}.credits`)}
          control={control}
          rules={{
            required: msgValidations.required,
            pattern: rulesPatternNumeric,
          }}
          disabled={isDisabledToUpdate}
        />
      </Col>
      {!!coursesFeatures?.retakeExamAllowed && (
        <Col xs={12} lg={4}>
          <Select
            name="retakeExamAllowed"
            label={t(`${prefix}.retakeExamAllowed`)}
            options={retakeExamOptions}
            control={control}
            disabled={isDisabledToUpdate}
          />
        </Col>
      )}
    </Row>
  );
};

export default CourseDetailsSection;
