import { useContext, useCallback, useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FileInputControlled, Button } from '@octano/global-ui';

import { CourseFormContext } from '../providers/CourseProvider';
import { SectionTitle } from '../../../../components/text';
import { CourseFormFields, CourseFormMode } from '../types';

const prefix = 'courses.update';

const CourseSyllabusSection = () => {
  const { t } = useTranslation();

  const { control, watch } = useFormContext<CourseFormFields>();

  const { course, mode } = useContext(CourseFormContext);

  const [syllabusFileInput] = watch(['syllabusFile']);

  const canDownloadSyllabus = useMemo(
    () => syllabusFileInput?.lastModified === -2,
    [syllabusFileInput?.lastModified],
  );

  const handleDownloadSyllabus = useCallback(() => {
    if (!course?.syllabus?.url?.trim()) {
      return;
    }
    window.open(course?.syllabus?.url?.trim(), '_blank');
  }, [course?.syllabus?.url]);

  return (
    <Row>
      <Col xs={12} className="pt-4">
        <SectionTitle text={t(`${prefix}.syllabusTitle`)} />
      </Col>
      <Col xs={12}>
        <p className="fs-16 fw-300">{t(`${prefix}.syllabusDescription`)}</p>
      </Col>
      <Col xs={12}>
        {mode !== CourseFormMode.WATCH ? (
          <FileInputControlled
            btnText=""
            name="syllabusFile"
            label=""
            accept="application/pdf, application/xls, application/xlsx, application/docx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            control={control}
            onDownloadFile={
              canDownloadSyllabus ? handleDownloadSyllabus : undefined
            }
            shouldUnregister
          />
        ) : (
          <Button
            text={t(`${prefix}.syllabusDownload`)}
            icon="download"
            outlined
            size="md"
            className="w-100"
            disabled={!canDownloadSyllabus}
            onClick={canDownloadSyllabus ? handleDownloadSyllabus : undefined}
          />
        )}
      </Col>
    </Row>
  );
};

export default CourseSyllabusSection;
