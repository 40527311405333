import { Button, SelectOptionType } from '@octano/global-ui';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Col, Form, Row } from 'reactstrap';

import { useTexts } from '../../hooks/textsContext';
import useUpdateAction from '../../hooks/useUpdateAction';
import { FormDataAction, StructureTypeCode } from '../../interfaces/type';
import { useCreateEvaluationFormContext } from '../EvaluationForm/context/CreateEvaluationFormContext';
import { GradesConfig } from '../EvaluationForm/types';
import HeaderStructureTypeFormSemiStaticFields from '../Header/HeaderStructureTypeFormSemiStaticFields';
import { useGradingStructureInfoLoader } from '../Loaders/GradingStructureInfoLoader';
import UpdateStructureTypeFormFields from './UpdateStructureTypeFormFields';
import UpdateStructureTypeFormFreeFields from './UpdateStructureTypeFormFreeFields';
import UpdateStructureTypeFormSemiStaticFields from './UpdateStructureTypeFormSemiStaticFields';

export function UpdateStructureTypeForm(): JSX.Element {
  const texts = useTexts();

  const { data: initialValues } = useGradingStructureInfoLoader();
  const { gradeEntry, handleValidate } = useCreateEvaluationFormContext();
  const { update } = useUpdateAction();

  const methods = useForm<{
    id: number | null;
    structureTypeId: SelectOptionType | null;
    finalGrade: GradesConfig['finalGrade'];
    testGrades: GradesConfig['testGrades'];
  }>({
    mode: 'onChange',
    defaultValues: {
      structureTypeId: {
        value: initialValues?.structureType?.id,
        label: initialValues?.structureType?.name,
      },
    },
  });
  const { handleSubmit, watch, formState } = methods;
  const { isSubmitting } = formState;
  const structureType = watch('structureTypeId');

  const onSubmit = useCallback(
    async (values: FormDataAction) => {
      if (initialValues?.id) {
        if (values?.structureTypeId?.value === StructureTypeCode.FREE) {
          return update(initialValues.id, {
            ...values,
            finalGrade: null,
            testGrades: null,
          });
        } else {
          return handleValidate(() =>
            update(initialValues.id, {
              ...values,
              finalGrade: gradeEntry.finalGrade,
              testGrades: gradeEntry.testGrades,
            }),
          )();
        }
      }
    },
    [initialValues?.id, gradeEntry, handleValidate, update],
  );

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <UpdateStructureTypeFormFields />

        {structureType?.value === StructureTypeCode.FIXED && (
          <article>
            <HeaderStructureTypeFormSemiStaticFields
              msgInfo={texts.form.msg.msgInfoFixed}
            />
            <UpdateStructureTypeFormSemiStaticFields />
          </article>
        )}

        {structureType?.value === StructureTypeCode.SEMIFIXED && (
          <article>
            <HeaderStructureTypeFormSemiStaticFields
              msgInfo={texts.form.msg.msgInfoSemiFixed}
            />
            <UpdateStructureTypeFormSemiStaticFields />
          </article>
        )}

        {structureType?.value === StructureTypeCode.FREE && (
          <article>
            <UpdateStructureTypeFormFreeFields />
          </article>
        )}

        <Row className="justify-content-end p-0 m-0">
          <Col xs={12} sm={4} className="p-0 m-0">
            <Button
              size="md"
              type="submit"
              text={texts.actions.save}
              fullwidth
              loading={isSubmitting}
            />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
}
