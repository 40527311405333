import { Button, Modal } from '@octano/global-ui';
import { forwardRef, useImperativeHandle, useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TeacherAdditionalDocument } from '../../type';
import CreateOrUpdateForm, { FormData } from './CreateOrUpdateForm';
import { UseActions } from '../../hooks/useActions';
import { TranslationsKeys } from '../../../../../../../locales/translations';

interface Props {
  actions: UseActions;
}

export interface CreateOrUpdateRef {
  closeModal: () => void;
  openCreate: (teacherId: number) => void;
  openUpdate: (documentId: number, row: TeacherAdditionalDocument) => void;
}

const dictPrefix = 'createModal';

const defaultForm = {
  name: '',
  description: '',
  file: null,
};

const CreateOrUpdateModal = forwardRef<CreateOrUpdateRef, Props>(
  ({ actions }, ref) => {
    const { t } = useTranslation(
      TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_ADDITIONAL_DOCUMENTATION,
    );
    const forms = useForm<FormData>({ defaultValues: defaultForm });
    const {
      reset,
      handleSubmit,
      formState: { isSubmitting },
    } = forms;
    const [modal, setModal] = useState(false);

    const [teacherId, setTeacherId] = useState<number>(0);
    const [documentId, setDocumentId] = useState<number>(0);

    const handleClose = useCallback(() => {
      setModal(false);
      setTeacherId(0);
      setDocumentId(0);
      reset(defaultForm);
    }, [reset]);

    const handleOpen = useCallback(
      (row?: TeacherAdditionalDocument) => {
        if (row) {
          reset({
            name: row.name,
            description: row.description ?? '',
            fileName: row?.file?.name ?? '',
          });
        } else {
          reset(defaultForm);
        }
        setModal(true);
      },
      [reset],
    );

    const handleOpenCreate = useCallback(
      (id: number) => {
        setTeacherId(id);
        setDocumentId(0);
        handleOpen(undefined);
      },
      [handleOpen],
    );

    const handleOpenUpdate = useCallback(
      (id: number, row: TeacherAdditionalDocument) => {
        setDocumentId(id);
        setTeacherId(0);
        handleOpen(row);
      },
      [handleOpen],
    );

    useImperativeHandle(ref, () => ({
      openCreate: handleOpenCreate,
      openUpdate: handleOpenUpdate,
      closeModal: handleClose,
    }));

    const onSubmit = useCallback(
      async (values: FormData) => {
        const payload: any = {
          name: values.name,
          description: values.description?.trim() ?? null,
        };
        if (documentId) {
          payload.file = values.file;
          await actions.updateDocument({
            ...payload,
            id: documentId,
          });
        } else {
          if (values.file) {
            payload.file = values.file;
          }
          await actions.createDocument({
            ...payload,
            teacherId,
          });
        }
      },
      [documentId, actions, teacherId],
    );

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <h1 className="text-dark fs-20 mt-3 mb-4 text-center">
            {t(`${dictPrefix}.${documentId ? 'titleUpdate' : 'titleCreate'}`)}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...forms}>
              <Row>
                <CreateOrUpdateForm />

                <Col xs={12} className="py-4" />
                <Col xs={12} md={6}>
                  <Button
                    outlined
                    text={t(`${dictPrefix}.actions.close`)}
                    fullwidth
                    onClick={() => setModal(false)}
                    className="mb-3"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Button
                    type="submit"
                    text={t(
                      `${dictPrefix}.actions.${
                        documentId ? 'update' : 'create'
                      }`,
                    )}
                    className="mb-3"
                    fullwidth
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </Col>
              </Row>
            </FormProvider>
          </form>
        </Container>
      </Modal>
    );
  },
);

export default CreateOrUpdateModal;
