import { Button, Modal, TextInput } from '@octano/global-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useValidations } from '../../../../hooks/useValidations';
import { TranslationsKeys } from '../../../../locales/translations';

interface Props {
  isOpen: boolean;
  title?: string;
  description?: string;
  onConfirm: ({ email }: { email: string }) => void;
  onCancel: () => void;
  isLoading?: boolean;
  initialEmail?: string;
}

export default function EmailConfirmModal({
  isOpen,
  title,
  description,
  onConfirm = () => null,
  onCancel = () => null,
  isLoading = false,
  initialEmail,
}: Props) {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_ACTIVATION,
  );
  const prefix = 'emailConfirmation';
  const { validateEmail, validateTextNotEmpty } = useValidations();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      email: initialEmail || '',
    },
  });

  useEffect(() => {
    reset({ email: initialEmail });
  }, [initialEmail, reset]);

  return (
    <Modal isOpen={isOpen} toggle={() => null} onClosed={() => reset()}>
      <form
        className="d-flex flex-column align-items-center"
        onSubmit={handleSubmit(onConfirm)}
      >
        <h1 className="text-dark fs-20 mb-0 mt-2 text-center">
          {title?.trim() ?? t(`${prefix}.title`)}
        </h1>
        <p className="fs-16 mt-4 mb-3 lh-30 text-center">
          {description?.trim() ?? t(`${prefix}.description`)}
        </p>

        <div className="w-100 mt-3 mb-4">
          <TextInput
            label="Email"
            name="email"
            control={control}
            rules={{
              validate: {
                email: validateEmail,
                notEmpty: validateTextNotEmpty,
              },
            }}
          />
        </div>
        <div className="d-flex w-100 flex-wrap" style={{ gap: 22 }}>
          <Button
            text={t(`common:actions.cancel`)}
            outlined
            size="lg"
            onClick={onCancel}
            className="flex-grow-1"
            style={{ width: 191 }}
            disabled={isLoading}
          />
          <Button
            text={t(`common:actions.confirm`)}
            size="lg"
            className="flex-grow-1"
            style={{ width: 191 }}
            type="submit"
            loading={isLoading}
          />
        </div>
      </form>
    </Modal>
  );
}
