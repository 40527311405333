import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResultDefaultError } from '../../../api/request';
import { AuthenticationError } from '../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import DisplayInfo from '../../../components/info/DisplayInfo';
import Loading from '../../../components/info/Loading';
import { PaginationRequestType } from '../../../types/paginationRequestType';
import { getDiscountAllocationsManagement } from '../api';
import {
  DiscountManagementFilter,
  DiscountManagementListResponse,
} from '../types';

interface TuitionContinuityDiscountManagemenProps {
  children: ReactElement[];
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  DiscountManagementFilter,
  PaginationRequestType<DiscountManagementListResponse>,
  AuthenticationError | AxiosResultDefaultError
>();

export function useTuitionContinuityDiscountManagementLoader() {
  return useFetch();
}

export const TuitionContinuityDiscountManagemenConsumer = FetchConsumer;

const keyPrefix = 'studentCurrentAccount.search';

export default function TuitionContinuityDiscountManagementLoader({
  children,
}: TuitionContinuityDiscountManagemenProps) {
  const { t } = useTranslation();
  const request = useCallback(async (query: DiscountManagementFilter) => {
    return getDiscountAllocationsManagement({
      ...query,
      items: 10,
      page: query.page - 1,
    });
  }, []);

  return (
    <FetchProvider
      request={request}
      defaultQuery={{ page: 1, items: 10 }}
      fetchImmediately={false}
    >
      {children[0]}
      <FetchConsumer>
        {({ loading, data, error, refresh, query }) => {
          if (loading && query.periodId) {
            return <Loading insideCard />;
          } else if (error) {
            return (
              <DisplayError
                insideCard
                title={
                  error.status === 404
                    ? t(`${keyPrefix}.error.title`)
                    : undefined
                }
                textBody={
                  error.status === 404
                    ? t(`${keyPrefix}.error.body`)
                    : error.code
                }
                retryAction={refresh}
              />
            );
          } else if (data) {
            return children[1];
          } else {
            return (
              <DisplayInfo
                insideCard
                title="Para continuar, realiza una búsqueda."
                textBody="Para consultar las deudas del alumno, realiza una búsqueda por nombre o ID."
              />
            );
          }
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
