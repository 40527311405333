import { Module } from '../../types/sectionSchedules';
import dayjs from 'dayjs';

export const formatModuleLabel = (
  module: Module,
  timeKey: 'endTime' | 'startTime' | 'both',
) => {
  const dates = {
    startTime: dayjs().format(`YYYY-MM-DDT[${module.startTime}]`),
    endTime: dayjs().format(`YYYY-MM-DDT[${module.endTime}]`),
  };
  const time: string[] = [];
  if (timeKey === 'both') {
    Object.values(dates).forEach((v) => {
      time.push(dayjs(v).format('HH:mm'));
    });
  } else {
    time.push(dayjs(dates[timeKey]).format('HH:mm'));
  }

  return `${module.shortening} ${time.join(' - ')}`;
};
