import { Col, Row } from 'reactstrap';
import EndCourseGradeTable from './parts/Table/EndCourseGradeTable';
import EvaluationsTable from './parts/Table/EvaluationsTable';

export default function EvaluationForm() {
  return (
    <Row className="mt-2 justify-content-between">
      <Col lg={3}>
        <EndCourseGradeTable />
      </Col>
      <Col lg={9} xl={8}>
        <EvaluationsTable />
      </Col>
    </Row>
  );
}
