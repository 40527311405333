import { Box, useMobile } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useTeacherInfoLoader } from '../Loaders/TeacherInfoLoader';
import { format as formatRut } from 'rut.js';
import { TranslationsKeys } from '../../../../../../locales/translations';

export default function TeacherDetailBox() {
  const prefix = 'teacherDetailsBox';
  const { t } = useTranslation(TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE);
  const isMobile = useMobile();

  const { data: teacherInfo } = useTeacherInfoLoader();

  return (
    <Box
      color="primary"
      variant="outlined"
      style={{ borderRadius: 4 }}
      className={`py-2 px-3 ${isMobile && 'mt-4 w-100'}`}
      body={
        <div className="d-flex justify-content-between">
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-70 text-uppercase"
            title={t(`${prefix}.docente`)}
            style={{ minWidth: isMobile ? 0 : 140 }}
            body={
              <p className="fs-14 mb-0 pr-2 text-capitalize">
                {teacherInfo?.account?.fullName}
              </p>
            }
          />
          <Box
            color="primary"
            variant="outlined"
            className="p-0 border-0 w-30 text-uppercase"
            title={t(`${prefix}.rut`)}
            style={{ minWidth: isMobile ? 0 : 140 }}
            body={
              <p className="fs-14 mb-0 text-capitalize">
                {teacherInfo?.account?.run &&
                  formatRut(teacherInfo?.account?.run)}
              </p>
            }
          />
        </div>
      }
    />
  );
}
