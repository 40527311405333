import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
} from '@octano/global-ui';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import DisplayError from '../../../components/info/DisplayError';
import { useValidations } from '../../../hooks/useValidations';
import { TranslationsKeys } from '../../../locales/translations';
import { formatDate } from '../../../utils/dates';
import { FormData } from '../types';
import { useTuitionContinuityDiscountManagementFilterLoader } from './TuitionContinuityDiscountManagementFilterLoader';
import { useTuitionContinuityDiscountManagementLoader } from './TuitionContinuityDiscountManagementLoader';

export const TuitionContinuityDiscontFilter = () => {
  const { t } = useTranslation(
    TranslationsKeys.TC_DISCOUNT_ALLOCATION_MANAGEMENT,
  );
  const { msgValidations } = useValidations();
  const { data } = useTuitionContinuityDiscountManagementFilterLoader();
  const { updateQuery, query } = useTuitionContinuityDiscountManagementLoader();
  const {
    periods,
    // schools,
    // studyPlans,
    // campuses,
    // entryPeriods,
    // schedules,
    // modalities,
    // genders,
  } = data!;

  const { control, handleSubmit, formState } = useForm<FormData>({
    defaultValues: {
      period: null,
    },
  });

  const optionsPeriods = useMemo<OutlinedSelectOptionType[]>(() => {
    return periods.map((p) => ({
      value: p.id,
      label: p.name,
    }));
  }, [periods]);

  if (Array.isArray(optionsPeriods) && optionsPeriods.length === 0) {
    return (
      <DisplayError
        insideCard
        textBody="No hay eventos de matrícula de continuidad definidos. Intenta más tarde"
      />
    );
  }

  const handleDownload = async (data: FormData) => {
    const submitData = {
      periodId: Number(data.period?.value),
      schoolId: Number(data.school?.value),
      studyPlanId: Number(data.studyPlan?.value),
      entryPeriodId: Number(data.entryPeriod?.value),
      campusId: Number(data.campus?.value),
      scheduleId: Number(data.schedule?.value),
      modalityId: Number(data.modality?.value),
      dateFrom: data.dateFrom
        ? formatDate(data.dateFrom, 'YYYY-MM-DD')
        : undefined,
      dateTo: data.dateTo ? formatDate(data.dateTo, 'YYYY-MM-DD') : undefined,
      genderId: Number(data.gender?.value),
    };

    updateQuery({ ...query, periodId: submitData.periodId });

    console.log(submitData);
  };

  return (
    <Form
      onSubmit={handleSubmit(handleDownload)}
      className="g-table-container mt-2 pb-4 pt-4"
    >
      <Row>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="period"
            control={control}
            label={''}
            options={optionsPeriods}
            rules={{ required: msgValidations.required }}
          />
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="pl-0">
          <Button
            text={t('search')}
            type="submit"
            size="md"
            loading={formState.isSubmitting}
            disabled={formState.isSubmitting}
            className="mb-3 mb-md-0"
          />
        </Col>
      </Row>

      {/* <Row className="pt-5">
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="school"
            control={control}
            label={''}
            isClearable
            options={schools.map((school) => ({
              value: school.id,
              label: school.name,
            }))}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="studyPlan"
            control={control}
            label={''}
            isClearable
            options={studyPlans.map((studyPlan) => ({
              value: studyPlan.id,
              label: studyPlan.name,
            }))}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="entryPeriod"
            control={control}
            label={''}
            isClearable
            options={entryPeriods.map((entryPeriod) => ({
              value: entryPeriod.id,
              label: entryPeriod.name,
            }))}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="campus"
            control={control}
            label={''}
            isClearable
            options={campuses.map((campus) => ({
              value: campus.id,
              label: campus.name,
            }))}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="schedule"
            control={control}
            label={''}
            isClearable
            options={schedules.map((schedule) => ({
              value: schedule.id,
              label: schedule.name,
            }))}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="modality"
            control={control}
            label={''}
            isClearable
            options={modalities.map((modality) => ({
              value: modality.id,
              label: modality.name,
            }))}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <DateInput name="dateFrom" control={control} label={''} />
        </Col>
        <Col lg={4} className="pl-0">
          <DateInput name="dateTo" control={control} label={''} />
        </Col>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="gender"
            control={control}
            label={''}
            isClearable
            options={genders.map((gender) => ({
              value: gender.id,
              label: gender.name,
            }))}
          />
        </Col>
      </Row> */}
    </Form>
  );
};
