import { createElement } from 'react';

import { Report } from '../../../../types/Reports';
import CertificateOfCompletionIssuedActions from './CertificateOfCompletionIssuedActions';
import ReportAcademicActions from './ReportAcademicActions';
import SectionGradesActions from './SectionGradesActions';
import StudentProgressActions from './StudentProgressActions';
import StudentSectionEnrolledActions from './StudentSectionEnrolledActions';
import StudentsWithoutEmail from './StudentsWithoutEmailActions';
import TeacherAttendanceActions from './TeacherAttendanceActions';
import TuitionsPeriodConsolidatedActions from './TuitionsPeriodConsolidatedActions';

const getActionsByReportType = (reportType: Report['type']) => {
  const COMPONENTS = {
    STUDENTS_WITHOUT_EMAIL: StudentsWithoutEmail,
    LESSONS_TEACHER: TeacherAttendanceActions,
    SECTIONS_GRADES: SectionGradesActions,
    STUDENT_SECTION_ENROLLED: StudentSectionEnrolledActions,
    TUITIONS_PERIOD_CONSOLIDATED: TuitionsPeriodConsolidatedActions,
    ACADEMIC: ReportAcademicActions,
    STUDENT_PROGRESS: StudentProgressActions,
    CERTIFICATE_OF_COMPLETION_ISSUED: CertificateOfCompletionIssuedActions,
  };

  return COMPONENTS[reportType];
};

interface Props {
  report: Report;
}

export default function ReportActionsCell({ report }: Props) {
  const { type, downloadUrl, renderSide } = report;
  return createElement(getActionsByReportType(type), {
    downloadUrl,
    renderSide,
  });
}
