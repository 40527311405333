import { DetailBox } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../utils/dates';
import { Course } from '../types';

type CourseDetailsBoxProps = {
  course: Course;
};

const CourseDetailsBox = ({ course }: CourseDetailsBoxProps) => {
  const { t } = useTranslation();

  return (
    <DetailBox
      columns={[
        {
          title: t('common.createdAt'),
          body: course ? formatDate(course.createdAt, 'DD-MM-YYYY') : '',
          size: { xs: 12, md: 6 },
        },
        {
          title: t('common.updatedAt'),
          body: course ? formatDate(course.updatedAt, 'DD-MM-YYYY') : '',
          size: { xs: 12, md: 6 },
        },
      ]}
    />
  );
};

export default CourseDetailsBox;
