import {
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
  SearchBox,
  useMobile,
  addToast,
} from '@octano/global-ui';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { downloadStudentsDegreeList } from '../../../api/requests/studentsDegree';

import { useValidations } from '../../../hooks/useValidations';
import { STUDENT_STATUS } from '../../../types/studentStatusTypes';
import { downloadFromBlob } from '../../../utils/blob';
import { useStudentsDegreeFormLoader } from '../contexts/StudentsDegreeFormLoader';
import { useStudentsDegreeLoader } from '../contexts/StudentsDegreeLoader';
import { SelectOptionWithDefaultValue } from '../types/StudentsDegree';

const dictPrefix = 'studentsDegree.searchControls';

const DEFAULT_VALUES = {
  search: '',
  studyPlanId: null,
  campusId: null,
  status: {
    label: 'Todos los estados',
    value: null,
  },
};

interface FormValues {
  search: string;
  studyPlanId: OutlinedSelectOptionType | null;
  campusId: OutlinedSelectOptionType | null;
  status: SelectOptionWithDefaultValue;
}

type SearchControlsProps = {
  status: STUDENT_STATUS;
};

export function SearchControls({ status }: SearchControlsProps) {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const { validateMinLength } = useValidations();

  const { updateQuery, loading = false } = useStudentsDegreeLoader();
  const { data } = useStudentsDegreeFormLoader();

  const [downloading, setDownloading] = useState<boolean>(false);

  const studyPlans = useMemo<OutlinedSelectOptionType[]>(() => {
    return data.studyPlans.map((i) => ({
      label: i.name,
      value: i.id,
    }));
  }, [data]);
  const campuses = useMemo<OutlinedSelectOptionType[]>(() => {
    return data.campuses.map((i) => ({
      label: i.name,
      value: i.id,
    }));
  }, [data]);

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
  });

  const handleSearch = useCallback(
    (values: FormValues) => {
      const search = values.search?.trim() !== '' ? values.search : undefined;
      const studyPlanId = values.studyPlanId?.value as number;
      const campusId = values.campusId?.value as number;

      updateQuery({ search, studyPlanId, campusId, page: 1 });
    },
    [updateQuery],
  );

  const handleDownload = useCallback(
    async (values: FormValues) => {
      if (downloading) {
        return;
      }
      setDownloading(true);
      const search = values.search?.trim() !== '' ? values.search : undefined;
      const studyPlanId = values.studyPlanId?.value as number;
      const campusId = values.campusId?.value as number;
      const response = await downloadStudentsDegreeList({
        search,
        studyPlanId,
        campusId,
        status,
      });
      const { data, error } = response;

      if (error) {
        addToast({
          text: t(`${dictPrefix}.failedDownloadFile`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        addToast({
          text: t(`${dictPrefix}.downloadedFile`),
          color: 'success',
          icon: 'success',
        });
        const blob = new Blob([data], {
          type: 'vnd.ms-excel',
        });
        downloadFromBlob(blob, `estudiantes-${status}.xlsx`);
      }
      setDownloading(false);
    },
    [t, downloading, status],
  );

  const onClear = useCallback(() => {
    reset();
    updateQuery();
  }, [reset, updateQuery]);

  return (
    <Form onSubmit={handleSubmit(handleSearch)}>
      <Row className="pb-4 gutters-filters">
        <Col xs={12} md={6} lg={3}>
          <SearchBox
            name="search"
            label={t(`${dictPrefix}.nameOrId`)}
            placeholder={t(`${dictPrefix}.nameOrIdPlaceholder`)}
            control={control}
            rules={{
              validate: {
                minLength: validateMinLength(3),
              },
            }}
          />
        </Col>

        <Col xs={12} md={6} lg={4}>
          <OutlinedSelect
            name="studyPlanId"
            label={t(`${dictPrefix}.studyPlan`)}
            placeholder={t(`${dictPrefix}.studyPlanPlaceholder`)}
            control={control}
            options={studyPlans}
          />
        </Col>

        <Col xs={12} md={6} lg={3}>
          <OutlinedSelect
            name="campusId"
            label={t(`${dictPrefix}.campus`)}
            placeholder={t(`${dictPrefix}.campusPlaceholder`)}
            control={control}
            options={campuses}
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 3, offset: 6 }}
          lg={{ size: 1, offset: 0 }}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            type="submit"
            text={t(`common.actions.search`)}
            size="md"
            loading={loading || downloading}
            fullwidth
          />
        </Col>

        <Col
          xs={12}
          md={{ size: 3 }}
          lg={1}
          style={{ marginTop: isMobile ? 0 : '1.5rem' }}
        >
          <Button
            text={t(`common.actions.clean`)}
            size="md"
            loading={loading || downloading}
            fullwidth
            outlined
            onClick={onClear}
          />
        </Col>
        <Col xs={12} md={{ size: 6 }} lg={10} />
        <Col xs={12} md={{ size: 6 }} lg={2}>
          <Button
            text={t(`common.actions.download`)}
            size="md"
            icon="download"
            loading={loading || downloading}
            fullwidth
            outlined
            onClick={handleSubmit(handleDownload)}
          />
        </Col>
      </Row>
    </Form>
  );
}
