import { Icon } from '@octano/global-ui';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardLink } from 'reactstrap';
import { PaymentAgreement } from '../types';
import dayjs from 'dayjs';

interface Props {
  data: PaymentAgreement;
}

type StyleType = 'decimal' | 'currency' | 'percent' | 'unit';

const backgroundColor = {
  green: '#F0FCED',
  red: '#FCEEED',
};

function CardItem(props: {
  title: string;
  value: number | string | string[];
  type?: StyleType;
  locale?: string;
  currency?: string;
}) {
  const {
    title,
    value,
    type: style = 'decimal',
    locale = 'es-CL',
    currency = 'CLP',
  } = props;
  return (
    <div className="d-flex justify-content-between">
      <small className="text-muted w-50">{title}</small>
      <p className="m-0">
        {typeof value === 'string' ? value : null}
        {typeof value === 'number'
          ? value.toLocaleString(locale, { style, currency })
          : null}
        {Array.isArray(value) ? (
          <div className="text-right">
            {value.map((item, index) => (
              <p className="m-0" key={index}>
                {item}
              </p>
            ))}
          </div>
        ) : null}
      </p>
    </div>
  );
}

function CardInfo(props: Props) {
  const {
    concept,
    discounts,
    isLatePayment,
    numberOfInstallments,
    firstInstallmentDueDate,
    totalAmount,
    dueAmount,
  } = props.data;

  const history = useHistory();
  return (
    <Card
      className="border border-primary"
      style={{
        backgroundColor: isLatePayment
          ? backgroundColor.red
          : backgroundColor.green,
      }}
    >
      <div className="px-3 pt-3">
        <p className="text-primary fs-14 m-0">Arancel</p>
        <p className="text-primary fs-16 font-weight-bold mb-1">{concept}</p>
      </div>
      <CardBody>
        <CardItem title="Monto total" value={totalAmount} type="currency" />
        <CardItem title="Monto adeudado" value={dueAmount} type="currency" />
        <CardItem title="Número de cuotas" value={numberOfInstallments} />
        <CardItem title="Descuentos" value={discounts} />
        <div className="divider my-2" />
        <CardItem
          title="Fecha de primer vencimiento"
          value={dayjs(firstInstallmentDueDate).format('DD/MM/YYYY')}
        />
      </CardBody>
      <CardFooter
        className="d-flex justify-content-between border-primary"
        onClick={() => {
          history.push('/');
        }}
      >
        <CardLink className="fs-14 mb-0">Ver detalle</CardLink>
        <Icon name="chevron_right" size={14} />
      </CardFooter>
    </Card>
  );
}

export default CardInfo;
