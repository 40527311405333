import request from '../../../api/request';
import { downloadFromBlob } from '../../../utils/blob';
import { DebtRsponse } from '../Debt/types';

const CURRENT_ACCOUNT = {
  DEBTS: (studentId: string | number) =>
    `maintainers-portal/current-accounts/students/${studentId}/debts`,

  DOWNLOAD_VOUCHER: (studentId: string | number) =>
    `maintainers-portal/current-accounts/students/${studentId}/debts/download-voucher`,
};

export function getDebts(studentId: string | number) {
  return request<DebtRsponse>(CURRENT_ACCOUNT.DEBTS(studentId));
}

export async function downloadVoucher({
  studentId,
  debtIds,
  amountToPay,
}: {
  studentId: string | number;
  debtIds: number[];
  amountToPay: number;
}) {
  const res = await request(CURRENT_ACCOUNT.DOWNLOAD_VOUCHER(studentId), {
    method: 'POST',
    data: { debtIds, amountToPay },
    responseType: 'blob',
  });
  const blob = new Blob([res.data], { type: 'application/pdf' });
  downloadFromBlob(blob, 'voucher.pdf');
}
