import { SelectOptionType } from '@octano/global-ui';
import { format } from 'rut.js';

import { StudentFileResponse } from '../../../../../../../types/studentFileSectionTypes';

export default function studenFileFormatPreviusData(
  data: StudentFileResponse,
  countryOptions: SelectOptionType[],
) {
  return {
    ...data.responses,
    rut: data.student.rut ? format(data.student.rut) : undefined,
    passportCountry: countryOptions.find(
      (c) => c.value === data.student.passport?.countryId,
    ),
    passportNumber: data.student.passport?.number,
    namesStudent: data.student.names,
    paternalSurname: data.student.paternalName,
    maternalSurname: data.student.maternalName,
    cv: data.studyPlan.code,
    studyPlan: data.studyPlan.name,
    campus: data.studyPlan.campus,
    schedule: data.studyPlan.schedule,
    entryYear: data?.postulation?.entryYear || undefined,
    officialEmail: data?.student?.account?.email,
    personalEmail: data?.student?.account?.personalEmail,
    birthday: data.student.birthday,
    country: countryOptions.find(
      (c) => c.label.toLocaleLowerCase() === 'chile',
    ),
  };
}
