import React from 'react';
import { ColumnTable } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { Debt, PaymentAgreementType } from '../types';
import { Input } from 'reactstrap';

const dictPrefix = 'studentCurrentAccount.tabs.currentAccount.table';

interface actionsColumns {
  onClickDetail: (row: any) => void;
  selectedDebts: Debt[];
  onClickNotifySelectedDebts: (row: Debt) => void;
}

export default function useColumns(parms: actionsColumns): ColumnTable<Debt>[] {
  const { onClickDetail, selectedDebts, onClickNotifySelectedDebts } = parms;
  const { t } = useTranslation();

  const paymentAgreementType = {
    [PaymentAgreementType.Tariff]: t(`${dictPrefix}.tariff`),
    [PaymentAgreementType.Tuition]: t(`${dictPrefix}.tuition`),
  };

  React.useEffect(() => {
    console.log(selectedDebts);
  }, [selectedDebts]);

  return [
    {
      columnName: 'concept',
      headerText: t(`${dictPrefix}.concept`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'agreementType',
      headerText: t(`${dictPrefix}.agreementType`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        return paymentAgreementType[row.agreementType];
      },
    },
    {
      columnName: 'numberOfInstallment',
      headerText: t(`${dictPrefix}.numberOfInstallment`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'expirationDate',
      headerText: t(`${dictPrefix}.expirationDate`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        return row.expirationDate
          ? new Date(row.expirationDate).toLocaleDateString()
          : '-';
      },
    },
    {
      columnName: 'daysLate',
      headerText: t(`${dictPrefix}.daysLate`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'capitalAmount',
      headerText: t(`${dictPrefix}.capitalAmount`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'interestRate',
      headerText: t(`${dictPrefix}.interestRate`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'totalAmount',
      headerText: t(`${dictPrefix}.totalAmount`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'paidAmount',
      headerText: t(`${dictPrefix}.paidAmount`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'outstandingDebt',
      headerText: t(`${dictPrefix}.outstandingDebt`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'action',
      headerText: t(`${dictPrefix}.action`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: 180,
      cellFormat: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary"
              onClick={() => onClickDetail(row)}
            >
              {t(`${dictPrefix}.viewDetail`)}
            </button>
          </div>
        );
      },
    },
    {
      columnName: '',
      headerText: '',
      cellFormat: (options) => {
        return (
          <div className="form-group">
            <label className="g-checkbox-input-container">
              <Input
                className="g-checkbox-input"
                type="checkbox"
                id=""
                name=""
                checked={selectedDebts.includes(options.row)}
                onChange={() => onClickNotifySelectedDebts(options.row)}
              />
              <span className="checkmark"></span>
            </label>
            <small className="g-input-error form-text text-danger"></small>
          </div>
        );
      },
    },
  ];
}
