import { OutlinedSelectOptionType } from '@octano/global-ui';

import { TUITION_CONTINUITY_PROCESS_MANAGEMENT } from '../endpoints';
import {
  AxiosErrorWithResponse,
  AxiosResult,
  AxiosResultDefaultError,
  RequestOptionsType,
  default as defaltRequest,
  default as requestCore,
} from '../request';
import { Period } from './periods';

export type TuitionContinuityProcessManagementErrors =
  | AxiosResultDefaultError
  | TuitionContinuityProcessManagementListMissingEventError
  | TuitionContinuityProcessManagementListIncomingEventError
  | TuitionContinuityProcessManagementListPeriodExceededError;

export async function request<T>(
  url: string,
  options?: RequestOptionsType,
): Promise<AxiosResult<T, TuitionContinuityProcessManagementErrors>> {
  const response = await requestCore<T>(url, options);
  if (response.error) {
    if (response.error.code === 'HTTP_ERROR' && response.error.status === 409) {
      if (response.error.data?.type === 'MISSING_EVENT') {
        return {
          error: {
            ...response.error,
            range: undefined,
            code: response.error.data.type,
          },
        };
      } else if (
        response.error.data?.type === 'INCOMING_EVENT' ||
        response.error.data?.type === 'PERIOD_EXCEEDED'
      ) {
        return {
          error: {
            ...response.error,
            range: response.error.data.range.range,
            code: response.error.data.type,
          },
        };
      }
    } else {
      return response;
    }
  }
  return response;
}

export type TuitionContinuityProcessManagementListMissingEventError = {
  code: 'MISSING_EVENT';
  error: AxiosErrorWithResponse;
  status: number;
  range: undefined;
};

export type TuitionContinuityProcessManagementListIncomingEventError = {
  code: 'INCOMING_EVENT';
  error: AxiosErrorWithResponse;
  status: number;
  range: [number, number];
};

export type TuitionContinuityProcessManagementListPeriodExceededError = {
  code: 'PERIOD_EXCEEDED';
  error: AxiosErrorWithResponse;
  status: number;
  range: [number, number];
};

export type TuitionContinuityProcessManagementList = {
  period: { id: number; name: string };
  studyPlanVersionOffers: {
    studyPlanVersionOfferId: number;
    studyPlanVersionName: string;
    campusName: string;
    scheduleName: string;
    total: number;
    completed: number;
    started: number;
    notStarted: number;
    schoolId: number;
    schoolName: string;
  }[];
};

export type TuitionContinuityProcessManagementListFormat = {
  schoolOption: OutlinedSelectOptionType[];
  onChangeSchool: (option: OutlinedSelectOptionType) => void;
  period: { id: number; name: string };
  studyPlanVersionOffers: {
    studyPlanVersionOfferId: number;
    studyPlanVersionName: string;
    campusName: string;
    scheduleName: string;
    total: number;
    completed: number;
    started: number;
    notStarted: number;
    schoolId: number;
    schoolName: string;
  }[];
};

export interface GetTuitionContinuityProcessManagemenResponse {
  periods: Period[];
  currentPeriod: Period;
}

export interface GetTuitionContinuityProcessManagemenResponseFilters
  extends GetTuitionContinuityProcessManagemenResponse {
  campuses: { id: number; name: string }[];
}

export function getPeriodOFContinuity() {
  const url = TUITION_CONTINUITY_PROCESS_MANAGEMENT.BASE;
  return defaltRequest<GetTuitionContinuityProcessManagemenResponseFilters>(
    url,
    {
      method: 'get',
    },
  );
}

/**
 * Obtiene el listado de procesos de continuidad por el id de periodo
 */
export async function getTuitionContinuityProcessManagementList(
  periodId: number,
  campusId?: number,
): Promise<
  AxiosResult<
    TuitionContinuityProcessManagementList,
    TuitionContinuityProcessManagementErrors
  >
> {
  const url = TUITION_CONTINUITY_PROCESS_MANAGEMENT.LIST;

  return request<TuitionContinuityProcessManagementList>(url, {
    params: {
      periodId,
      campusId,
    },
  });
}

export type TuitionContinuityProcessManagementDetail = {
  studyPlanVersionName: string;
  campusName: string;
  scheduleName: string;
  discounts: {
    id: number;
    code: string;
    name: string;
    percentage: number;
    description: string | null;
  }[];
};

/**
 * Obtiene el estado de la postulacion por rut
 */
export async function getTuitionContinuityProcessManagementDetail(
  studyPlanVersionOfferId: number | string,
  periodId: number | string,
): Promise<
  AxiosResult<
    TuitionContinuityProcessManagementDetail,
    TuitionContinuityProcessManagementErrors
  >
> {
  const url = TUITION_CONTINUITY_PROCESS_MANAGEMENT.DETAIL(
    studyPlanVersionOfferId,
  );

  return request<TuitionContinuityProcessManagementDetail>(
    `${url}?periodId=${periodId}`,
  );
}

export interface TuitionContinuityProcessDetailStudentResponseDto {
  id: number;
  rut: string;
  names: string;
  lastNames: string;
  cellPhone: string;
  email: string;
  enrollmentDate?: string;
  tuitionContinuityProcessStep?: string;
  discounts: {
    id: number;
    code: string;
    name: string;
    percentage: number;
    type: 'tariff' | 'tuition';
  }[];
}

export interface TuitionContinuityProcessDetailStudentsResponseDto {
  total: number;
  total_pages: number;
  data: TuitionContinuityProcessDetailStudentResponseDto[];
}

/**
 * Obtiene el estado de la postulacion por rut
 */
export async function getTuitionContinuityProcessManagementDetailStudents(
  studyPlanVersionOfferId: number | string,
  periodId: number | string,
  { status }: { status: string },
  { page, search }: { page: number; search?: string },
): Promise<
  AxiosResult<
    TuitionContinuityProcessDetailStudentsResponseDto,
    TuitionContinuityProcessManagementErrors
  >
> {
  const url = TUITION_CONTINUITY_PROCESS_MANAGEMENT.DETAIL_STUDENTS(
    studyPlanVersionOfferId,
  );

  return request<TuitionContinuityProcessDetailStudentsResponseDto>(url, {
    params: {
      status,
      page,
      rutOrPassport: search,
      periodId: periodId,
    },
  });
}

/**
 * Asignar un descuento a un estudiante en un periodo
 */
export async function saveTuitionContinuityProcessManagementDetailDiscount(data: {
  studyPlanEnrollmentId: number;
  discountId: number;
  periodId: number;
  type: 'tuition' | 'tariff';
}): Promise<
  AxiosResult<
    TuitionContinuityProcessDetailStudentsResponseDto,
    TuitionContinuityProcessManagementErrors
  >
> {
  return request<TuitionContinuityProcessDetailStudentsResponseDto>(
    TUITION_CONTINUITY_PROCESS_MANAGEMENT.DISCOUNT,
    {
      method: 'POST',
      data,
    },
  );
}
