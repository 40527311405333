import { ColumnTable, Icon } from '@octano/global-ui';

import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { TranslationsKeys } from '../../../../../../locales/translations';
import {
  TeacherAssignmentHourType,
  TeacherAssignmentRecordHour,
} from '../type';

const dictPrefix = 'recordsTable';

const sumHours = (hours?: TeacherAssignmentRecordHour[], typeId?: string) => {
  if (typeId && hours?.length) {
    hours = hours?.filter((e) => e.typeId === typeId);
  }
  if (!hours?.length) {
    return 0;
  }
  return hours?.map((e) => e.value)?.reduce((a, b) => a + b, 0);
};

export default function useColumns({
  hourTypes = [],
  onClickEdit,
  onClickRemove,
}: {
  hourTypes?: TeacherAssignmentHourType[];
  onClickEdit: (row: any) => void;
  onClickRemove: (row: any) => void;
}): ColumnTable[] {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_HIRING_INFORMATION,
  );

  const columns = useMemo(() => {
    const items: ColumnTable[] = [
      {
        columnName: 'teacherContractType',
        headerText: t(`${dictPrefix}.type`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => value?.name ?? '-',
      },
      {
        columnName: 'startAt',
        headerText: t(`${dictPrefix}.startAt`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => dayjs(value).format('DD/MM/YYYY'),
      },
      {
        columnName: 'modality',
        headerText: t(`${dictPrefix}.modality`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => value?.name ?? '-',
      },
      {
        columnName: 'studyPlan',
        headerText: t(`${dictPrefix}.studyPlan`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => value?.name ?? '-',
      },
      {
        columnName: 'school',
        headerText: t(`${dictPrefix}.school`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => value?.name ?? '-',
      },
      {
        columnName: 'campus',
        headerText: t(`${dictPrefix}.campus`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => value?.name ?? '-',
      },
      {
        columnName: 'deanship',
        headerText: t(`${dictPrefix}.deanship`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => value?.name ?? '-',
      },
      {
        columnName: 'teacherAcademicRank',
        headerText: t(`${dictPrefix}.teacherAcademicRank`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => value?.name ?? '-',
      },
      {
        columnName: 'startPeriod',
        headerText: t(`${dictPrefix}.startPeriod`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ value }) => value?.name ?? '-',
      },
    ];

    hourTypes.forEach((hourType) => {
      items.push({
        columnName: hourType.id,
        headerText: hourType.name,
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 100,
        cellFormat: ({ row }) => sumHours(row?.hours, hourType.id),
      });
    });

    if (hourTypes?.length) {
      items.push({
        columnName: 'hours',
        headerText: t(`${dictPrefix}.hoursTotal`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 100,
        cellFormat: ({ value }) => sumHours(value),
      });
    }

    items.push({
      columnName: 'id',
      headerText: t(`${dictPrefix}.actions`),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <button className="btn" onClick={() => onClickEdit(row)}>
              <Icon color="primary" name="edit" />
            </button>

            <button className="btn" onClick={() => onClickRemove(row)}>
              <Icon name="trash" color="primary" />
            </button>
          </div>
        );
      },
    });

    return items;
  }, [t, onClickEdit, onClickRemove, hourTypes]);

  return columns;
}
