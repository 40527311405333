import { Button, Modal } from '@octano/global-ui';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { TranslationsKeys } from '../../../../locales/translations';
import { UseActions } from '../../hooks/useActions';
import { DiscountManagementListResponse } from '../../types';
import { useTuitionContinuityDiscountManagementFilterLoader } from '../TuitionContinuityDiscountManagementFilterLoader';
import { useTuitionContinuityDiscountManagementLoader } from '../TuitionContinuityDiscountManagementLoader';
import TuitionContinuityDiscountUpdateForm, {
  FormData,
} from './TuitionContinuityDiscountUpdateForm';

interface Props {
  actions: UseActions;
}

export interface TuitionContinuityDiscountUpdateModalRef {
  closeModal: () => void;
  openUpdate: (row: any) => void;
}

const prefix = (key: string) => `actions.${key}`;

const defaultForm = {
  id: 0,
  type: null,
  discountName: '',
  discountCode: '',
  discountPercentage: 0,
};

const TuitionContinuityDiscountUpdateModal = forwardRef<
  TuitionContinuityDiscountUpdateModalRef,
  Props
>(({ actions }, ref) => {
  const { t } = useTranslation(
    TranslationsKeys.TC_DISCOUNT_ALLOCATION_MANAGEMENT,
  );
  const { data } = useTuitionContinuityDiscountManagementFilterLoader();
  const { refresh } = useTuitionContinuityDiscountManagementLoader();

  const forms = useForm<FormData>({ defaultValues: defaultForm });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = forms;
  const [modal, setModal] = useState(false);

  const handleClose = useCallback(() => {
    setModal(false);
    reset(defaultForm);
  }, [reset]);

  const handleOpen = useCallback(
    (row?: DiscountManagementListResponse) => {
      if (row) {
        const discountType = data!.discountTypeOptions.find(
          (x) => (x.id = row.discountType),
        )!;
        reset({
          id: row.discountId,
          discountName: row.discountName,
          discountCode: row.discountCode,
          discountPercentage: row.discountPercentage,
          type: {
            label: discountType.name,
            value: discountType.id,
          },
        });
      } else {
        reset(defaultForm);
      }
      setModal(true);
    },
    [reset, data],
  );

  const handleOpenUpdate = useCallback(
    (row: any) => {
      handleOpen(row);
    },
    [handleOpen],
  );

  useImperativeHandle(ref, () => ({
    openUpdate: handleOpenUpdate,
    closeModal: handleClose,
  }));

  const onSubmit = useCallback(
    async (values: FormData) => {
      await actions.updateRecord({
        ...values,
        type: values.type?.value,
      });
      reset();
      refresh();
    },
    [actions, refresh, reset],
  );

  return (
    <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
      <Container fluid>
        <p className="text-dark fs-20 mt-3 mb-4 text-center">
          {t(prefix('update.title'))}
        </p>
        <p className="fs-16 mt-3 mb-4 mx-5 px-5 text-center">
          {t(prefix('update.description'))}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...forms}>
            <Row>
              <TuitionContinuityDiscountUpdateForm />
              <Col xs={12} md={6}>
                <Button
                  outlined
                  text={t('cancel')}
                  fullwidth
                  onClick={() => setModal(false)}
                  className="mb-3"
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="submit"
                  text={t('confirm')}
                  className="mb-3"
                  fullwidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </FormProvider>
        </form>
      </Container>
    </Modal>
  );
});

export default TuitionContinuityDiscountUpdateModal;
