import { CreateEvaluationFormContextProvider } from '../EvaluationForm/context/CreateEvaluationFormContext';
import { useGradingStructureInfoLoader } from '../Loaders/GradingStructureInfoLoader';

/**
 * Inicializa el contexto de la estructura de calificaciones
 * para finalGrade y testGrades
 */
const InitUpdateStructureTypeForm = ({ children }: any) => {
  const { data: initialValues } = useGradingStructureInfoLoader();
  return (
    <CreateEvaluationFormContextProvider
      gradesConfig={{
        finalGrade:
          initialValues?.currentStructureTypeSchema?.finalEvaluationsConfig ??
          {},
        testGrades:
          initialValues?.currentStructureTypeSchema?.partialEvaluationsConfig ??
          [],
      }}
    >
      {children}
    </CreateEvaluationFormContextProvider>
  );
};

export default InitUpdateStructureTypeForm;
