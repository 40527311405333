import request from '../../api/request';
import { FormDataResponse, FormDataSubmitType } from './types';

export const DISCOUNT_ALLOCATIONS = {
  FORM_DATA: '/maintainers-portal/tuition-continuity/discount-allocation/form',
  GET_BASE_INFO:
    '/maintainers-portal/tuition-continuity/discount-allocation/base',
  GET_TEMPLATE:
    '/maintainers-portal/tuition-continuity/discount-allocation/template',
  UPLOAD_TEMPLATE:
    '/maintainers-portal/tuition-continuity/discount-allocation/upload',
  GET_FILE_UPLOAD_FAILURE: (fileUploadFailureId: string) =>
    `/maintainers-portal/tuition-continuity/discount-allocation/file-upload-failure/${fileUploadFailureId}`,
};

export function getDiscountAllocationsFormData() {
  return request<FormDataResponse>(DISCOUNT_ALLOCATIONS.FORM_DATA);
}

export function getTemplate(formData: FormDataSubmitType) {
  return request<any>(DISCOUNT_ALLOCATIONS.GET_TEMPLATE, {
    data: formData,
    method: 'POST',
    responseType: 'blob',
  });
}

export function getBaseInfo() {
  return request<any>(DISCOUNT_ALLOCATIONS.GET_BASE_INFO, {
    responseType: 'blob',
  });
}

export function uploadTemplate(file: File) {
  const url = DISCOUNT_ALLOCATIONS.UPLOAD_TEMPLATE;
  const data = new FormData();
  data.append('file', file);
  return request<any>(url, { data, method: 'POST' });
}

export function getFileUploadFailure(fileUploadFailureId: string) {
  return request<any>(
    DISCOUNT_ALLOCATIONS.GET_FILE_UPLOAD_FAILURE(fileUploadFailureId),
    { responseType: 'blob' },
  );
}
