import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { StructureTypeCode } from '../../../interfaces/type';

const useStructureTypeIsFixed = () => {
  const { watch } = useFormContext();
  const structureType = watch('structureTypeId');

  const isFixedType = useMemo(() => {
    return !!structureType && structureType?.value === StructureTypeCode.FIXED;
  }, [structureType]);

  return {
    isFixedType,
  };
};

export default useStructureTypeIsFixed;
