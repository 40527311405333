import { ReactElement, useCallback } from 'react';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { getDiscountAllocationsManagementFormData } from '../api';
import { FormDataResponse } from '../types';

export interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  FormDataResponse
>();

export function useTuitionContinuityDiscountManagementFilterLoader() {
  return useFetch();
}

export const BaseLoaderConsumer = FetchConsumer;

export default function TuitionContinuityDiscountManagementFilterLoader({
  children,
}: BaseLoaderProps) {
  const request = useCallback(() => {
    return getDiscountAllocationsManagementFormData();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return (
              <div className="mx-3">
                <Loading insideCard />
              </div>
            );
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children;
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
