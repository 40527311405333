import { SelectOptionType } from '@octano/global-ui';
import {
  CreateCourseBody,
  UpdateCourseBody,
} from '../../../types/courseMaintainerTypes';
import { getSelectedOption } from '../../../utils/selectFormat';
import {
  Course,
  CourseFormData,
  CourseFormFields,
  CourseSyllabus,
  RetakeExamAllowedCode,
  CourseFormMode,
} from './types';

export const syllabusToFile = (
  syllabus?: CourseSyllabus | null,
): File | null => {
  if (!syllabus) {
    return null;
  }
  return new File([] as any, `${syllabus?.name}.${syllabus?.extension}`, {
    lastModified: -2,
  });
};

export const baseToSelectOption = <T extends { id: number; name: string }>(
  items?: T[],
): SelectOptionType[] =>
  items?.map((e) => ({
    label: e.name,
    value: e.id,
  })) || [];

export const courseToFormFields = (
  course: Course,
  {
    schools = [],
    academicLevels = [],
    modalities = [],
    retakeExamOptions = [],
  }: CourseFormData,
): CourseFormFields => {
  return {
    // CourseDetailsSection
    shortening: course.shortening,
    code: course.code,
    name: course.name,
    credits: course.credits.toString(),
    school: getSelectedOption(course.schoolId, schools),
    academicLevel: getSelectedOption(course.academicLevelId, academicLevels),
    modality: getSelectedOption(course.modalityId ?? 0, modalities),
    retakeExamAllowed: getSelectedOption(
      course.retakeExamAllowed
        ? RetakeExamAllowedCode.Enabled
        : RetakeExamAllowedCode.Disabled,
      retakeExamOptions,
    ),
    // CourseTypeSection
    courseTypes: course?.types?.map((e) => e.id) ?? [],
    // CourseActivityTypeSection,
    attendance:
      course?.assistanceRequirements?.map((e) => ({
        minPercentage: e.minPercentage,
        activityTypeId: e.activityTypeId,
      })) ?? [],
    // CourseSyllabusSection
    syllabusFile: syllabusToFile(course?.syllabus),
    // CourseCompatibilitiesSection
    compatibilities:
      course?.compatibilities?.map((e) => ({
        id: e.id,
        shortening: e.shortening,
        name: e.name,
        credits: e.credits,
      })) ?? [],
    // CoursePrerequisitesSection
    prerequisites:
      course?.dependsOn?.map((e) => ({
        id: e.id,
        shortening: e.shortening,
        name: e.name,
        credits: e.credits,
      })) ?? [],
  };
};

export const submitRequiresConfirmation = (
  values: CourseFormFields,
  mode: CourseFormMode,
): boolean => {
  return (
    !values.prerequisites ||
    (values.prerequisites.length === 0 && mode === CourseFormMode.CREATE)
  );
};

export const creationPayload = (
  values: CourseFormFields,
): CreateCourseBody => ({
  name: values.name,
  code: values.code,
  shortening: values.shortening,
  credits: +values.credits,
  types: values?.courseTypes ?? [],
  schoolId: values.school?.value ? +values.school?.value : 0,
  academicLevelId: values.academicLevel?.value
    ? +values.academicLevel?.value
    : 0,
  assistanceRequirements: values?.attendance ?? [],
  dependsOn:
    values?.prerequisites?.map((prerequisite) => prerequisite.id) ?? [],
  compatibilityIds:
    values?.compatibilities?.map((compatibility) => compatibility.id) ?? [],
  retakeExamAllowed:
    values.retakeExamAllowed?.value === RetakeExamAllowedCode.Enabled,
});

export const updatingPayload = (
  courseId: number,
  values: CourseFormFields,
  isDisabled: boolean,
): UpdateCourseBody => {
  const payload: UpdateCourseBody = {
    id: courseId,
    types: values.courseTypes ?? [],
    assistanceRequirements: values?.attendance ?? [],
    dependsOn:
      values?.prerequisites?.map((prerequisite) => prerequisite.id) ?? [],
    compatibilityIds:
      values?.compatibilities?.map((compatibility) => compatibility.id) ?? [],
    modalityId: values.modality?.value ? Number(values.modality?.value) : null,
  };
  if (!isDisabled) {
    return {
      ...payload,
      name: values.name,
      code: values.code,
      shortening: values.shortening,
      credits: +values.credits,
      schoolId: values.school?.value ? +values.school?.value : 0,
      academicLevelId: values.academicLevel?.value
        ? +values.academicLevel?.value
        : 0,
      retakeExamAllowed:
        values.retakeExamAllowed?.value === RetakeExamAllowedCode.Enabled,
    };
  }
  return payload;
};
