import { CellFormatOptions, Icon } from '@octano/global-ui';
import { useEffect, useRef, useState } from 'react';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import {
  DiscountTexts,
  Postulant,
} from '../../../api/requests/studyPlansOffer';

const DiscountField = ({
  options,
}: {
  options: CellFormatOptions<Postulant>;
}) => {
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const discounts =
    options.row.postulations?.[0]?.details?.[0]?.postulationDetailDiscount;

  useEffect(() => {
    if (spanRef.current) {
      setPopoverOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spanRef.current]);

  if (!discounts || discounts.length === 0) {
    return null; // O manejar el caso en que no hay descuentos
  }

  const uniqueId = `key_${options.row.id}`;

  return (
    <>
      <span ref={spanRef} id={uniqueId}>
        <Icon name="eye" size={16} />
      </span>
      {popoverOpen && (
        <UncontrolledPopover placement="left" target={uniqueId}>
          <PopoverHeader>Descuentos asignados</PopoverHeader>
          <PopoverBody style={{ maxHeight: '300px', overflow: 'auto' }}>
            <ul>
              {discounts.map((discount) => (
                <li key={discount.id}>
                  {DiscountTexts[discount.type]} - {discount.discountName} -{' '}
                  {discount.discountValue}% - {discount.discountCode}
                </li>
              ))}
            </ul>
          </PopoverBody>
        </UncontrolledPopover>
      )}
    </>
  );
};

export default DiscountField;
