import { useParams, useHistory } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';

import { AxiosResultDefaultError } from '../../../api/request';

import CourseProvider from './providers/CourseProvider';
import CourseForm from './parts/CourseForm';
import { PathsLayouts } from '../../../config/routes';
import { CourseFormFields } from './types';
import {
  removeCourseSyllabus,
  requestCreateCourse,
  requestUpdateCourse,
  updateCourseSyllabus,
} from './api';
import { creationPayload, updatingPayload } from './utils';

const prefix = 'courses.update';

const CourseUpdate = () => {
  const { t } = useTranslation();
  const { id: courseId } = useParams<{ id: string }>();
  const history = useHistory();

  const [processing, setProcessing] = useState<boolean>(false);

  const handleBack = useCallback(() => {
    history.push(`${PathsLayouts.academicOffers}/curricular-subjects`);
  }, [history]);

  const responseCreateOrUpdate = useCallback(
    (error: false | AxiosResultDefaultError | undefined) => {
      if (error) {
        if (
          error?.data?.message?.some((err: string) => err === 'SHORT_IN_USE')
        ) {
          addToast({
            icon: 'warning',
            color: 'warning',
            text: t(`${prefix}.validateShortening`),
          });
        } else if (
          error?.data?.message?.some((err: string) => err === 'COD_IN_USE')
        ) {
          addToast({
            icon: 'warning',
            color: 'warning',
            text: t(`${prefix}.validateCode`),
          });
        } else {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t('common.errors.save'),
          });
        }
      } else {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(
            `common.messages.${!courseId ? 'saveSuccess' : 'editSuccess'}`,
          ),
        });
        handleBack();
      }
    },
    [handleBack, t, courseId],
  );

  const onUpdateSyllabus = useCallback(
    async (id: string, values: CourseFormFields) => {
      if (values?.syllabusFile) {
        if (values?.syllabusFile?.lastModified !== -2) {
          await updateCourseSyllabus({
            id: +id,
            file: values?.syllabusFile,
          });
        }
      } else {
        await removeCourseSyllabus(+id);
      }
    },
    [],
  );

  const handleSubmit = useCallback(
    async ({
      values,
      isDisabledToUpdate,
    }: {
      values: CourseFormFields;
      isDisabledToUpdate: boolean;
    }) => {
      if (processing) {
        return;
      }
      setProcessing(true);
      const [{ error, data }] = courseId
        ? await Promise.all([
            requestUpdateCourse(
              updatingPayload(+courseId, values, isDisabledToUpdate),
            ),
            onUpdateSyllabus(courseId, values),
          ])
        : await Promise.all([requestCreateCourse(creationPayload(values))]);

      if (!error && data?.id && !courseId) {
        await onUpdateSyllabus(`${data?.id}`, values);
      }

      setProcessing(false);
      responseCreateOrUpdate(error);
    },
    [responseCreateOrUpdate, onUpdateSyllabus, courseId, processing],
  );

  return (
    <CourseProvider courseId={courseId}>
      <CourseForm
        processing={processing}
        onBack={handleBack}
        onSubmit={handleSubmit}
      />
    </CourseProvider>
  );
};

export default CourseUpdate;
