import { useEffect, useContext, useCallback } from 'react';
import { Row, Col, Form } from 'reactstrap';
import { addToast, showDialogConfirm } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { CourseFormFields } from '../types';
import { courseToFormFields, submitRequiresConfirmation } from '../utils';
import { CourseFormContext } from '../providers/CourseProvider';

import CourseHeaderSection from './CourseHeaderSection';
import CourseDetailsSection from './CourseDetailsSection';
import CourseTypeSection from './CourseTypeSection';
import CourseActivityTypeSection from './CourseActivityTypeSection';
import CourseAttendanceSection from './CourseAttendanceSection';
import CourseSyllabusSection from './CourseSyllabusSection';
import CourseCompatibilitiesSection from './CourseCompatibilitiesSection';
import CoursePrerequisitesSection from './CoursePrerequisitesSection';
import CourseFooterSection from './CourseFooterSection';

const prefix = 'courses.update';

type CourseFormProps = {
  processing?: boolean;
  onSubmit: (p: {
    values: CourseFormFields;
    isDisabledToUpdate: boolean;
  }) => void;
  onBack?: () => void;
};

const CourseForm = ({ processing, onBack, onSubmit }: CourseFormProps) => {
  const { t } = useTranslation();
  const { mode, course, formData, isDisabledToUpdate } =
    useContext(CourseFormContext);

  const methods = useForm<CourseFormFields>();
  const { reset, handleSubmit } = methods;

  const handleSave = useCallback(
    async (values: CourseFormFields) => {
      if (!values?.courseTypes?.length) {
        addToast({
          icon: 'warning',
          color: 'warning',
          text: t(`${prefix}.validateCourseType`),
        });
        return;
      }
      if (!values?.attendance?.length) {
        addToast({
          icon: 'warning',
          color: 'warning',
          text: t(`${prefix}.validateAttendance`),
        });
        return;
      }

      if (submitRequiresConfirmation(values, mode)) {
        return showDialogConfirm({
          icon: {
            name: 'warning',
            color: 'warning',
          },
          title: t(`${prefix}.confirmModalTitle`),
          subtitle: t(`${prefix}.confirmModalBody`),
          btnConfirm: {
            text: t(`common.actions.confirm`),
            onConfirm: () => {
              onSubmit({
                values,
                isDisabledToUpdate,
              });
            },
          },
          btnClose: {
            text: t(`common.actions.cancel`),
          },
        });
      } else {
        onSubmit({
          values,
          isDisabledToUpdate,
        });
      }
    },
    [t, onSubmit, isDisabledToUpdate, mode],
  );

  useEffect(() => {
    if (course && formData) {
      reset(courseToFormFields(course, formData));
    }
  }, [course, formData, reset]);

  return (
    <Form onSubmit={handleSubmit(handleSave)}>
      <FormProvider {...methods}>
        <CourseHeaderSection onBack={onBack} />
        <CourseDetailsSection />
        <CourseTypeSection />
        <CourseActivityTypeSection />
        <Row className="pt-4">
          <Col xs={12} md={4}>
            <CourseAttendanceSection />
          </Col>
          <Col xs={12} md={8}>
            <CoursePrerequisitesSection />
          </Col>
          <Col xs={12} md={4}>
            <CourseSyllabusSection />
          </Col>
          <Col xs={12} md={8}>
            <CourseCompatibilitiesSection />
          </Col>
        </Row>
        <CourseFooterSection processing={processing} />
      </FormProvider>
    </Form>
  );
};

export default CourseForm;
