import {
  useCallback,
  useImperativeHandle,
  forwardRef,
  Ref,
  useRef,
} from 'react';

import { addToast } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { clean } from 'rut.js';

import { TeacherFileItem } from '../../TeacherFile/TeacherList/interfaces/teacher-file-item.interface';
import { sendTeacherAccountActivationLink } from '../../../../api/requests/teacherActivation';
import ConfirmEmailActionModal, {
  ConfirmEmailActionModalMethods,
} from './ConfirmEmailActionModal';
import ConfirmExistingAccountModal, {
  ConfirmExistingAccountModalMethods,
} from './ConfirmExistingAccountModal';
import { TranslationsKeys } from '../../../../locales/translations';

export interface ConfirmSendTeacherInvitationMethods {
  open: (teacherFileItem: TeacherFileItem) => void;
}

interface Props {
  onConfirm?: (teacherFileItem: TeacherFileItem) => void;
}

const prefix = 'teacherForm';

const ConfirmSendTeacherInvitation = (
  { onConfirm }: Props,
  ref: Ref<ConfirmSendTeacherInvitationMethods>,
) => {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_ACTIVATION,
  );
  const confirmEmail = useRef<ConfirmEmailActionModalMethods>(null);
  const confirmExistingAccount =
    useRef<ConfirmExistingAccountModalMethods>(null);

  const handleConfirm = useCallback(
    async (
      teacherFile: TeacherFileItem,
      newEmail: string,
      force: boolean = false,
    ) => {
      if (!teacherFile) {
        return;
      }

      const { error } = await sendTeacherAccountActivationLink({
        ...teacherFile.account,
        rut: clean(teacherFile.identification),
        email: newEmail,
        ignoreWarnings: force,
      });

      if (error) {
        if (
          typeof error.data.message === 'object' &&
          error.data.message.length &&
          !force
        ) {
          confirmExistingAccount?.current?.open({
            onConfirm: () => {
              confirmEmail?.current?.open({
                email: newEmail,
                loading: true,
                onConfirm: async (newEmail: string) =>
                  handleConfirm(teacherFile, newEmail, true),
              });
            },
          });
        } else if (error.data.message === 'Email Duplicate') {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`${prefix}.duplicatedEmail`),
          });
        } else {
          addToast({
            icon: 'error',
            color: 'danger',
            text: t(`${prefix}.errorSendingInvitation`),
          });
        }
        return;
      }

      if (force) {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.grantedPermissions`),
        });
      } else {
        addToast({
          icon: 'success',
          color: 'success',
          text: t(`${prefix}.invitationSent`),
        });
      }

      !!onConfirm && onConfirm(teacherFile);
    },
    [t, onConfirm],
  );

  const handleOpen = useCallback(
    (teacherFileItem: TeacherFileItem) => {
      confirmEmail?.current?.open({
        email: teacherFileItem.email,
        onConfirm: async (newEmail: string) =>
          handleConfirm(teacherFileItem, newEmail),
      });
    },
    [handleConfirm],
  );

  useImperativeHandle(ref, () => ({
    open: handleOpen,
  }));

  return (
    <>
      <ConfirmEmailActionModal ref={confirmEmail} />
      <ConfirmExistingAccountModal ref={confirmExistingAccount} />
    </>
  );
};

export default forwardRef(ConfirmSendTeacherInvitation);
