import { useContext, useState, useCallback } from 'react';
import { Button, Table, showDialogConfirm } from '@octano/global-ui';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext, useFieldArray } from 'react-hook-form';

import { CourseFormContext } from '../providers/CourseProvider';
import { useColumnsPrerequisites } from './useColumnsPrerequisites';
import { SectionTitle } from '../../../../components/text';
import { CourseFormFields, CourseFormMode } from '../types';
import TableEmptyContent from '../../../../components/text/TableEmptyContent';
import ModalAddPrerequisite from './ModalAddPrerequisite';

const prefix = 'courses.update';

const CoursePrerequisitesSection = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<CourseFormFields>();

  const { mode } = useContext(CourseFormContext);

  const { fields: prerequisites = [], remove: removePrerequisite } =
    useFieldArray({
      control,
      name: 'prerequisites',
    });

  const [modalPrerequisitesOpen, setModalPrerequisitesOpen] =
    useState<boolean>(false);

  const onDeletePrerequisite = useCallback(
    (index: number) => {
      showDialogConfirm({
        icon: {
          name: 'warning',
          color: 'warning',
        },
        title: t(`${prefix}.removePrerequisiteTitle`),
        subtitle: '',
        btnConfirm: {
          text: t(`common:actions.confirm`),
          onConfirm: () => removePrerequisite(index),
        },
        btnClose: {
          text: t(`common:actions.cancel`),
        },
      });
    },
    [removePrerequisite, t],
  );

  const { columns: columnsPrerequisites } = useColumnsPrerequisites({
    mode,
    onDeletePrerequisite,
  });

  return (
    <>
      <Row>
        <Col
          xs={12}
          className="d-flex justify-content-between align-items-center p-4"
        >
          <SectionTitle text={t(`${prefix}.prerequisites`)} />
          {mode !== CourseFormMode.WATCH && (
            <Button
              icon="plus"
              onClick={() => setModalPrerequisitesOpen(true)}
              size="md"
              text={t(`${prefix}.addPrerequisite`)}
            />
          )}
        </Col>
        <Col xs={12}>
          <Table
            columns={columnsPrerequisites}
            data={prerequisites}
            noResultsText={
              <TableEmptyContent
                title={t(`${prefix}.titleEmptyPrerequisites`)}
                subtitle={t(
                  `${prefix}.${
                    mode === CourseFormMode.WATCH
                      ? 'watchBodyEmptyPrerequisites'
                      : 'bodyEmptyPrerequisites'
                  }`,
                )}
              />
            }
          />
        </Col>
      </Row>

      <ModalAddPrerequisite
        isOpen={modalPrerequisitesOpen}
        toggle={() => setModalPrerequisitesOpen(false)}
      />
    </>
  );
};

export default CoursePrerequisitesSection;
