import { addToast, Table } from '@octano/global-ui';
import { useRef } from 'react';
import useActions from '../hooks/useActions';
import useColumns from '../hooks/useColumns';
import TuitionContinuityDiscountUpdateModal, {
  TuitionContinuityDiscountUpdateModalRef,
} from './modals/TuitionContinuityDiscountUpdateModal';
import { DiscountManagementListResponse } from '../types';
import { useTuitionContinuityDiscountManagementLoader } from './TuitionContinuityDiscountManagementLoader';
import TuitionContinuityDiscountDeleteModal, {
  TuitionContinuityDiscountDeleteModalRef,
} from './modals/TuitionContinuityDiscountDeleteModal';

function DiscountManagementTable() {
  const {
    data,
    refresh,
    updateQuery,
    query,
  } = useTuitionContinuityDiscountManagementLoader();

  const updateModalRef = useRef<TuitionContinuityDiscountUpdateModalRef>(null);
  const deleteModalRef = useRef<TuitionContinuityDiscountDeleteModalRef>(null);
  const onClickUpdate = (row: DiscountManagementListResponse) => {
    updateModalRef.current?.openUpdate(row);
  };

  const onClickDelete = (tuitionContinuityDiscountId: number) => {
    deleteModalRef.current?.openModal(tuitionContinuityDiscountId);
  };

  //Acciones y respuesta(eliminar-actualizar)
  const actions = useActions({
    onSuccess: () => {
      addToast({
        icon: 'success',
        color: 'success',
        text: '',
      });
    },
    onError: (_, messsage) => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: messsage ?? 'Ha ocurrido un error',
      });
    },
  });

  const columns = useColumns({ onClickUpdate, onClickDelete });

  return (
    <>
      <Table
        data={data?.data ?? []}
        columns={columns}
        pagination={{
          totalItems: data?.total ?? 0,
          onChangePage: (page) => updateQuery({ ...query, page }),
          itemsPerPage: 10,
          totalPages: data?.total_pages ?? 0,
          currentPage: query.page,
        }}
      />
      <TuitionContinuityDiscountUpdateModal
        ref={updateModalRef}
        actions={actions}
      />
      <TuitionContinuityDiscountDeleteModal
        ref={deleteModalRef}
        onConfirm={(id) => {
          actions.deleteRecord(id);
          refresh();
        }}
        onDecline={() => {}}
      />
    </>
  );
}

export default DiscountManagementTable;
