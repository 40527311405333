import { Select, SelectOptionType, TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import { useValidations } from '../../../../hooks/useValidations';
import { TranslationsKeys } from '../../../../locales/translations';
import { useTuitionContinuityDiscountManagementFilterLoader } from '../TuitionContinuityDiscountManagementFilterLoader';

export interface FormData {
  id: number;
  type: SelectOptionType | null;
  discountName: string;
  discountCode: string;
  discountPercentage: number;
}

const prefix = (key: string) => `actions.update.${key}`;

const TuitionContinuityDiscountUpdateForm = () => {
  const { t } = useTranslation(
    TranslationsKeys.TC_DISCOUNT_ALLOCATION_MANAGEMENT,
  );
  const { data } = useTuitionContinuityDiscountManagementFilterLoader();

  const forms = useFormContext<FormData>();
  const { control } = forms;
  const { msgValidations } = useValidations();

  return (
    <>
      <div className="d-none">
        <TextInput
          name="id"
          label=""
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </div>
      <Col xs={12}>
        <Select
          options={data!.discountTypeOptions.map((item) => ({
            value: item.id,
            label: item.name,
          }))}
          label=""
          name="type"
          control={control}
        />
      </Col>
      <Col xs={12} md={6} className="mt-4">
        <TextInput
          name="discountCode"
          label={t(prefix('code'))}
          placeholder={t(prefix('code'))}
          control={control}
          rules={{ required: msgValidations.required }}
        />
      </Col>
      <Col xs={12} md={6} className="mt-4">
        <TextInput
          name="discountPercentage"
          label={t(prefix('percentaje'))}
          placeholder={t(prefix('percentaje'))}
          control={control}
          disabled
        />
      </Col>

      <Col xs={12} className="my-4">
        <TextInput
          name="discountName"
          label={t(prefix('name'))}
          placeholder={t(prefix('name'))}
          control={control}
          disabled
        />
      </Col>
    </>
  );
};

export default TuitionContinuityDiscountUpdateForm;
