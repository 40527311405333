import { ReactElement, useCallback } from 'react';

import { AxiosResult, AxiosResultDefaultError } from '../../../api/request';
import { studentsPetitionsListFilter } from '../../../api/requests/studentsDegree';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { StudentsDegreeFilterResponse } from '../../../types/studentsDegree';

export interface StudentsDegreeFormLoaderProps {
  children: ReactElement;
}

export type StudentsDegreeFormLoaderResponse = StudentsDegreeFilterResponse;

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  StudentsDegreeFormLoaderResponse,
  AxiosResultDefaultError
>();

export function useStudentsDegreeFormLoader() {
  const context = useFetch();

  if (!context.data) {
    throw new Error('data no cargada en useStudentsDegreeFormLoader');
  }

  return { data: context.data };
}
export const StudentsDegreeFormLoaderConsumer = FetchConsumer;

export default function StudentsDegreeFormLoader({
  children,
}: StudentsDegreeFormLoaderProps) {
  const request = useCallback(async (): Promise<
    AxiosResult<StudentsDegreeFormLoaderResponse, AxiosResultDefaultError>
  > => {
    return studentsPetitionsListFilter();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return (
              <div className="mx-3">
                <Loading insideCard />
              </div>
            );
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }
          return children;
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
