import { useMemo } from 'react';

interface BadgeStateProps {
  status?: 'loaded' | 'unloaded';
  text: string;
}
export default function BadgeState({
  status = 'loaded',
  text,
}: BadgeStateProps) {
  const colors = useMemo(() => {
    return {
      unloaded: '#F2C0BD',
      loaded: '#CEF0DA',
    };
  }, []);

  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ gap: 3 }}
      >
        <div
          className="border border-primary"
          style={{ width: 20, height: 20, backgroundColor: colors[status] }}
        />
        <span>{text}</span>
      </div>
    </>
  );
}
