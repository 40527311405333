import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../../../locales/translations';

interface Texts {
  title: string;
  subtitle: string;
  modal: {
    title: string;
    delete: string;
  };
  table: {
    workExperience: {
      columns: {
        title: string;
        position: string;
        areaSpecialty: string;
        institution: string;
        startDate: string;
        endDate: string;
        linkedFile: string;
        actions: string;
      };
      add: string;
    };
  };
  form: {
    position: string;
    areaSpecialty: string;
    institution: string;
    startDate: string;
    endDate: string;
    isCurrent: string;
    linkedFile: string;
  };
  actions: {
    confirm: string;
    save: string;
    cancel: string;
    download: string;
    upload: string;
  };
}

interface TextsProviderProps {
  children: ReactNode;
}

const TextsContext = createContext<Texts | undefined>(undefined);

export const TextsProvider = ({ children }: TextsProviderProps) => {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_EMPLOYMENT_BACKGROUND,
  );

  const texts = useMemo(
    () => ({
      title: t(`title`),
      subtitle: t(`subtitle`),
      modal: {
        title: t(`modal.title`),
        delete: t(`modal.delete`),
      },
      table: {
        workExperience: {
          columns: {
            ...t<string, Texts['table']['workExperience']['columns']>(
              `table.workExperience.columns`,
              {
                returnObjects: true,
              },
            ),
          },
          add: t(`table.workExperience.add`),
        },
      },
      form: {
        ...t<string, Texts['form']>(`form`, {
          returnObjects: true,
        }),
      },
      actions: {
        ...t<string, Texts['actions']>(`actions`, {
          returnObjects: true,
        }),
      },
    }),
    [t],
  );

  return (
    <TextsContext.Provider value={texts}>{children}</TextsContext.Provider>
  );
};

export const useTexts = () => {
  const context = useContext(TextsContext);
  if (!context) {
    throw new Error('useTexts must be used within a TextsProvider');
  }
  return context;
};
