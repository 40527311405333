import {
  Button,
  ColumnTable,
  Modal,
  Table,
  TextInput,
  TextOutlinedInput,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { useStudentCurrentAccountLoader } from '../../components/StudentCurrentAccountLoader';
import { downloadVoucher } from '../api';
import { Debt } from '../types';

//Modal interfaces
interface Props {
  onConfirm: (data: any) => void;
  onDecline: () => void;
}
export interface DebVoucherModalRef {
  openModal: (row: Debt[]) => void;
  closeModal: () => void;
}
interface DebtForm extends Debt {
  amountToPay: number;
}
interface FormFields {
  rows: DebtForm[];
  totalAmountToPay: number;
  amountToPay: number;
}

//Modal constants
const dictPrefix = 'studentCurrentAccount.tabs.currentAccount.voucher';

//Modal functions
const formatter = (value: number) =>
  `$${value.toLocaleString('es-CL', {
    style: 'decimal',
    currency: 'CLP',
  })}`;

const DebVoucherModal = forwardRef<DebVoucherModalRef, Props>(
  ({ onConfirm, onDecline }, ref) => {
    const [data, setData] = useState<Debt[]>([]);
    const [modal, setModal] = useState(false);
    const {
      data: { studentId },
    } = useStudentCurrentAccountLoader();

    const {
      control,
      handleSubmit,
      reset,
      watch,
      setValue,
      formState: { isSubmitting },
    } = useForm<FormFields>({ mode: 'all' });
    const { t } = useTranslation();
    const totalAmountToPay = watch('totalAmountToPay');
    const amountToPay = watch('amountToPay');

    useEffect(() => {
      reset({
        amountToPay: 0,
        totalAmountToPay: data.reduce((a, b) => a + b.capitalAmount, 0) ?? 0,
        rows: data
          ?.sort((a, b) =>
            dayjs(a.expirationDate).isAfter(dayjs(b.expirationDate)) ? 1 : -1,
          )
          .map((d) => {
            return { ...d, amountToPay: 0 };
          }),
      });
    }, [data, reset]);

    useEffect(() => {
      let calc = amountToPay;
      data?.forEach((row, number) => {
        if (calc >= row.outstandingDebt) {
          setValue(`rows.${number}.amountToPay`, row.outstandingDebt);
          calc -= row.outstandingDebt;
        } else if (calc < row.outstandingDebt && calc > 0) {
          setValue(`rows.${number}.amountToPay`, calc);
          calc = 0;
        } else {
          setValue(`rows.${number}.amountToPay`, 0);
        }
      });
    }, [amountToPay, data, setValue]);

    const onSubmit = async (values: FormFields) => {
      const { amountToPay, rows } = values;
      await downloadVoucher({
        studentId,
        debtIds: rows.filter((d) => d.amountToPay > 0).map((d) => d.id),
        amountToPay: amountToPay,
      });
      onConfirm(data!);
      reset();
      setModal(false);
    };

    useImperativeHandle(ref, () => ({
      openModal(row: Debt[]) {
        setData(row);
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    const columns: ColumnTable<Debt>[] = [
      {
        columnName: 'concept',
        headerText: t(`${dictPrefix}.concept`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        columnName: 'numberOfInstallment',
        headerText: t(`${dictPrefix}.numberOfInstallment`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        columnName: 'expirationDate',
        headerText: t(`${dictPrefix}.expirationDate`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        cellFormat: ({ row }) => {
          return (
            <p className={row.daysLate > 0 ? 'text-danger' : 'text-primary'}>
              {row.expirationDate
                ? new Date(row.expirationDate).toLocaleDateString()
                : '-'}
            </p>
          );
        },
      },
      {
        columnName: 'outstandingDebt',
        headerText: t(`${dictPrefix}.outstandingDebt`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
      },
      {
        columnName: 'action',
        headerText: t(`${dictPrefix}.payOffDebt`),
        tdClassName: 'text-center',
        thClassName: 'text-center',
        width: 120,
        cellFormat: ({ index: number }) => {
          return (
            <TextOutlinedInput
              control={control}
              label=""
              formatter={formatter}
              name={`rows.${number}.amountToPay`}
              readOnly
            />
          );
        },
      },
    ];

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid className="text-center">
          <p className="text-dark fs-22 mb-3">{t(`${dictPrefix}.title`)}</p>
          <p className="fs-18">{t(`${dictPrefix}.description`)}</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} md={6} className="text-left">
                <TextInput
                  control={control}
                  label={t(`${dictPrefix}.totalAmountToPay`)}
                  name="totalAmountToPay"
                  formatter={formatter}
                  readOnly
                />
              </Col>
              <Col xs={12} md={6} className="text-left">
                <TextInput
                  control={control}
                  label={t(`${dictPrefix}.amountToPay`)}
                  name="amountToPay"
                  rules={{
                    validate: {
                      validateAmount: (value) => {
                        if (Number(value) > Number(totalAmountToPay)) {
                          return String(t(`${dictPrefix}.amountToPayError`));
                        }
                        return undefined;
                      },
                    },
                  }}
                />
              </Col>
            </Row>
            <Table data={data} columns={columns} />
            <div
              className="d-flex justify-content-around mt-5 pt-5"
              style={{ gap: 10 }}
            >
              <div className="flex-fill">
                <Button
                  className="w-100"
                  outlined
                  text={t(`${dictPrefix}.cancel`)}
                  onClick={() => {
                    onDecline();
                    setModal(false);
                  }}
                />
              </div>
              <div className="flex-fill">
                <Button
                  className="w-100"
                  type="submit"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  text={t(`${dictPrefix}.generate`)}
                />
              </div>
            </div>
          </form>
        </Container>
      </Modal>
    );
  },
);

export default DebVoucherModal;
