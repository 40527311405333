import { useCallback } from 'react';
import { updateDiscount, deleteDiscount } from '../api';

export interface UseActions {
  updateRecord: (data: any) => Promise<void>;
  deleteRecord: (recordId: number) => Promise<void>;
}
type ActionType = 'update' | 'delete';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}): UseActions {
  const updateRecordAction = useCallback(
    async (params: any) => {
      const result = await updateDiscount(params);
      if (!result.error) {
        return props.onSuccess('update');
      }
      props.onError('update');
    },
    [props],
  );

  const deleteRecordAction = useCallback(
    async (tuitionContinuityDiscountId: number) => {
      const result = await deleteDiscount(tuitionContinuityDiscountId);
      if (!result.error) {
        return props.onSuccess('delete');
      }
      props.onError('delete');
    },
    [props],
  );

  return {
    updateRecord: updateRecordAction,
    deleteRecord: deleteRecordAction,
  };
}
