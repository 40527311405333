import {
  InterestsCalculatorVariant,
  TariffPaymentMethodSettingValue,
} from '../../../../types/tariffTypes';
import { formatToOnlyPositiveScaledIntegers } from '../../../../utils/currency';
import { FinantialConfig } from '../interfaces/finantial-config.interface';

export const DEFAULT_VALUES: FinantialConfig = {
  hasInterests: true,
  hasTariffDownPayment: true,
  hasTuition: false,
  chargeOnlineTuition: false,
  forceFirstInstallmentOnTuitionFree: false,
  hasPaymentMethod: false,
  interestRate: formatToOnlyPositiveScaledIntegers(`${1.5 * 100}`),
  maxTariffDownPaymentRate: formatToOnlyPositiveScaledIntegers(`${0 * 100}`),
  maxInstallments: 1,
  noInterestsInstallments: 1,
  interestCalculatorVariant: InterestsCalculatorVariant.ProrateInterest,
  payday: 5,
};

export const parseDefaultValuesFromFetch = (
  data: TariffPaymentMethodSettingValue,
): FinantialConfig => ({
  ...data,
  hasTariffDownPayment: !!data?.maxTariffDownPaymentRate,
  hasInterests: !!data?.interestRate,
  interestRate: formatToOnlyPositiveScaledIntegers(
    `${Number(data?.interestRate ?? 0) * 10000 ?? '0,00'}`,
  ),
  maxTariffDownPaymentRate: formatToOnlyPositiveScaledIntegers(
    `${Number(data?.maxTariffDownPaymentRate ?? 0) * 10000 ?? '0,00'}`,
  ),
  payday: data?.payday !== undefined ? data?.payday : DEFAULT_VALUES.payday,
});

export const parseDefaultValuesToFetch = (
  data: FinantialConfig,
): TariffPaymentMethodSettingValue => ({
  ...data,
  interestRate: data?.hasInterests
    ? Number(data?.interestRate.replace(/\D/g, '').trim() ?? 0) / 10000
    : 0,
  maxTariffDownPaymentRate: data?.hasTariffDownPayment
    ? Number(data?.maxTariffDownPaymentRate.replace(/\D/g, '').trim() ?? 0) /
      10000
    : 0,
  noInterestsInstallments: data?.hasInterests
    ? data?.noInterestsInstallments
    : 0,
});
