import { Table } from '@octano/global-ui';
import useColumns from '../hooks/useColumns';
import { useDebtLoader } from './DebtLoader';
import { useState, useRef } from 'react';
import { Debt } from '../types';
import { Button } from '@octano/global-ui';
import { Container, Row, Col } from 'reactstrap';
import DebVoucherModal, { DebVoucherModalRef } from './DebtVoucherModal';

function DebtTable() {
  //deudas seleccionadas en la tabla
  const [selectedDebts, setSelectedDebts] = useState<Debt[]>([]);

  //data de la request
  const { data, loading } = useDebtLoader();

  //ref para el modal del voucher
  const debtVoucherModalRef = useRef<DebVoucherModalRef>(null);

  const onClickDetail = (row: any) => {
    console.log(row);
  };

  //Agrega o quita deudas del listado de seleccionados(en respuesta a los check)
  const onClickNotifySelectedDebts = (row: Debt) => {
    setSelectedDebts((prevSelectedDebts) => {
      const isDebtSelected = prevSelectedDebts.includes(row);
      if (isDebtSelected) {
        return prevSelectedDebts.filter((debt) => debt.id !== row.id);
      } else {
        return [...prevSelectedDebts, row];
      }
    });
  };

  /**
   * useColumns notifica las deudas seleccionadas o deseleccionadas y nosotros
   * Le enviamos el array completo de deudas seleccionadas para que muestre los
   * check seleccionados en el componente input
   * */
  const columns = useColumns({
    onClickDetail,
    selectedDebts,
    onClickNotifySelectedDebts,
  });

  return (
    <>
      <div className="divider my-4" />
      <Table
        columns={columns}
        data={data?.debts ?? []}
        isLoadingResults={loading}
      />
      <Container fluid className="mt-5">
        <Row>
          <Col xs={12} md={{ size: 6, offset: 6 }}>
            <Row>
              <Col xs={12} md={6}>
                <Button
                  type="button"
                  text="Repactar deudas"
                  loading={loading}
                  size="md"
                  fullwidth
                  outlined
                  onClick={async () => {}}
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="button"
                  onClick={() =>
                    debtVoucherModalRef.current?.openModal(selectedDebts)
                  }
                  text="Generar Voucher"
                  size="md"
                  disabled={loading || selectedDebts.length === 0}
                  fullwidth
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <DebVoucherModal
        ref={debtVoucherModalRef}
        onConfirm={() => {}}
        onDecline={() => {}}
      />
    </>
  );
}

export default DebtTable;
