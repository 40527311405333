import { format as formatRUT } from 'rut.js';

import { StudentPostCloseDetail } from '../../types/studentPostClose';

export function mapStudentPostCloseDetailResponse(
  response: StudentPostCloseDetail.DetailResponse,
) {
  const { courseEnrollment, studentSectionGrades } = response;
  const { section, studyPlanEnrollment, state } = courseEnrollment;
  const { student } = studyPlanEnrollment;

  const id =
    (student.account.run
      ? formatRUT(student.account.run)
      : student.account.passportNumber) || '';

  return {
    summary: {
      studentFullName: student.account.fullName || '',
      rut: id,
      courseName: section.course.name,
      shortening: section.course.shortening,
      section: section.name,
      teacherFullName: section.professors
        ?.map((prof) => prof.account.fullName)
        .join(' - '),
      state,
    },
    grades: [
      ...studentSectionGrades.partialGrades.map((p) => ({
        ...p,
        gradeType: 'partial-grade',
      })),
      ...[studentSectionGrades.partialGrade].map((p) => ({
        ...p,
        gradeType: 'partials-total',
      })),
      ...(studentSectionGrades.examGrade
        ? [studentSectionGrades.examGrade].map((p) => ({
            ...p,
            gradeType: 'exam-grade',
          }))
        : []),
      ...[studentSectionGrades.finalGrade].map((p) => ({
        ...p,
        gradeType: 'final',
      })),
    ] as StudentPostCloseDetail.Grade[],
  };
}
