import { Card } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import TuitionContinuityDiscountManagementFilterLoader from './parts/TuitionContinuityDiscountManagementFilterLoader';
import DiscountManagementTable from './parts/TuitionContinuityDiscountManagementTable';

import { TuitionContinuityDiscontFilter } from './parts/TuitionContinuityDiscontFilter';
import { TranslationsKeys } from '../../locales/translations';
import TuitionContinuityDiscountManagementLoader from './parts/TuitionContinuityDiscountManagementLoader';

export default function TuitionContinuityDiscountManagement() {
  const { t } = useTranslation(
    TranslationsKeys.TC_DISCOUNT_ALLOCATION_MANAGEMENT,
  );
  return (
    <div className="student-grades-closure mx-3">
      <Card className="p-4">
        <div className="mb-3 fs-16">{t('description')}</div>
        <TuitionContinuityDiscountManagementFilterLoader>
          <TuitionContinuityDiscountManagementLoader>
            <TuitionContinuityDiscontFilter />
            <DiscountManagementTable />
          </TuitionContinuityDiscountManagementLoader>
        </TuitionContinuityDiscountManagementFilterLoader>
      </Card>
    </div>
  );
}
