import { Table, addToast } from '@octano/global-ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getTcDocumentReviewDocumentsUrl,
  updateTcDocumentReviewDocumentStatus,
} from '../../../api/requests/tuitionContinuityDocumentReviewDocuments';
import { ModalConfirmation } from '../../../components/modals/Confirmation';
import TableEmptyContent from '../../../components/text/TableEmptyContent';
import {
  StudentDocumentReviewInfDocument,
  TuitionContinuityDocumentStatusEnum,
} from '../../../types/tuitionContinuityDocumentReviewDocuments';
import { logger } from '../../../utils/logger';
import TuitionContinuityDocumentReviewDocumentsModalUpload from './TuitionContinuityDocumentReviewDocumentsModalUpload';
import { useColumnsListDocumentByStatus } from './useColumnsListDocumentByStatus';
import { useOnDelete } from './useOnDelete';
import { useOnUpload } from './useOnUpload';

export interface TuitionContinuityDocumentReviewDocumentsLoaderProps {
  tcProcessId: string;
  data: StudentDocumentReviewInfDocument[];
}

export function TuitionContinuityDocumentReviewDocumentsTable({
  tcProcessId,
  data: dataProp,
}: TuitionContinuityDocumentReviewDocumentsLoaderProps) {
  const { t } = useTranslation();
  const prefix = 'tuitionContinuityDocumentReviewStudent';

  const texts = useMemo(
    () => ({
      empty: {
        title: t(`${prefix}.table.empty.title`),
        subtitle: t(`${prefix}.table.empty.subtitle`),
      },
      changeStateError: t(`${prefix}.table.changeStatusError`),
      downloadError: t(`${prefix}.table.downloadError`),
      deleteError: t(`${prefix}.table.deleteError`),
      confirm: {
        title: '¿Estás seguro de eliminar este archivo?',
        subtitle:
          'Una vez que el archivo sea eliminado no podrás deshacer la acción',
      },
      confirmApproved: {
        title: '¿Estás seguro de eliminar este archivo ya aprobado?',
        subtitle:
          'Una vez que el archivo sea eliminado no podrás deshacer la acción',
      },
    }),
    [t],
  );

  const [data, setData] = useState(dataProp);

  const onChangeStateError = useCallback(() => {
    addToast({
      icon: 'error',
      color: 'danger',
      text: texts.changeStateError,
    });
  }, [texts]);

  const onChangeState = useCallback(
    async (
      row: StudentDocumentReviewInfDocument,
      status: TuitionContinuityDocumentStatusEnum,
    ) => {
      if (!row.id) {
        logger('No se puede cambiar el estado de un documento que no existe');
        return;
      }
      const documentId = row.id;
      const oldStatus = row.status;
      row.status = status;
      setData((v) => [...v]);
      let { error } = await updateTcDocumentReviewDocumentStatus(
        documentId,
        status,
      );
      if (error) {
        row.status = oldStatus;
        setData((v) => [...v]);
        onChangeStateError();
      }
    },
    [onChangeStateError],
  );

  const onDownloadError = useCallback(() => {
    addToast({
      icon: 'error',
      color: 'danger',
      text: texts.downloadError,
    });
  }, [texts]);

  const onDownload = useCallback(
    async (documentId: number | string) => {
      let { data, error } = await getTcDocumentReviewDocumentsUrl(documentId);
      if (error) {
        onDownloadError();
      } else if (data) {
        let link = document.createElement('a');
        link.download = data.name;
        link.href = data.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    [onDownloadError],
  );

  const onDeleteConfirm = useCallback(
    (row: StudentDocumentReviewInfDocument) => {
      setData((d) => {
        const index = d.findIndex((document) => document === row);
        const newData = [...d];
        newData.splice(index, 1, {
          type: row.type,
          required: row.required,
          documentType: row.documentType,
        });
        return newData;
      });
    },
    [],
  );

  const onDeleteError = useCallback(
    (row: StudentDocumentReviewInfDocument) => {
      setData((d) => {
        const index = d.findIndex((document) => document.type === row.type);
        const newData = [...d];
        newData.splice(index, 1, row);
        return newData;
      });
      addToast({
        icon: 'error',
        color: 'danger',
        text: texts.deleteError,
      });
    },
    [texts],
  );

  const { data: modalConfirm, onDelete } = useOnDelete({
    onConfirm: onDeleteConfirm,
    onError: onDeleteError,
  });

  const onUploadConfirm = useCallback(
    async (row: StudentDocumentReviewInfDocument) => {
      setData((d) => {
        const index = d.findIndex((document) => document.type === row.type);
        const newData = [...d];
        newData.splice(index, 1, row);
        return newData;
      });
    },
    [],
  );
  const { data: modalAddDocument, onUpload } = useOnUpload({
    onConfirm: onUploadConfirm,
  });

  const columns = useColumnsListDocumentByStatus({
    onChangeState,
    onDownload,
    onDelete,
    onUpload,
  });

  return (
    <>
      <Table
        columns={columns}
        data={data}
        noResultsText={
          <TableEmptyContent
            title={texts.empty.title}
            subtitle={texts.empty.subtitle}
          />
        }
      />
      {modalConfirm.open && (
        <ModalConfirmation
          icon="information"
          open={modalConfirm.open}
          onClose={modalConfirm.onClose}
          onConfirm={modalConfirm.onConfirm}
          {...(modalConfirm.approved ? texts.confirmApproved : texts.confirm)}
        />
      )}

      {modalAddDocument.open && (
        <TuitionContinuityDocumentReviewDocumentsModalUpload
          open={modalAddDocument.open}
          tcProcessId={tcProcessId}
          onConfirm={modalAddDocument.onConfirm}
          onClose={modalAddDocument.onClose}
          document={modalAddDocument.document}
        />
      )}
    </>
  );
}
