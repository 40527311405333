import { useCallback, useEffect, useRef, useState } from 'react';

import {
  getFinantialConfig,
  getFinantialConfigFormData,
} from '../../../../api/requests/settings';
import { TariffPaymentMethodSettingValue } from '../../../../types/tariffTypes';
import { FormDataFinantial } from '../interfaces/finantial-config.interface';

export default function useFinantialSettings() {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefetching, setIsRefetching] = useState(false);
  const alreadyFetched = useRef(false);

  const [config, setConfig] =
    useState<TariffPaymentMethodSettingValue | null>();
  const [formData, setFormData] = useState<FormDataFinantial | null>();
  const [error, setError] = useState(false);

  const fetchConfig = useCallback(async () => {
    if (alreadyFetched.current) setIsRefetching(true);
    else alreadyFetched.current = true;

    const [
      { data, error: fetchError },
      { data: formDataResponse, error: formDataError },
    ] = await Promise.all([getFinantialConfig(), getFinantialConfigFormData()]);

    if (fetchError) {
      setError(true);
    } else {
      setConfig(data);
    }

    if (!formDataError && formDataResponse) {
      setFormData(formDataResponse);
    }

    setIsLoading(false);
    setIsRefetching(false);
  }, [alreadyFetched]);

  useEffect(() => {
    if (!alreadyFetched.current) {
      fetchConfig();
    }
  }, [fetchConfig]);

  return {
    isLoading,
    config,
    formData,
    error,
    refetch: fetchConfig,
    isRefetching,
  };
}
