import { Alert } from '@octano/global-ui';
import { useTexts } from '../../hooks/textsContext';
const HeaderStructureTypeFormSemiStaticFields = ({
  msgInfo,
}: {
  msgInfo: string;
}) => {
  const texts = useTexts();
  return (
    <>
      <header>
        <h2 className="fs-22 text-uppercase text-primary fw-700 mt-4 mb-2">
          {texts.form.title}
        </h2>
        <p className="fs-16 mb-2">{texts.form.subtitle}</p>
        <div className="py-2  d-flex justify-content-start align-items-center">
          <Alert
            className="mb-2"
            color="info"
            icon="information"
            size="lg"
            text={msgInfo}
            toggle={() => {}}
          />
        </div>
      </header>
    </>
  );
};

export default HeaderStructureTypeFormSemiStaticFields;
