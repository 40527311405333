import { ColumnTable, Button } from '@octano/global-ui';
import useTexts from './useTexts';

interface Params {
  onClickUpload: () => void;
}

export default function useColumns({ onClickUpload }: Params): ColumnTable[] {
  const texts = useTexts();
  return [
    {
      columnName: 'bulkDiscountTemplate',
      headerText: texts.table.bulkDiscountTemplate,
      tdClassName: 'pl-4',
      thClassName: 'pl-4',
    },
    {
      columnName: 'actions',
      headerText: texts.table.action,
      thClassName: 'text-right pr-5',
      tdClassName: 'text-right pr-4',
      cellFormat: () => {
        return (
          <Button
            size="sm"
            onClick={() => onClickUpload()}
            text={texts.table.load}
          />
        );
      },
    },
  ];
}
