import { Card, Col, Row } from 'reactstrap';

import NotesScaleSection from './parts/NotesScaleSection';
import TimeVariableSection from './parts/TimeVariableSection';
import CoursesFeatures from './parts/CoursesFeatures';

export default function Academics() {
  return (
    <Card className="p-4">
      <Row>
        <Col lg={6} md={6} sm={12}>
          <NotesScaleSection />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <TimeVariableSection />
          <CoursesFeatures />
        </Col>
      </Row>
    </Card>
  );
}
