import {
  addToast,
  Button,
  OutlinedSelect,
  OutlinedSelectOptionType,
  DateInput,
} from '@octano/global-ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Col, Row, Form } from 'reactstrap';
import DisplayError from '../../../components/info/DisplayError';
import { FormData, PeriodSelectorForm } from '../types';
import { downloadFromBlob } from '../../../utils/blob';
import { getTemplate, getBaseInfo } from '../api';
import useTexts from '../hooks/useTexts';
import { formatDate } from '../../../utils/dates';

interface FilterListProps extends PeriodSelectorForm {}

export const FilterList = ({
  periods,
  schools,
  studyPlans,
  entryPeriods,
  campuses,
  schedules,
  modalities,
  genders,
}: Readonly<FilterListProps>) => {
  const { t } = useTranslation();
  const texts = useTexts();
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      period: null,
    },
  });

  const optionsPeriods = useMemo<OutlinedSelectOptionType[]>(() => {
    return periods.map((p) => ({
      value: p.id,
      label: p.name,
    }));
  }, [periods]);

  const selectedPeriod = watch('period');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingBase, setIsLoadingBase] = useState<boolean>(false);

  if (Array.isArray(optionsPeriods) && optionsPeriods.length === 0) {
    return (
      <DisplayError
        insideCard
        textBody="No hay eventos de matrícula de continuidad definidos. Intenta más tarde"
      />
    );
  }

  const handleDownloadBaseInfo = async () => {
    setIsLoadingBase(true);
    const res = await getBaseInfo();
    if (res.error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: texts.periodsSelector.baseInfo.error,
      });
    } else {
      const blob = new Blob([res.data], { type: 'vnd.ms-excel' });
      const fileName = `Planilla_Base_Asignación_Descuentos.xlsx`;
      downloadFromBlob(blob, fileName);
    }
    setIsLoadingBase(false);
  };

  const handleDownload = async (data: FormData) => {
    if (!data.period) {
      return addToast({
        icon: 'error',
        color: 'danger',
        text: texts.periodsSelector.baseInfo.error,
      });
    }

    setIsLoading(true);

    const submitData = {
      periodId: Number(data.period.value),
      schoolId: Number(data.school?.value),
      studyPlanId: Number(data.studyPlan?.value),
      entryPeriodId: Number(data.entryPeriod?.value),
      campusId: Number(data.campus?.value),
      scheduleId: Number(data.schedule?.value),
      modalityId: Number(data.modality?.value),
      dateFrom: data.dateFrom
        ? formatDate(data.dateFrom, 'YYYY-MM-DD')
        : undefined,
      dateTo: data.dateTo ? formatDate(data.dateTo, 'YYYY-MM-DD') : undefined,
      genderId: Number(data.gender?.value),
    };

    const res = await getTemplate(submitData);

    if (res.error) {
      const error =
        res.error?.status === 404
          ? texts.periodsSelector.form.notFound
          : texts.periodsSelector.baseInfo.error;
      addToast({
        icon: 'error',
        color: 'danger',
        text: error,
      });
    } else {
      const blob = new Blob([res.data], { type: 'vnd.ms-excel' });
      const periodName = data.period.label;
      const fileName = `Planilla_Asignación_Descuentos_${periodName}.xlsx`;
      downloadFromBlob(blob, fileName);
    }
    setIsLoading(false);
  };

  return (
    <Form
      onSubmit={handleSubmit(handleDownload)}
      className="g-table-container mt-2 pb-4 pt-4"
    >
      <Row>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="period"
            control={control}
            label={texts.periodsSelector.form.period}
            options={optionsPeriods}
          />
        </Col>
      </Row>

      <Row className="pt-5">
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="school"
            control={control}
            label={texts.periodsSelector.form.school}
            isClearable
            options={schools.map((school) => ({
              value: school.id,
              label: school.name,
            }))}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="studyPlan"
            control={control}
            label={texts.periodsSelector.form.studyPlan}
            isClearable
            options={studyPlans.map((studyPlan) => ({
              value: studyPlan.id,
              label: studyPlan.name,
            }))}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="entryPeriod"
            control={control}
            label={texts.periodsSelector.form.entryPeriod}
            isClearable
            options={entryPeriods.map((entryPeriod) => ({
              value: entryPeriod.id,
              label: entryPeriod.name,
            }))}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="campus"
            control={control}
            label={texts.periodsSelector.form.campus}
            isClearable
            options={campuses.map((campus) => ({
              value: campus.id,
              label: campus.name,
            }))}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="schedule"
            control={control}
            label={texts.periodsSelector.form.schedule}
            isClearable
            options={schedules.map((schedule) => ({
              value: schedule.id,
              label: schedule.name,
            }))}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="modality"
            control={control}
            label={texts.periodsSelector.form.modality}
            isClearable
            options={modalities.map((modality) => ({
              value: modality.id,
              label: modality.name,
            }))}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <DateInput
            name="dateFrom"
            control={control}
            label={texts.periodsSelector.form.dateFrom}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <DateInput
            name="dateTo"
            control={control}
            label={texts.periodsSelector.form.dateTo}
          />
        </Col>
        <Col lg={4} className="pl-0">
          <OutlinedSelect
            name="gender"
            control={control}
            label={texts.periodsSelector.form.gender}
            isClearable
            options={genders.map((gender) => ({
              value: gender.id,
              label: gender.name,
            }))}
          />
        </Col>
      </Row>

      <Row className="mt-5 justify-content-end">
        <Col md="auto" sm="auto" xs="auto" className="pl-0">
          <Button
            text={t('tuitionContinuityDiscountAllocation.form.downloadBase')}
            type="button"
            size="md"
            loading={isLoadingBase}
            disabled={isLoadingBase}
            className="mb-3 mb-md-0"
            onClick={handleDownloadBaseInfo}
          />
        </Col>
        <Col md="auto" sm="auto" xs="auto" className="pl-0">
          <Button
            text={t('tuitionContinuityDiscountAllocation.form.submit')}
            type="submit"
            size="md"
            loading={isLoading}
            disabled={isSubmitting || !selectedPeriod}
            className="mb-3 mb-md-0"
          />
        </Col>
      </Row>
    </Form>
  );
};
