import {
  Button,
  CheckInput,
  DetailBox,
  OutlinedSelect,
  SelectOptionType,
  TextOutlinedInput,
} from '@octano/global-ui';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Form, Row } from 'reactstrap';
import { clean, validate } from 'rut.js';
import { useParameters } from '../../../hooks/useParameters';
import { useValidations } from '../../../hooks/useValidations';
import { useStudentCurrentAccountLoader } from './StudentCurrentAccountLoader';

interface SearchUserForm {
  country: SelectOptionType | null;
  identifier: string;
  showPassport: boolean;
}

const keyPrefix = 'studentCurrentAccount.search';

export default function StudentSearchAndDetail() {
  const { control, handleSubmit, reset, watch } = useForm<SearchUserForm>({
    mode: 'onSubmit',
  });
  const { countryOptions } = useParameters();
  const { msgValidations } = useValidations();
  const showPassport = watch('showPassport');
  const { updateQuery, clearData, data } = useStudentCurrentAccountLoader();
  const { t } = useTranslation();

  const onSubmit = useCallback(
    (values) => {
      updateQuery({
        identifier: validate(values.identifier)
          ? clean(values.identifier)
          : values.identifier,
        country: values.country?.value,
      });
    },
    [updateQuery],
  );

  return (
    <Container fluid className="p-4">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <p>{t(`${keyPrefix}.title`)}</p>
          </Col>
          {data ? (
            <Col>
              <DetailBox
                columns={[
                  { title: 'Nombre', body: data.name },
                  { title: 'Id', body: data.rutOrPassport },
                ]}
              />
            </Col>
          ) : null}
        </Row>

        <Row className="px-3">
          <div className="w-25 d-flex" style={{ gap: 10 }}>
            {showPassport ? (
              <div className="w-50">
                <OutlinedSelect
                  options={countryOptions}
                  control={control}
                  name="country"
                  placeholder={t(`${keyPrefix}.country`)}
                  label={t(`${keyPrefix}.country`)}
                  shouldUnregister
                  rules={{
                    required: msgValidations.required,
                  }}
                />
              </div>
            ) : null}
            <div className={showPassport ? 'w-50' : 'w-100'}>
              <TextOutlinedInput
                control={control}
                name="identifier"
                placeholder={t(`${keyPrefix}.passportOrIdentifier`)}
                label={t(`${keyPrefix}.passportOrIdentifier`)}
                rules={{
                  required: msgValidations.required,
                }}
              />
            </div>
          </div>
          <div className={`d-flex pt-4`}>
            <div>
              <Button
                type="submit"
                text="Buscar"
                size="md"
                className="ml-2"
                disabled={false}
              />
              <Button
                text="Limpiar"
                size="md"
                className="ml-2"
                outlined
                onClick={() => {
                  reset({
                    country: null,
                    identifier: '',
                  });
                  clearData();
                }}
              />
            </div>
          </div>
        </Row>

        <Row className="px-3">
          <CheckInput
            name="showPassport"
            control={control}
            label="BUSCAR POR PASAPORTE"
          />
        </Row>
      </Form>
    </Container>
  );
}
