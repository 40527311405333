import { useCallback } from 'react';
import { uploadTemplate } from '../api';
export interface UseActions {
  bulkDiscountAllocation: (data: any) => Promise<void>;
}
type ActionType = 'create' | 'update' | 'delete';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const { onSuccess } = props;
  const bulkDiscountAllocation = useCallback(
    async (file: File) => {
      const result = await uploadTemplate(file);

      if (!result.error) {
        return onSuccess('create');
      } else {
        throw result.error.data;
      }
    },
    [onSuccess],
  );

  return {
    bulkDiscountAllocation,
  };
}
