import useActions from './useActions';
import { useHistory } from 'react-router-dom';
import { addToast } from '@octano/global-ui';

const useUpdateAction = () => {
  const history = useHistory();

  const { update } = useActions({
    onSuccess: (a) => {
      if (a === 'create' || a === 'update') {
        history.goBack();
        addToast({
          icon: 'success',
          color: 'success',
          text: 'Estructura guardada con éxito',
        });
      }
    },
    onError: (_, messsage) => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: messsage ?? 'Ha ocurrido un error',
      });
    },
  });

  return { update };
};

export default useUpdateAction;
