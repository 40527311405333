export namespace StudentPostCloseSearch {
  export interface SearchResponse {
    data: SearchEntry[];
    total: number;
    total_pages: number;
  }

  export interface SearchEntry {
    id: number;
    createdAt: string;
    updatedAt: string;
    account: Account;
    studyPlanEnrollments: StudyPlanEnrollment[];
  }

  export interface StudyPlanEnrollment {
    id: number;
    studentId: number;
    status: string;
    academicOfferStudyPlanVersionOfferId: number;
    sectionPackageId: number;
    createdAt: string;
    updatedAt: string;
    deletedAt?: any;
    courseEnrollments: CourseEnrollment[];
    academicOfferStudyPlanVersionOffer: AcademicOfferStudyPlanVersionOffer;
  }

  export interface AcademicOfferStudyPlanVersionOffer {
    id: number;
    academicOfferId: number;
    studyPlanVersionOfferId: number;
    quota: number;
    createdAt: string;
    updatedAt: string;
    studyPlanVersionOffer: StudyPlanVersionOffer;
  }

  export interface StudyPlanVersionOffer {
    id: number;
    createdAt: string;
    updatedAt: string;
    studyPlanVersion: StudyPlanVersion;
  }

  export interface StudyPlanVersion {
    entryRequirements: string;
    graduationRequirements: string;
    degreeRequirements: string;
    id: number;
    code: string;
    name: string;
    status: string;
    studyPlanId: number;
    createdAt: string;
    updatedAt: string;
  }

  export interface CourseEnrollment {
    id: number;
    state: string;
    semesterCourseId: number;
    sectionId: number;
    createdAt: string;
    updatedAt: string;
    deletedAt?: any;
    section: Section;
  }

  export interface Section {
    id: number;
    name: string;
    maximumStudent: number;
    createdAt: string;
    updatedAt: string;
    campusId: number;
    closedAt: string;
    closedWithId: number;
    period: Period;
    course: Course;
  }

  export interface Course {
    id: number;
    name: string;
    code: string;
    shortening: string;
    credits: number;
    schoolId: number;
    createdAt: string;
    updatedAt: string;
    isEnable: boolean;
  }

  export interface Period {
    id: number;
    code: string;
    name: string;
    description: string;
    creditMin: number;
    creditMax: number;
    startDate: string;
    endDate: string;
    lastLoadDate: string;
    loadingDeadline?: any;
    uploadDateSectionAttributes?: any;
    sectionAttributesUploadDeadline?: any;
    createdAt: string;
    updatedAt: string;
    active: boolean;
  }

  export interface Account {
    id: number;
    email: string;
    personalEmail: string;
    name: string;
    run: string;
    paternalLastName: string;
    maternalLastName: string;
    lastNames: string;
    paternalPreference: boolean;
    birthday: string;
    lastLogin: string;
    isActive: boolean;
    passportNumber?: any;
    countryId?: any;
    studentId: number;
    assistantId?: any;
    teacherId?: any;
    fullName: string;
  }
}

export namespace StudentPostCloseDetail {
  export interface PartialEvaluationSubevaluationsGrade {
    gradeType: 'partial-grade';
    date: string;
    grade: Grade | null;
    name: string;
    percentage: number;
    studentTestGradeId?: number;
    testGradeId: number;
  }

  export interface PartialEvaluationSubevaluations {
    gradeType: 'partial-grade';
    type: 'subevaluations';
    testGradeGroupId: number;
    name: string;
    grade: Grade | null;
    percentage: number;
    date: string;
    grades: PartialEvaluationSubevaluationsGrade[];
  }

  export interface PartialEvaluationNormal {
    gradeType: 'partial-grade';
    type: 'normal';
    testGradeId: number;
    studentTestGradeId?: number;
    name: string;
    grade: Grade | null;
    percentage: number;
    date: string;
  }

  export type PartialGradeCalculated =
    | PartialEvaluationSubevaluations
    | PartialEvaluationNormal;

  export interface DetailResponse {
    courseEnrollment: CourseEnrollment;
    studentSectionGrades: StudentSectionGrades;
  }

  export interface StudentSectionGrades {
    id: number;
    testGrades: TestGrade[];
    examGrade: Omit<ExamGrade, 'gradeType'> | null;
    partialGrade: Omit<PartialGrade, 'gradeType'>;
    partialGrades: Omit<PartialGradeCalculated, 'gradeType'>[];
    finalGrade: Omit<FinalGrade, 'gradeType'>;
  }

  export interface FinalGrade {
    gradeType: 'final';
    name: string;
    grade?: any;
    percentage: number;
    date?: any;
  }

  export interface PartialGrade {
    gradeType: 'partials-total';
    name: string;
    grade?: any;
    percentage: number;
    date: string;
  }

  export interface ExamGrade {
    gradeType: 'exam-grade';
    name: string;
    grade?: any;
    finalGradeId: number;
    date?: any;
    percentage: number;
  }

  export type TestGradeEvaluation =
    | PartialEvaluationSubevaluations
    | PartialEvaluationNormal;

  export interface TestGrade {
    studentTestGradeId?: number;
    grade?: number;
    testGradeId: number;
    name: string;
    date: string;
    percentage: number;
  }

  export interface CourseEnrollment {
    id: number;
    state: 'A' | 'R' | 'P';
    semesterCourseId: number;
    sectionId: number;
    createdAt: string;
    updatedAt: string;
    deletedAt?: any;
    section: Section;
    studyPlanEnrollment: StudyPlanEnrollment;
  }

  export interface StudyPlanEnrollment {
    id: number;
    studentId: number;
    status: string;
    academicOfferStudyPlanVersionOfferId: number;
    sectionPackageId: number;
    createdAt: string;
    updatedAt: string;
    deletedAt?: any;
    student: Student;
  }

  export interface Student {
    id: number;
    createdAt: string;
    updatedAt: string;
    account: Account;
  }

  export interface Account {
    id: number;
    email: string;
    personalEmail: string;
    name: string;
    run: string;
    paternalLastName: string;
    maternalLastName: string;
    lastNames: string;
    paternalPreference: boolean;
    birthday: string;
    lastLogin: string;
    isActive: boolean;
    passportNumber?: any;
    countryId?: any;
    studentId: number;
    assistantId?: any;
    teacherId?: any;
    fullName: string;
  }

  export interface Section {
    id: number;
    name: string;
    maximumStudent: number;
    createdAt: string;
    updatedAt: string;
    campusId: number;
    closedAt: string;
    closedWithId: number;
    period: Period;
    course: Course;
    professors: Professor[];
  }

  export interface Period {
    id: number;
    code: string;
    name: string;
    description: string;
    creditMin: number;
    creditMax: number;
    startDate: string;
    endDate: string;
    lastLoadDate: string;
    loadingDeadline?: any;
    uploadDateSectionAttributes?: any;
    sectionAttributesUploadDeadline?: any;
    createdAt: string;
    updatedAt: string;
    active: boolean;
  }

  export interface Course {
    id: number;
    name: string;
    code: string;
    shortening: string;
    credits: number;
    schoolId: number;
    createdAt: string;
    updatedAt: string;
    isEnable: boolean;
  }

  export interface Professor {
    id: number;
    createdAt: string;
    updatedAt: string;
    account: Account;
  }

  export type Grade =
    | StudentPostCloseDetail.TestGradeEvaluation
    | StudentPostCloseDetail.PartialGrade
    | StudentPostCloseDetail.ExamGrade
    | StudentPostCloseDetail.FinalGrade;

  export function isTestGrade(
    grade: Grade,
  ): grade is StudentPostCloseDetail.TestGradeEvaluation {
    return grade.gradeType === 'partial-grade';
  }

  export function hasTestGradeId<T extends object>(
    grade: T,
  ): grade is { testGradeId: number } & T {
    return grade.hasOwnProperty('testGradeId') && 'testGradeId' in grade;
  }

  export function isExamGrade(
    grade: Grade,
  ): grade is StudentPostCloseDetail.ExamGrade {
    return grade.gradeType === 'exam-grade';
  }
}
