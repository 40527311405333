import { addToast } from '@octano/global-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import { getForm, getTeachers } from '../../../../api/requests/teacherFile';
import { PathsLayouts } from '../../../../config/routes';
import { TranslationsKeys } from '../../../../locales/translations';
import ConfirmDisableTeacher, {
  ConfirmDisableTeacherMethods,
} from '../../TeacherActivation/parts/ConfirmDisableTeacher';
import ConfirmSendTeacherInvitation, {
  ConfirmSendTeacherInvitationMethods,
} from '../../TeacherActivation/parts/ConfirmSendTeacherInvitation';
import { TeacherFileItemAction } from './enums/teacher-file-item-action.enum';
import { TeacherFileFiltersForm } from './interfaces/teacher-file-filters-form.interface';
import { TeacherFileFilters } from './interfaces/teacher-file-filters.interface';
import { TeacherFileItem } from './interfaces/teacher-file-item.interface';
import { SearchControls } from './parts/SearchControls';
import { TeachersTable } from './parts/TeacherTable';

export const ITEMS_PER_PAGE = 10;

const TeacherFile = () => {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_LIST,
  );
  const history = useHistory();

  const sendTeacherInvitation =
    useRef<ConfirmSendTeacherInvitationMethods>(null);
  const disableTeacher = useRef<ConfirmDisableTeacherMethods>(null);

  const [filters, setFilters] = useState<TeacherFileFilters>();
  const [tableData, setTableData] = useState<TeacherFileItem[]>([]);

  const [isLoadingTable, setIsTableLoading] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);

  const [filterForm, setFilterForm] = useState<TeacherFileFiltersForm>();

  const handleSearch = useCallback(
    async (params: { page: number } & TeacherFileFilters) => {
      setIsTableLoading(true);
      const { data, error } = await getTeachers({
        ...params,
        items_per_page: ITEMS_PER_PAGE,
      });

      if (error) {
        addToast({
          text: t(`errorOnSearch`),
          color: 'danger',
          icon: 'error',
        });
      } else {
        setTableData(data?.data ?? []);
        setTotalPages(data?.total_pages || 0);
        setTotalItems(data?.total || 0);
      }
      setIsTableLoading(false);
    },
    [t],
  );

  const handleForm = useCallback(async () => {
    const { error, data } = await getForm();
    if (error) {
      addToast({
        text: t(`errorOnSearch`),
        color: 'danger',
        icon: 'error',
      });
    } else {
      setFilterForm(data ?? undefined);
    }
  }, [t]);

  const handleProcessFilters = useCallback(
    (next?: TeacherFileFilters) => {
      if (!isLoadingTable) {
        setFilters(next);
        setCurrentPage(0);
      }
    },
    [isLoadingTable],
  );

  const handleReload = useCallback(() => {
    handleSearch({ ...filters, page: currentPage });
  }, [handleSearch, currentPage, filters]);

  const handleAction = useCallback(
    (action: TeacherFileItemAction, item: TeacherFileItem) => {
      switch (action) {
        case TeacherFileItemAction.Disable:
          disableTeacher?.current?.open(item);
          break;
        case TeacherFileItemAction.SendInvitation:
          sendTeacherInvitation?.current?.open(item);
          break;
        case TeacherFileItemAction.Edit:
          history.push(
            `${PathsLayouts.teacherFile}/${item.id}/personal-background`,
          );
          break;
      }
    },
    [history],
  );

  useEffect(() => {
    handleSearch({ ...filters, page: currentPage });
  }, [handleSearch, currentPage, filters]);

  useEffect(() => {
    handleForm();
  }, [handleForm]);

  return (
    <>
      <div className="mx-3">
        <Card className="p-4">
          <Row>
            <Col className="mb-4" xs={12}>
              <div className="d-flex flex-wrap w-100 align-items-center justify-content-center">
                <div>
                  <p className="fs-20 text-uppercase text-primary mb-0 fw-700">
                    {t(`title`)}
                  </p>
                </div>
                <div className="flex-fill px-2" />
              </div>
            </Col>
            <Col className="mb-4" xs={12}>
              <SearchControls
                {...filterForm}
                loading={isLoadingTable}
                onSearch={handleProcessFilters}
              />
            </Col>
            <Col xs={12}>
              <TeachersTable
                data={tableData}
                itemPerPage={ITEMS_PER_PAGE}
                totalItems={totalItems}
                totalPages={totalPages}
                currentPage={currentPage}
                isLoadingResults={isLoadingTable}
                hasActivationPermissions={true}
                onChangePage={(page) => {
                  setCurrentPage(page);
                }}
                onPressAction={handleAction}
              />
            </Col>
          </Row>
        </Card>
      </div>
      <ConfirmSendTeacherInvitation
        ref={sendTeacherInvitation}
        onConfirm={handleReload}
      />
      <ConfirmDisableTeacher ref={disableTeacher} onConfirm={handleReload} />
    </>
  );
};

export default TeacherFile;
