import { ReactElement, useCallback } from 'react';

import { createFetchContext } from '../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../components/info/DisplayError';
import Loading from '../../../../components/info/Loading';
import { getAcademicOfferCreateFormData } from '../api';
import { VacantOfferFormDataResponse } from '../types';

export interface AcademicOffersFormLoaderProps {
  children: (props: {
    data: VacantOfferFormDataResponse;
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  VacantOfferFormDataResponse
>();

export const BaseLoaderConsumer = FetchConsumer;

export default function AcademicOffersFormLoader({
  children,
}: AcademicOffersFormLoaderProps) {
  const request = useCallback(() => {
    return getAcademicOfferCreateFormData();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                insideCard
                textBody={error.code}
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          if (loading) {
            return (
              <div className="mx-3">
                <Loading insideCard />
              </div>
            );
          }

          if (!data) {
            return (
              <DisplayError
                insideCard
                textBody="Data no cargada"
                retryAction={refresh}
                loadingAction={loading}
              />
            );
          }

          return children({ data, refresh });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
