import { addToast, Button, Table } from '@octano/global-ui';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../../../locales/translations';
import HeaderInfo from '../../parts/Header/HeaderInfo';
import useActions from '../hooks/useActions';
import useColumns from '../hooks/useColumns';
import { useHiringInformationFormDataLoader } from './Loaders/HiringInformationFormDataLoader';
import { useHiringInformationLoader } from './Loaders/HiringInformationLoader';
import ConfirmModal, { ConfirmModalRef } from './Modals/ConfirmModal';
import CreateOrUpdateModal, {
  CreateOrUpdateRef,
} from './Modals/CreateOrUpdateModal';

function HiringInformationTable() {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_HIRING_INFORMATION,
  );
  const { data, loading, refresh } = useHiringInformationLoader();
  const { data: formData, loading: loadingFormData } =
    useHiringInformationFormDataLoader();

  const confirmModalRef = useRef<ConfirmModalRef>(null);
  const createOrUpdateModalRef = useRef<CreateOrUpdateRef>(null);

  const actions = useActions({
    onSuccess: (a) => {
      if (a === 'create' || a === 'update') {
        createOrUpdateModalRef?.current?.closeModal();
      }
      refresh();
    },
    onError: (_, messsage) => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: messsage ?? 'Ha ocurrido un error',
      });
    },
  });

  const onClickEdit = (row: any) => {
    createOrUpdateModalRef.current?.openUpdate(row?.id, row);
  };
  const onClickRemove = (row: any) => {
    confirmModalRef.current?.openModal(row);
  };

  const columns = useColumns({
    hourTypes: formData?.hourTypes,
    onClickEdit,
    onClickRemove,
  });

  return (
    <>
      <HeaderInfo />
      <div className="w-100 d-flex  mb-4">
        <div className="flex-fill d-block">
          <span className="w-100 d-block fs-20 text-uppercase text-primary mb-0 fw-700">
            {t('title')}
          </span>
          <span className="w-100 d-block fs-16">{t('description')}</span>
        </div>
        {!!data?.teacher?.id && (
          <div>
            <Button
              text={t('create')}
              color="primary"
              icon="plus"
              size="md"
              outlined
              onClick={() =>
                createOrUpdateModalRef.current?.openCreate(data?.teacher?.id)
              }
            />
          </div>
        )}
      </div>

      <Table
        columns={columns}
        data={data?.teacherAssignmentRecords ?? []}
        isLoadingResults={loading || loadingFormData}
      />
      <CreateOrUpdateModal ref={createOrUpdateModalRef} actions={actions} />

      <ConfirmModal
        onDecline={() => confirmModalRef.current?.closeModal()}
        onConfirm={(row) => {
          actions.deleteRecord(row.id);
        }}
        ref={confirmModalRef}
      />
    </>
  );
}

export default HiringInformationTable;
