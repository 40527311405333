import { Button, Modal } from '@octano/global-ui';
import {
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Col, Container, Row } from 'reactstrap';
import { UseActions } from '../../hooks/useActions';
import { useTranslation } from 'react-i18next';
import { useHiringInformationFormDataLoader } from '../Loaders/HiringInformationFormDataLoader';
import { TeacherAssignmentRecord } from '../../type';
import CreateOrUpdateForm, { FormData } from './CreateOrUpdateForm';
import { mapTeacherAssignmentRecordToFormData } from '../../utils';
import { TranslationsKeys } from '../../../../../../../locales/translations';

interface Props {
  actions: UseActions;
}

export interface CreateOrUpdateRef {
  closeModal: () => void;
  openCreate: (teacherId: number) => void;
  openUpdate: (recordId: number, row: TeacherAssignmentRecord) => void;
}

const dictPrefix = 'createModal';

const defaultForm = {
  teacherContractType: null,
  campus: null,
  studyPlan: null,
  teacherAcademicRank: null,
  startPeriod: null,
  startAt: null,
  hours: {},
};

const CreateOrUpdateModal = forwardRef<CreateOrUpdateRef, Props>(
  ({ actions }, ref) => {
    const { t } = useTranslation(
      TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_HIRING_INFORMATION,
    );
    const forms = useForm<FormData>({ defaultValues: defaultForm });
    const {
      reset,
      watch,
      handleSubmit,
      formState: { isSubmitting },
    } = forms;
    const { data } = useHiringInformationFormDataLoader();
    const [modal, setModal] = useState(false);

    const [teacherId, setTeacherId] = useState<number>(0);
    const [recordId, setRecordId] = useState<number>(0);

    const [hourError, setHourError] = useState<string>();

    const hoursData = watch('hours');

    const hoursDataSum = useMemo(() => {
      const items = Object.values(hoursData ?? {});

      return items.reduce((a, b) => Number(a ?? 0) + Number(b ?? 0), 0);
    }, [hoursData]);

    const handleClose = useCallback(() => {
      setModal(false);
      setTeacherId(0);
      setRecordId(0);
      reset(defaultForm);
    }, [reset]);

    const handleOpen = useCallback(
      (row?: TeacherAssignmentRecord) => {
        if (row) {
          reset(
            mapTeacherAssignmentRecordToFormData(row, data?.hourTypes ?? []),
          );
        } else {
          reset(defaultForm);
        }
        setModal(true);
      },
      [reset, data?.hourTypes],
    );

    const handleOpenCreate = useCallback(
      (id: number) => {
        setTeacherId(id);
        setRecordId(0);
        handleOpen(undefined);
      },
      [handleOpen],
    );

    const handleOpenUpdate = useCallback(
      (id: number, row: TeacherAssignmentRecord) => {
        setRecordId(id);
        setTeacherId(0);
        handleOpen(row);
      },
      [handleOpen],
    );

    useImperativeHandle(ref, () => ({
      openCreate: handleOpenCreate,
      openUpdate: handleOpenUpdate,
      closeModal: handleClose,
    }));

    const onSubmit = useCallback(
      async (values: FormData) => {
        if (hoursDataSum <= 0) {
          setHourError(t(`${dictPrefix}.errors.minHours`));
          return;
        }

        setHourError(undefined);
        const mappedHours: any[] = [];
        Object.entries(values.hours).forEach(([typeId, value]) => {
          mappedHours.push({
            typeId,
            value: Number(value),
          });
        });
        const payload: any = {
          startAt: values.startAt ?? null,
          campusId: values?.campus?.value,
          studyPlanId: values?.studyPlan?.value,
          teacherContractTypeId: values.teacherContractType?.value,
          teacherAcademicRankId: values?.teacherAcademicRank?.value,
          startPeriodId: values?.startPeriod?.value,
          schoolId: values?.school?.value,
          academicLevelId: values?.academicLevel?.value,
          modalityId: values?.modality?.value,
          deanshipId: values?.deanship?.value ?? null,
          hours: mappedHours,
        };
        if (recordId) {
          await actions.updateRecord({
            ...payload,
            id: recordId,
          });
        } else {
          await actions.createRecord({
            ...payload,
            teacherId,
          });
        }
      },
      [recordId, actions, teacherId, hoursDataSum, t],
    );

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <h1 className="text-dark fs-20 mt-3 mb-4 text-center">
            {t(`${dictPrefix}.title`)}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...forms}>
              <Row>
                <CreateOrUpdateForm
                  hoursDataSum={hoursDataSum}
                  hourError={hourError}
                />

                <Col xs={12} className="py-4" />
                <Col xs={12} md={6}>
                  <Button
                    outlined
                    text={t(`${dictPrefix}.actions.close`)}
                    fullwidth
                    onClick={() => setModal(false)}
                    className="mb-3"
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Button
                    type="submit"
                    text={t(`${dictPrefix}.actions.save`)}
                    className="mb-3"
                    fullwidth
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </Col>
              </Row>
            </FormProvider>
          </form>
        </Container>
      </Modal>
    );
  },
);

export default CreateOrUpdateModal;
