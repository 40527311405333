import { TextInput, addToast } from '@octano/global-ui';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { TariffData } from '../../../../../../../types/tariffTypes';
import {
  TariffFormatter,
  moneyFormatter,
} from '../../../../../../../utils/currency';

interface ITariffQuotaDisplayDataProps {
  tariffData: TariffData;
  paydayDescription?: string;
  showTuitionAmount?: boolean;
  tariffDownPaymentEnabled?: boolean;
  maxTariffDownPayment?: number;
  tuitionValue?: number;
  children: JSX.Element;
  onChangeFinalTariff?: (next: number) => void;
}

export default function TariffDisplayData({
  tariffData,
  paydayDescription,
  showTuitionAmount = false,
  maxTariffDownPayment = 0,
  tuitionValue = 0,
  tariffDownPaymentEnabled = false,
  children,
  onChangeFinalTariff,
}: ITariffQuotaDisplayDataProps) {
  const prefix = 'tuitionProcessNoSua.tariffQuotas';
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext();

  const stringTariffDownPayment: string | undefined = watch(
    'tariffDownPayment',
  );

  const tariffValue = tariffData.amountsWithDiscounts.feeNetAmount;

  const tariffDownPayment = useMemo(() => {
    if (stringTariffDownPayment?.trim()) {
      return Number(`${stringTariffDownPayment}`.replace(/\D/g, ''));
    }
    return 0;
  }, [stringTariffDownPayment]);

  const finalTariff = useMemo(() => {
    const final = tariffValue - tariffDownPayment;
    return final > 0 ? final : 0;
  }, [tariffDownPayment, tariffValue]);

  const [tariff, tuition] = useMemo(
    () => [
      moneyFormatter().format(tariffValue),
      moneyFormatter().format(tuitionValue),
    ],
    [tuitionValue, tariffValue],
  );

  useEffect(() => {
    if (tariffDownPayment > maxTariffDownPayment) {
      setValue('tariffDownPayment', TariffFormatter(maxTariffDownPayment));
      addToast({
        icon: 'information',
        color: 'warning',
        text: t(`${prefix}.maxDownPaymentAlert`, {
          amount: moneyFormatter().format(maxTariffDownPayment),
        }),
      });
    }
  }, [maxTariffDownPayment, setValue, t, tariffDownPayment]);

  useEffect(() => {
    if (tariffDownPayment === tariffValue && tariffDownPayment !== 0) {
      setValue('tariffDownPayment', '');
      !!onChangeFinalTariff && onChangeFinalTariff(tariffValue);
    }
  }, [onChangeFinalTariff, setValue, tariffDownPayment, tariffValue]);

  return (
    <>
      <Row>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="studyPlan"
            label={t(`${prefix}.displayData.studyPlan`)}
            disabled
            value={
              tariffData.studyPlan.studyPlanVersionOffer.studyPlanVersion?.name
            }
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="campus"
            label={t(`${prefix}.displayData.campus`)}
            disabled
            value={tariffData.studyPlan.studyPlanVersionOffer.campus?.name}
          />
        </Col>
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="schedule"
            label={t(`${prefix}.displayData.schedule`)}
            disabled
            value={tariffData.studyPlan.studyPlanVersionOffer.schedule?.name}
          />
        </Col>
        {showTuitionAmount ? (
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name="tuitionAmount"
              label={t(`${prefix}.displayData.tuition`)}
              disabled
              value={tuition}
            />
          </Col>
        ) : null}
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="tariff"
            label={t(
              `${prefix}.displayData.${
                tariffData.amountsWithDiscounts.feeDiscountsAmount > 0
                  ? 'discountedTariff'
                  : 'tariff'
              }`,
            )}
            disabled
            value={tariff}
          />
        </Col>
        {!!(tariffDownPaymentEnabled && maxTariffDownPayment) && (
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              control={control}
              name="tariffDownPayment"
              label={t(`${prefix}.displayData.tariffDownPayment`)}
              disabled={false}
              formatter={TariffFormatter}
              onBlur={() =>
                !!onChangeFinalTariff && onChangeFinalTariff(finalTariff)
              }
            />
          </Col>
        )}
        {!!(tariffDownPaymentEnabled && maxTariffDownPayment) && (
          <Col className="pb-3" xs={12} lg={4}>
            <TextInput
              name="finalTariff"
              label={t(`${prefix}.displayData.finalTariff`)}
              disabled
              value={TariffFormatter(finalTariff)}
              formatter={TariffFormatter}
            />
          </Col>
        )}
        <Col className="pb-3" xs={12} lg={4}>
          <TextInput
            name="payDay"
            label={t(`${prefix}.displayData.payDay`)}
            disabled
            value={paydayDescription}
          />
        </Col>
        {children}
      </Row>
    </>
  );
}
