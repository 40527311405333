import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TranslatonsArray } from '../locales/translations';

import { formatI18n, LazyImportPlugin } from '../utils/i18n';

const namespaces = TranslatonsArray;

export const checkNamespaces = () => {
  if (!i18n.isInitialized) {
    return false;
  }

  return namespaces.every((ns) => i18n.hasResourceBundle('es', ns));
};

i18n
  .use(LazyImportPlugin)
  .use(initReactI18next)
  .init({
    lng: 'es',
    fallbackLng: 'es',
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false,
    },
    ns: namespaces,
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
      format: formatI18n,
    },
  });

export default i18n;
