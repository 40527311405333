export enum PaymentAgreementType {
  Tuition = 'tuition',
  Tariff = 'tariff',
}
export interface Debt {
  //identificador de la deuda
  id: number;
  //Corresponde a una descripción, cada deuda puede definir su concepto en su Mapper
  concept: string;
  //Tipo de acuerdo de momento tenemos arancel y matricula, pero luego vinen las repactaciones
  agreementType: PaymentAgreementType;
  //Nº de cuota: ejemplo 1/7(uno de siete cuotas)
  numberOfInstallment: string;
  //Fecha de vencimiento de la cuota
  expirationDate: string;
  //dias de atraso(días de mora)
  daysLate: number;
  //Monto total de la deuda
  capitalAmount: number;
  interestRate: number;
  interestAmount: number;
  totalAmount: number;
  paidAmount: number;
  outstandingDebt: number;
}

export interface DebtRsponse {
  debts: Debt[];
}
