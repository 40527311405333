import {
  Button,
  FileInputControlled,
  Modal,
  addToast,
} from '@octano/global-ui';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Container, Form, Row } from 'reactstrap';
import { useValidations } from '../../../hooks/useValidations';
import { UseActions } from '../hooks/useActions';
import useTexts from '../hooks/useTexts';

export interface DiscountAllocationModalRef {
  closeModal: () => void;
  openModal: () => void;
}

interface Props {
  actions: UseActions;
  onErrorFileId: (id: string) => void;
}

const DiscountAllocationModal = forwardRef<DiscountAllocationModalRef, Props>(
  ({ actions, onErrorFileId }, ref) => {
    const forms = useForm({
      mode: 'onChange',
    });
    const {
      control,
      reset,
      handleSubmit,
      formState: { isSubmitting },
    } = forms;

    const texts = useTexts();
    const { msgValidations } = useValidations();
    const [modal, setModal] = useState(false);

    useImperativeHandle(ref, () => ({
      openModal() {
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    const onSubmit = async (values: any) => {
      try {
        await actions.bulkDiscountAllocation(values.file);
        reset();
        setModal(false);
      } catch (error: any) {
        if (error?.fileUploadFailureId) {
          onErrorFileId(error.fileUploadFailureId);
        } else {
          addToast({
            icon: 'error',
            color: 'danger',
            text: texts.modal.error,
          });
        }
        reset();
        setModal(false);
      }
    };

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <p className="text-dark fs-20 mt-3 mb-4 text-center">
            {texts.modal.title}
          </p>
          <p className="text-dark fs-16 mt-3 mb-4 text-center">
            {texts.modal.explanation}
          </p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-5">
              <Col xs={12}>
                <FileInputControlled
                  name="file"
                  label=""
                  btnText={texts.modal.form.btnText}
                  rules={{
                    required: msgValidations.required,
                  }}
                  accept=" application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  control={control}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} className="pb-2">
                <Button
                  onClick={() => setModal(false)}
                  text={texts.modal.form.cancel}
                  outlined
                  fullwidth
                  disabled={isSubmitting}
                />
              </Col>
              <Col xs={12} sm={6} className="pb-2">
                <Button
                  type="submit"
                  text={texts.modal.form.submit}
                  fullwidth
                  loading={isSubmitting}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </Modal>
    );
  },
);

export default DiscountAllocationModal;
