import { ColumnTable } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { TranslationsKeys } from '../../../../../../locales/translations';

export default function useColumns(
  actionsColumns: ColumnTable[],
): ColumnTable[] {
  const { t } = useTranslation(
    TranslationsKeys.TEACHER_MANAGMENT_TEACHER_FILE_ADDITIONAL_DOCUMENTATION,
  );

  return [
    {
      columnName: 'name',
      headerText: t('table.name'),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'description',
      headerText: t('table.description'),
      tdClassName: 'text-center',
      thClassName: 'text-center',
    },
    {
      columnName: 'file',
      headerText: t('table.file'),
      tdClassName: 'text-center',
      thClassName: 'text-center',
      cellFormat: ({ value }) =>
        value?.name ? `${value?.name}.${value?.extension}` : '-',
    },
    ...actionsColumns,
  ];
}
