import { Button, Modal, addToast } from '@octano/global-ui';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import useTexts from '../hooks/useTexts';
import { getFileUploadFailure } from '../api';
import { downloadFromBlob } from '../../../utils/blob';

export interface UploadFailureModalRef {
  openModal: () => void;
  closeModal: () => void;
}

interface Props {
  fileUploadFailureId: string;
}

const UploadFailureModal = forwardRef<UploadFailureModalRef, Props>(
  ({ fileUploadFailureId }, ref) => {
    const [modal, setModal] = useState(false);
    const texts = useTexts();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDownload = async () => {
      setIsLoading(true);
      const res = await getFileUploadFailure(fileUploadFailureId);

      if (res.error) {
        addToast({
          icon: 'error',
          color: 'danger',
          text: texts.uploadFailureModal.error,
        });
      } else {
        const blob = new Blob([res.data], { type: 'vnd.ms-excel' });
        const fileName = `Planilla_Asignación_Descuentos_Errores.xlsx`;
        downloadFromBlob(blob, fileName);
      }
      setIsLoading(false);
      setModal(false);
    };

    useImperativeHandle(ref, () => ({
      openModal() {
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <p className="text-dark fs-22 mt-3 mb-4 text-center">
            {texts.uploadFailureModal.title}
          </p>
          <p className="text-dark fs-16 mt-3 mb-4 text-center">
            {texts.uploadFailureModal.description}
          </p>
          <Row>
            <Col className="text-center mt-5">
              <Button
                onClick={handleDownload}
                text={texts.uploadFailureModal.download}
                fullwidth
                loading={isLoading}
                disabled={isLoading}
              />
            </Col>
          </Row>
        </Container>
      </Modal>
    );
  },
);

export default UploadFailureModal;
