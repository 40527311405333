import { addToast, Button, Modal, Select, TextInput } from '@octano/global-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { saveTuitionContinuityProcessManagementDetailDiscount } from '../../../api/requests/tuitionContinuityProcessManagement';
import { CardTitle } from '../../../components/text';
import { useLayoutState } from '../../../hooks/useLayoutState';
import { useValidations } from '../../../hooks/useValidations';
import useTexts from '../hooks/useTexts';

interface DiscountItem {
  id: number;
  code: string;
  name: string;
  description: string | null;
  percentage: number;
}

export interface TuitionContinuityModalDiscount {
  id: number;
  code: string;
  name: string;
  percentage: number;
  type: 'tariff' | 'tuition';
}

interface TuitionContinuityProcessManagementDiscountModalProps {
  studyPlanEnrollmentId: number;
  show: boolean;
  discounts: DiscountItem[];
  discountsOnPossession?: TuitionContinuityModalDiscount[];
  periodId: number | string;
  onComplete: (completed: boolean) => void;
}

interface FormData {
  code: string;
  percentage: number;
  name: string;
  type: { label: string; value: 'tariff' | 'tuition' } | null;
}

export default function TuitionContinuityProcessManagementDiscountModal({
  studyPlanEnrollmentId,
  show,
  discountsOnPossession,
  discounts,
  periodId,
  onComplete,
}: TuitionContinuityProcessManagementDiscountModalProps) {
  //custom hooks
  const texts = useTexts();
  const { showErrorModal } = useLayoutState();
  const { validateTextNotEmpty } = useValidations();
  //Form hooks
  const {
    control,
    handleSubmit,
    formState,
    reset,
    watch,
  } = useForm<FormData>();
  const code = watch('code');
  const type = watch('type');

  useEffect(() => {
    const currentDiscount = discountsOnPossession?.find(
      (element) => element.type === type?.value,
    );
    if (currentDiscount) {
      reset({
        code: currentDiscount.code,
        percentage: currentDiscount.percentage,
        name: currentDiscount.name,
      });
    }
  }, [type, reset, discountsOnPossession]);

  // Al ir tipeando en codigo busca si existe y autocompleta los campos
  useEffect(() => {
    const found = discounts.find((element) => element.code === code);
    if (found) {
      reset({
        code,
        percentage: found.percentage,
        name: found.name,
      });
    } else {
      reset({
        code,
        type: null,
      });
    }
  }, [code, discounts, reset]);

  const saveDiscount = async (values: FormData) => {
    console.log(values);

    const {
      error,
    } = await saveTuitionContinuityProcessManagementDetailDiscount({
      studyPlanEnrollmentId,
      discountId: discounts.find((element) => element.code === code)?.id!,
      periodId: Number(periodId),
      type: values.type!.value,
    });
    if (error && error.code === 'CONNECTION') {
      showErrorModal();
    } else if (error) {
      addToast({
        icon: 'error',
        color: 'danger',
        text: texts.error,
      });
    } else {
      addToast({
        icon: 'success',
        color: 'success',
        text: texts.success,
      });
      onComplete(true);
    }
  };

  return (
    <Modal
      isOpen={show}
      toggle={() => onComplete(false)}
      size="md"
      unmountOnClose
    >
      <form onSubmit={handleSubmit(saveDiscount)}>
        <div className="text-center">
          <CardTitle children={texts.title} />
        </div>
        <Row className="mt-3">
          <Col md={6} xs={12}>
            <TextInput
              name="code"
              control={control}
              label={texts.label.code}
              rules={{
                validate: {
                  notEmpty: validateTextNotEmpty,
                  codeExit: (value) => {
                    if (
                      !discounts.some(
                        (element) => element.code === String(value),
                      )
                    ) {
                      return texts.notFound;
                    }

                    return undefined;
                  },
                },
              }}
            />
          </Col>
          <Col md={6} xs={12}>
            <TextInput
              name="percentage"
              control={control}
              disabled
              label={texts.label.percentage}
            />
          </Col>
        </Row>
        <Row className="mt-0 mt-md-4">
          <Col xs="12" md="12">
            <TextInput
              name="name"
              disabled
              control={control}
              label={texts.label.name}
            />
          </Col>
        </Row>
        <Row className="mt-0 mt-md-4">
          <Col xs="12" md="12">
            <Select
              options={[
                { label: 'Arancel', value: 'tariff' },
                { label: 'Matrícula', value: 'tuition' },
              ]}
              name="type"
              control={control}
              label={texts.label.type}
              rules={{
                validate: validateTextNotEmpty,
              }}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
            <Button
              type="button"
              text={texts.actions.cancel}
              outlined
              onClick={() => onComplete(false)}
              fullwidth
            />
          </Col>
          <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
            <Button
              type="submit"
              text={texts.actions.confirm}
              fullwidth
              className="mb-3 mb-md-0"
              loading={formState.isSubmitting}
            />
          </Col>
        </Row>
      </form>
    </Modal>
  );
}
