export enum TranslationsKeys {
  // Globals
  TERMS = 'terms',
  COMMON = 'common',
  INPUTS = 'inputs',
  // Layouts
  AUTHORIZED_LAYOUT = 'layouts/authorized',
  // Olds
  OLD_TRANSLATIONS = 'translation',
  // teacher-management
  TEACHER_MANAGMENT_TEACHER_ACTIVATION = 'teacher-management/teacher-activation',
  TEACHER_MANAGMENT_TEACHER_FILE = 'teacher-management/teacher-file',
  TEACHER_MANAGMENT_TEACHER_FILE_LIST = 'teacher-management/teacher-file/list',
  TEACHER_MANAGMENT_TEACHER_FILE_ADDITIONAL_DOCUMENTATION = 'teacher-management/teacher-file/additional-documentation',
  TEACHER_MANAGMENT_TEACHER_FILE_EDUCATIONAL_BACKGROUND = 'teacher-management/teacher-file/educational-background',
  TEACHER_MANAGMENT_TEACHER_FILE_EMPLOYMENT_BACKGROUND = 'teacher-management/teacher-file/employment-background',
  TEACHER_MANAGMENT_TEACHER_FILE_HIRING_INFORMATION = 'teacher-management/teacher-file/hiring-information',
  TEACHER_MANAGMENT_TEACHER_FILE_PERSONAL_BACKGROUND = 'teacher-management/teacher-file/personal-background',
  // tuition-continuity
  TC_DISCOUNT_ALLOCATION_MANAGEMENT = 'tuition-continuity/discount-allocation-management',
}

export const TranslatonsArray = Object.values(TranslationsKeys);
