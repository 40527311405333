import {
  CellFormatOptions,
  CheckInput,
  ColumnTable,
  DateInput,
  Icon,
  TextInput,
  TextOutlinedInput,
} from '@octano/global-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../../../../../../components/tooltip/Tooltip';
import { EvaluationType, PartialEvaluation } from '../types';
import { isSubevaluation } from '../utils/validEvaluation';
import useStructureTypeIsFixed from './useStructureTypeIsFixed';
import './../parts/styles/column.scss';

export interface PartialEvaluationGradeColumnsProps {
  onChange: (state: PartialEvaluation[]) => void;
  error?: boolean;
  partialEvaluations: PartialEvaluation[];
  onNameChange: (row: { name?: string }) => (e: any) => void;
  onDateChange: (row: { date?: string | Date | null }) => (e: any) => void;
  onWeightingChange: (row: { percentage?: string }) => (e: any) => void;
  onHasLimitDateChange: (row: { hasLimitDate?: boolean }) => (e: any) => void;
  onLimitDateChange: (row: {
    limitDate?: string | Date | null;
  }) => (e: any) => void;
}

export default function usePartialEvaluationGradeColumns({
  onChange,
  error,
  partialEvaluations,
  onNameChange,
  onDateChange,
  onWeightingChange,
  onHasLimitDateChange,
  onLimitDateChange,
}: PartialEvaluationGradeColumnsProps) {
  const { t } = useTranslation();
  const { isFixedType } = useStructureTypeIsFixed();

  // Callbacks
  const isExisingtNameDuplicate = useCallback(
    (index: number, name: string) => {
      let errorText = partialEvaluations.find((partialEvaluation, key) => {
        if (key !== index) {
          if (!partialEvaluation.name) {
            return false;
          }
          return partialEvaluation.name === name;
        }
        return false;
      });
      if (errorText) {
        return `*${t(
          'configurations.gradingStructure.form.partialEvaluationGradeEntry.duplicationName',
        )}`;
      }
      return undefined;
    },
    [partialEvaluations, t],
  );

  // Crud operations - Evaluations
  const addPartialEvaluationSubevaluation = useCallback(
    (index: number) => {
      return () => {
        const currentEvaluation = partialEvaluations[index];
        if (isSubevaluation(currentEvaluation)) {
          onChange([
            ...partialEvaluations.slice(0, index),
            {
              ...currentEvaluation,
              grades: [...currentEvaluation.grades, { canItBeRemoved: true }],
            },
            ...partialEvaluations.slice(index + 1),
          ]);
        } else {
          const { date, ...data } = currentEvaluation;
          onChange([
            ...partialEvaluations.slice(0, index),
            {
              ...data,
              type: EvaluationType.Subevaluations,
              grades: [
                { canItBeRemoved: !isFixedType },
                { canItBeRemoved: !isFixedType },
              ],
            },
            ...partialEvaluations.slice(index + 1),
          ]);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partialEvaluations, onChange],
  );
  const deletePartialEvaluationByIndex = useCallback(
    (index: number) => {
      return () => {
        // if (partialEvaluations[index].canItBeRemoved) {
        if (partialEvaluations[index]) {
          const newPartialEvaluations = [...partialEvaluations];
          newPartialEvaluations.splice(index, 1);
          onChange(newPartialEvaluations);
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partialEvaluations, onChange],
  );

  const columns = useMemo<ColumnTable[]>(() => {
    return [
      {
        columnName: 'evaluation',
        headerText: t(
          'configurations.gradingStructure.form.partialEvaluationGradeEntry.columns.evaluation',
        ),
        width: '45%',
        thClassName: 'text-center',
        tdProps: { noTdWrap: true },
        cellFormat: ({ row, index }: CellFormatOptions<PartialEvaluation>) => {
          let errorText =
            error && !row.name
              ? t('configurations.gradingStructure.form.require')
              : undefined;
          let limitOfChar =
            row.name && row.name.length > 30
              ? t(
                  'configurations.gradingStructure.form.partialEvaluationGradeEntry.charLimit',
                )
              : undefined;
          return (
            <td
              colSpan={isSubevaluation(row) ? (row?.hasLimitDate ? 1 : 2) : 1}
            >
              <TextInput
                key={index}
                name={`evaluation-${index}`}
                label={t(
                  'configurations.gradingStructure.form.partialEvaluationGradeEntry.columns.placeholder.nameEvaluation',
                )}
                type="text"
                value={row.name}
                errorText={
                  isExisingtNameDuplicate(
                    index as number,
                    row.name as string,
                  ) ||
                  limitOfChar ||
                  errorText
                }
                onChange={onNameChange(row)}
              />

              <div
                className="d-flex justify-content-left"
                style={{ gap: '3px' }}
              >
                <CheckInput
                  name={`hasLimitDate.${index}`}
                  value={row.hasLimitDate}
                  onChange={onHasLimitDateChange(row)}
                  label={
                    isSubevaluation(row)
                      ? t(
                          'configurations.gradingStructure.form.partialEvaluationGradeEntry.columns.placeholder.hasLimitDate2',
                        )
                      : t(
                          'configurations.gradingStructure.form.partialEvaluationGradeEntry.columns.placeholder.hasLimitDate1',
                        )
                  }
                />
                <Tooltip
                  id={`info_tooltip_${index}`}
                  placement="right"
                  text={
                    isSubevaluation(row)
                      ? t(
                          'configurations.gradingStructure.form.partialEvaluationGradeEntry.columns.placeholder.tooltipLimitDate2',
                        )
                      : t(
                          'configurations.gradingStructure.form.partialEvaluationGradeEntry.columns.placeholder.tooltipLimitDate1',
                        )
                  }
                >
                  <Icon name="information" size={14.71} />
                </Tooltip>
              </div>
            </td>
          );
        },
      },
      {
        columnName: 'date',
        headerText: t(
          'configurations.gradingStructure.form.partialEvaluationGradeEntry.columns.date',
        ),
        width: '35%',
        thClassName: 'text-center',
        tdProps: { noTdWrap: true },
        cellFormat: (options: CellFormatOptions<PartialEvaluation>) => {
          if (options.row?.hasLimitDate) {
            return (
              <td className="align-top ">
                <DateInput
                  name={`limitDate-${options.index}`}
                  label={
                    isSubevaluation(options.row)
                      ? t(
                          'configurations.gradingStructure.form.partialEvaluationGradeEntry.columns.placeholder.limitDate2',
                        )
                      : t(
                          'configurations.gradingStructure.form.partialEvaluationGradeEntry.columns.placeholder.limitDate1',
                        )
                  }
                  value={options.row.limitDate}
                  onChange={onLimitDateChange(options.row)}
                  shouldUnregister={true}
                />
              </td>
            );
          }
          if (isSubevaluation(options.row)) {
            return null;
          }
          let errorText =
            error && !options.row.date
              ? t('configurations.gradingStructure.form.require')
              : undefined;
          return (
            <td className="align-top">
              <DateInput
                name={`date-${options.index}`}
                label={t(
                  'configurations.gradingStructure.form.partialEvaluationGradeEntry.columns.placeholder.surrencerDate',
                )}
                value={options.row.date}
                onChange={onDateChange(options.row)}
                shouldUnregister={true}
                errorText={errorText}
              />
            </td>
          );
        },
      },
      {
        columnName: 'percentage',
        headerText: t(
          'configurations.gradingStructure.form.partialEvaluationGradeEntry.columns.percentage',
        ),
        width: '20%',
        thClassName: 'text-center',
        tdClassName: 'expander-height-input',
        cellFormat: (options: CellFormatOptions<PartialEvaluation>) => {
          let errorText =
            error && !options.row.percentage
              ? t('configurations.gradingStructure.form.require')
              : undefined;
          let value =
            options.row.percentage === '0' ? '' : options.row.percentage;
          return (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ gap: '8px' }}
            >
              <div style={{ maxWidth: '100px' }}>
                <TextOutlinedInput
                  type="text"
                  name="percentage"
                  placeholder="—%"
                  value={value}
                  onChange={onWeightingChange(options.row)}
                  errorText={errorText}
                />
              </div>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ gap: '8px' }}
              >
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={addPartialEvaluationSubevaluation(
                    options.index as number,
                  )}
                >
                  <Icon name="plus" className="mb-3" />
                </div>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={deletePartialEvaluationByIndex(
                    options.index as number,
                  )}
                >
                  <Icon name="trash" className="mb-3" />
                </div>
              </div>
            </div>
          );
        },
      },
    ];
  }, [
    t,
    onNameChange,
    onDateChange,
    onWeightingChange,
    isExisingtNameDuplicate,
    deletePartialEvaluationByIndex,
    addPartialEvaluationSubevaluation,
    onLimitDateChange,
    onHasLimitDateChange,
    error,
  ]);

  return columns;
}
