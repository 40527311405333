import { Table, addToast } from '@octano/global-ui';
import { useRef, useState } from 'react';
import useActions from '../hooks/useActions';
import useColumns from '../hooks/useColumns';
import useTexts from '../hooks/useTexts';
import DiscountAllocationModal, {
  DiscountAllocationModalRef,
} from '../modals/DiscountAllocationModal';
import UploadFailureModal, {
  UploadFailureModalRef,
} from '../modals/UploadFailureModal';
function DiscountAllocationTable() {
  const createOrUpdateModalRef = useRef<DiscountAllocationModalRef>(null);
  const createOrUpdateErrorModalRef = useRef<UploadFailureModalRef>(null);
  const [fileUploadFailureId, setFileUploadFailureId] = useState<string>('');
  const onClickUpload = () => {
    createOrUpdateModalRef.current?.openModal();
  };
  const texts = useTexts();

  const actions = useActions({
    onSuccess: () => {
      addToast({
        icon: 'success',
        color: 'success',
        text: texts.modal.success,
      });
    },
    onError: (_, messsage) => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: messsage ?? 'Ha ocurrido un error',
      });
    },
  });

  const openErrorModal = (id: string) => {
    setFileUploadFailureId(id);
    createOrUpdateErrorModalRef.current?.openModal();
  };

  const columns = useColumns({ onClickUpload });

  return (
    <>
      <Table
        data={[
          {
            bulkDiscountTemplate:
              'Planilla de alumnos que recibirán el descuento',
          },
        ]}
        columns={columns}
      />
      <DiscountAllocationModal
        ref={createOrUpdateModalRef}
        actions={actions}
        onErrorFileId={openErrorModal}
      />
      <UploadFailureModal
        ref={createOrUpdateErrorModalRef}
        fileUploadFailureId={fileUploadFailureId}
      />
    </>
  );
}

export default DiscountAllocationTable;
