import EvaluationForm from '../EvaluationForm';

const UpdateStructureTypeFormSemiStaticFields = () => {
  return (
    <>
      <div className=" bg-white rounded">
        <EvaluationForm />
      </div>
    </>
  );
};

export default UpdateStructureTypeFormSemiStaticFields;
