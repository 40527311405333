import { useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import { TextsProvider } from './hooks/textsContext';
import EmploymentBackgroundTable from './parts/EmploymentBackgroundTable';
import EmploymentBackgroundLoader from './parts/Loaders/EmploymentBackgroundLoader';

function EmploymentBackground() {
  const { teacherId } = useParams<{ teacherId: string }>();

  return (
    <Container fluid className="mt-4">
      <TextsProvider>
        <EmploymentBackgroundLoader teacherId={Number(teacherId)}>
          <EmploymentBackgroundTable />
        </EmploymentBackgroundLoader>
      </TextsProvider>
    </Container>
  );
}
export default EmploymentBackground;
